import PropTypes from 'prop-types';
import get from 'lodash/get';
import React from 'react';
import FormFields from './fields/FormFields';
import { hasRequiredData } from './utils';

const Ticketing = (props) => {
  const { ticketing, onChange, onValidate, data } = props;
  if (!ticketing || !ticketing.enabled) return null;
  const { fields = [], strings = {} } = ticketing;
  const disabled = !hasRequiredData(fields, data);
  const buttonLabel = get(strings, 'register-button', "S'inscrire");
  return (
    <div className='Ticketing"'>
      <FormFields fields={fields} onChange={onChange} data={data} />
      <div className="af-container" {...props}>
        <button type="button" className="af-form-button" onClick={onValidate} disabled={disabled}>
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

Ticketing.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  ticketing: PropTypes.object,
};

Ticketing.defaultProps = {
  ticketing: undefined,
};

export default Ticketing;
