import { isUpdate } from '../../utils';
import React, { PureComponent } from 'react';

const getButtonLabel = (label: string, showNextTabOnUpdate?: boolean, strings: Record<string, any> = {}) => {
  const { update = 'Mettre à jour' } = strings;
  if (isUpdate() && !showNextTabOnUpdate) return update;
  return label;
};

type TabButtonProps = {
  text?: string;
  label: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  strings: Record<string, any>;
  showNextTabOnUpdate?: boolean;
  style?: Record<string, any>;
};

class TabButton extends PureComponent<TabButtonProps> {
  render() {
    const { text, label, disabled, onClick, strings = {}, showNextTabOnUpdate, style = {}, ...props } = this.props;
    // Text or label, legacy...
    const buttonLabel = getButtonLabel(label, showNextTabOnUpdate, strings) || label || text;
    return (
      <div style={style} className="af-container" {...props}>
        <button type="submit" className="af-form-button" onClick={onClick} disabled={disabled}>
          {buttonLabel || 'Submit'}
        </button>
      </div>
    );
  }
}

export { TabButton };
