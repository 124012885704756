import get from 'lodash/get';
import pick from 'lodash/pick';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import registrationV2Service from '../../services/registrationV2.service';
import { Slot, computeSlots } from '../../utils/slotUtils';
import RadioField from '../RadioField';
import SelectField from '../SelectField';
import './AppointmentSelectField.scss';
import InputBlocker from '../../components/InputBlocker';

type FieldProps<T> = {
  _id: string;
  label: string;
  name: string;
  required: boolean;
  data: Record<string, any>;
  value?: T | null;
  mode?: 'summary';
  summary?: any;
  className?: string;
  onChange: (name: string, value: T | null) => void;
};

function pickFieldProps<T>(props: any): FieldProps<T> {
  return pick(props, ['_id', 'label', 'name', 'required', 'data', 'value', 'mode', 'summary', 'onChange']);
}

function formatDate(date: string, format: string): string {
  return moment(date, moment.ISO_8601).format(format);
}

const formatTime = (date: string) => formatDate(date, 'HH:mm');

const slotComponents = {
  SelectField,
  RadioField,
};

type AppointmentSelectFieldProps = FieldProps<{ startDate: string; endDate: string; location?: string | null }> & {
  type: 'AppointmentSelectField';
  slot: {
    pickerType: 'SelectField' | 'RadioField';
    options?: Slot[];
  };
  dateField: string;
  location?: {
    label: string;
    pickerType: 'SelectField' | 'RadioField';
    options?: { label: string; value: string }[];
  };
};

const Picker: FC<Record<string, any> & { type: 'SelectField' | 'RadioField' }> = ({ type = 'SelectField', ...props }) => {
  const SlotCompo = slotComponents[type] ?? SelectField;
  return <SlotCompo {...props} type={type} />;
};

type LocationRes = {
  slot?: { startDate: string; endDate: string };
  locations: { label: string; value: string; state: 'free' | 'booked' | 'booked-by-parent' }[];
};

const AppointmentSelectField: FC<AppointmentSelectFieldProps> = (props) => {
  const { _id, data, dateField, name, value, onChange, location, slot } = props;

  const slotValue = value ? `${value.startDate}|${value.endDate}` : '';

  const { data: locationRes, isLoading } = useQuery<LocationRes>(
    ['AppointmentSelectField', 'locations', slotValue],
    () => {
      return registrationV2Service.getAvailableLocations(value, _id);
    },
    { enabled: !!slotValue },
  );

  const fieldProps = pickFieldProps(props);

  const selectedDate = get(data, dateField);
  if (dateField && !selectedDate) return null;

  const handleSlotChange = (n: string, v: string | null) => {
    if (v) {
      const [startDate, endDate] = v.split('|');
      // Flushes location
      onChange(name, { startDate, endDate });
    } else {
      onChange(name, null);
    }
  };

  const handleLocationChange = (n: string, loc: string | null) => {
    onChange(name, { ...value, location: loc } as any);
  };

  const timeOptions = useMemo(() => {
    return computeSlots(slot.options || [])
      .map((s) => {
        const timeSlot = `${formatTime(s.startDate)} - ${formatTime(s.endDate)}`;
        return {
          value: s.startDate + '|' + s.endDate,
          date: formatDate(s.startDate, 'YYYY-MM-DD'),
          // If filtered by date, just show time, else show date + time
          label: selectedDate ? timeSlot : `${formatDate(s.startDate, 'LL')} ${timeSlot}`,
          slot: s,
        };
      })
      .filter((s) => !selectedDate || s.date === selectedDate);
  }, [slot.options, selectedDate]);

  useEffect(() => {
    if (value?.location && locationRes?.locations?.length) {
      // Auto-kick if not available
      const locationOption = locationRes.locations.find((loc) => loc.value === value.location);
      if (locationOption?.state === 'booked') {
        // Sorry !
        handleLocationChange(name, null);
      }
    }
  }, [name, value, locationRes?.locations]);

  // Auto-flush invalid dates
  useEffect(() => {
    const prevDate = value ? formatDate(value.startDate, 'YYYY-MM-DD') : null;
    if (selectedDate && value && prevDate && prevDate !== selectedDate) {
      // See if we can keep the same slot
      const newSlot = {
        startDate: value.startDate.replace(prevDate, selectedDate),
        endDate: value.endDate.replace(prevDate, selectedDate),
      };
      // Check that this is a valid slot
      if (timeOptions.find((opt) => opt.slot.startDate === newSlot.startDate && opt.slot.endDate === newSlot.endDate)) {
        onChange(name, newSlot);
      } else {
        // Flush
        handleSlotChange(name, null);
      }
    }
  }, [name, selectedDate, value, timeOptions]);

  const slotPickerType = slot?.pickerType || 'SelectField';
  const locationPickerType = location?.pickerType || 'SelectField';

  return (
    <>
      <Picker {...fieldProps} type={slotPickerType} options={timeOptions} value={slotValue} onChange={handleSlotChange} />
      {!!value &&
        !!location &&
        !!location.options?.length &&
        !isLoading &&
        locationRes?.slot?.startDate === value.startDate &&
        locationRes?.slot?.endDate === value.endDate && (
          <Picker
            {...fieldProps}
            className={`${fieldProps.className || 'af-field-container--RadioField--location'} `}
            name="location"
            label={location?.label}
            type={locationPickerType}
            template={locationPickerType === 'RadioField' ? 'button' : undefined}
            options={locationRes.locations}
            value={value?.location}
            onChange={handleLocationChange}
          />
        )}
      {!value?.location && <InputBlocker name={`${name}.location`} style={{}} />}
    </>
  );
};

export default AppointmentSelectField;
