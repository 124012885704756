import React from 'react';
import Images from '../../utils/Images';
import './AwarenessBlock.scss';

interface AwarenessBlockType {
  image: {
    uri: string;
  };
  description: string;
}
export const AwarenessBlock = (props: AwarenessBlockType) => {
  const { image, description } = props;
  return (
    <div className="awareness-block af-field-container">
      <div className="awareness-block__image-wrapper">
        <img src={Images.maxWidth(image, 50)} alt="Awareness image" />
      </div>
      <div className="awareness-block__description">{description}</div>
    </div>
  );
};
