import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import { decode, encode } from 'querystring';

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function pickUTM(object) {
  return pickBy(object, (_v, k) => startsWith(k, 'utm_'));
}

export function extractUTM(queryString) {
  const str = trim(queryString, '?');
  if (!str) return {};
  return pickUTM(decode(str));
}

export function appendQueryString(url, qs) {
  if (!url || !qs) return url;

  if (typeof qs !== 'string') {
    qs = encode(qs);
  }
  return url.indexOf('?') === -1 ? `${url}?${qs}` : `${url}&${qs}`;
}
