import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import get from 'lodash/get';
import { hasOriginalData } from '../utils';
import { StringField } from './index';

export default class MultiStringField extends PureComponent {
  static defaultProps = {
    inputType: 'text',
    value: '',
    required: false,
    fields: [],
  };

  render() {
    const { id, label, mode, fields, inputType, data, onChange, required, summary, disabled, locked } = this.props;
    // if (disabled && !value) return null; // Hide disabled field
    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return fields.map((field, index) => (
        <StringField
          key={'' + index}
          type="StringField"
          value={get(data, field.name)}
          {...field}
          mode={mode}
          label={`${label} - ${field.placeholder || field.label || field.name}`}
        />
      ));
    }

    return (
      <FieldContainer {...this.props}>
        <div className="af-multi-string-container">
          {fields.map((field, index) => {
            const { placeholder, name } = field;
            return (
              <input
                key={index}
                id={id + '-' + name}
                className="af-multi-string-field"
                name={name}
                type={inputType}
                placeholder={placeholder || label}
                value={get(data, name)}
                required={required}
                readOnly={disabled || (locked && hasOriginalData(name))}
                onChange={(e) => onChange(name, e.target.value)}
              />
            );
          })}
        </div>
        {/* {error && <p className="error">{error}</p>} */}
      </FieldContainer>
    );
  }
}
