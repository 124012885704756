import toPairs from "lodash/toPairs";
import PropTypes from "prop-types";
import React from "react";
import HiddenFields from "./HiddenFields";
import { extractUTM, pickUTM } from "../utils/urlUtils";

const UTMFields = React.memo(function UTMFields() {
  const userUTM = pickUTM(window.__DATA__.data); // Pick from user profile (window.__DATA__.data)
  if (Object.keys(userUTM).length) {
    return <HiddenFields kvPairs={toPairs(userUTM)} />;
  }

  const urlUTM = extractUTM(window.location.search); // Pick from url
  if (Object.keys(urlUTM).length) {
    return <HiddenFields kvPairs={toPairs(urlUTM)} />;
  }
  return null;
});

const AutomaticFields = ({ automaticFields, data }) => {
  return (
    <>
      <UTMFields />
      <HiddenFields kvPairs={automaticFields.map((field) => [field, data[field]]).filter(([_k, v]) => v)} />
    </>
  );
};

AutomaticFields.defaultProps = {
  automaticFields: [],
  data: {},
};

AutomaticFields.propTypes = {
  automaticFields: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object,
};

export default AutomaticFields;
