import React, { FC } from 'react';
import { PlacesAutocompleteField, PlacesAutocompleteFieldProps } from './PlacesAutocompleteField';
import { GooglePlace } from '../carbon-footprint.types';
import { useTranslation } from 'react-i18next';
import { NumberField } from '../../NumberField';

type FromAndToFieldsProps = Omit<PlacesAutocompleteFieldProps, 'value' | 'label' | 'name'> & {
  from?: GooglePlace;
  to?: GooglePlace;
  distanceKm?: number | '';
};

const FromAndToFields: FC<FromAndToFieldsProps> = ({ from, to, distanceKm, onChange, types, mode }) => {
  const { t } = useTranslation();
  if (!window.google?.maps) {
    // Fallback to distance
    return <NumberField value={distanceKm ?? ''} name="distanceKm" onChange={onChange} label={t('carbon-footprint.labels.distance')} />;
  }
  return (
    <>
      <PlacesAutocompleteField
        required
        label={t('carbon-footprint.labels.from')}
        name="from"
        value={from}
        onChange={onChange}
        types={types}
        mode={mode}
      />
      <PlacesAutocompleteField required label={t('carbon-footprint.labels.to')} name="to" value={to} onChange={onChange} types={types} mode={mode} />
    </>
  );
};

export default FromAndToFields;
