import React, { PureComponent } from "react";
import FieldContainer from "../components/FieldContainer";
import ImageUploader from "../components/ImageUploader";
export default class ImageField extends PureComponent {
  static defaultProps = {
    inputType: "text",
    value: "",
    required: false
  };

  render() {
    const { id, name, value, onChange, placeholder, width = 200, height = 200, required, disabled } = this.props;
    const imageValue = value && typeof value === "string" ? JSON.parse(value) || {} : value;
    return (
      <FieldContainer {...this.props} isUploader>
        <ImageUploader
          id={id}
          style={{ width, height }}
          field={name}
          value={imageValue}
          required={required}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
        />
        {value && <input type="hidden" name={name} value={JSON.stringify(imageValue)} />}
      </FieldContainer>
    );
  }
}
