export const computeProductFieldErrors = (field: any, basket: any) => {
  const { strings = {}, minCount, required } = field;
  if (required && !basket?.length) {
    return [
      {
        key: 'products:required',
        message: strings['no-products'] || "Vous n'avez pas sélectionné de produits",
      },
    ];
  }

  if (minCount !== undefined && (basket?.length || 0) < minCount) {
    return [
      {
        key: 'products:minCount',
        message: strings['not-enough-products'] || "Vous n'avez pas sélectionné assez de produits",
      },
    ];
  }
};
