import cx from 'classnames';
import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import { hasOriginalData } from '../utils';
import { formatData } from '../utils/stringUtils';

const DefaultInput = (props) => <input {...props} />;

export default class StringField extends PureComponent {
  static defaultProps = {
    inputType: 'text',
    value: '',
    required: false,
  };

  handleBlur = (e) => {
    // format data on blur
    const { name, format, onChange } = this.props;
    onChange(name, formatData(e.target.value, format));
  };

  render() {
    const {
      id,
      label,
      placeholder,
      name,
      inputType,
      min,
      max,
      pattern,
      value,
      onChange,
      required,
      disabled,
      locked,
      mask,
      maskChar,
      mode,
      incorrect,
      hint,
    } = this.props;
    if (disabled && !value) return null; // Hide disabled field

    const Input = mask ? InputMask : DefaultInput;

    if (mode === 'summary') {
      return <StringSummary {...this.props} value={value} />;
    }
    return (
      <FieldContainer {...this.props} type="StringField" className={cx('mode', mode)}>
        <div className="af-string-field__container">
          <Input
            id={id}
            className={cx('af-string-field', incorrect && 'af-string-field--incorrect')}
            name={name}
            type={inputType}
            pattern={pattern}
            mask={mask}
            maskChar={maskChar}
            placeholder={placeholder || label}
            value={value ?? ''}
            required={required}
            readOnly={disabled || (locked && hasOriginalData(name))}
            onChange={(e) => onChange(name, e.target.value)}
            onBlur={this.handleBlur}
            min={min}
            max={max}
          />
          {incorrect && hint && <span className="af-string-field__incorrect-hint">{hint}</span>}
        </div>
      </FieldContainer>
    );
  }
}
