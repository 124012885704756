import i18n from '.';

type Option = { value: string; label: string };

export function translateOptions(values: string[], translationPrefix: string): Option[] {
  return values.map((v) => ({
    value: v,
    label: i18n.t(`${translationPrefix}.${v}`),
  }));
}
