import React, { PureComponent } from "react";
import FieldContainer from "../components/FieldContainer";

function nameFor(user) {
  return `${user.firstName || ""} ${user.lastName || ""}`.trim();
}

export default class RoomMate extends PureComponent {
  render() {
    const { name, value, onChange, required = false } = this.props;
    const roommates = (window.__DATA__ && window.__DATA__.roommates) || [];
    if (!Array.isArray(roommates)) {
      console.log("Invalid roommates");
      return null;
    }
    return (
      <FieldContainer {...this.props}>
        <select
          name={name}
          required={required}
          onChange={e => onChange(name, e.target.value)}
          value={value}
          className="af-select-field"
        >
          <option value="" />
          {roommates.map(roommate => (
            <option key={roommate._id} value={roommate._id}>
              {nameFor(roommate)}
            </option>
          ))}
        </select>
      </FieldContainer>
    );
  }
}
