/* eslint-disable react/prop-types */
import React from 'react';
import { cssForFont } from './utils/fontUtils';
import Images from './utils/Images';

function alphaHex(alphaLevel) {
  const str = Math.floor(alphaLevel * 255).toString(16);
  if (str.length === 1) return '0' + str;
  return str;
}

function alpha(color, alphaLevel) {
  return color
    .replace(/rgb\((.*)\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(rgba\(.*),[^,]+\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(#......)/, (_all, group1) => `${group1}${alphaHex(alphaLevel)}`);
}

const Font = ({ type, font }) => {
  if (!font) return null;
  const css = cssForFont(type, font);
  if (!css) return null;
  return (
    <style
      key={`font-${type}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: css,
      }}
    />
  );
};

export default class Styles extends React.PureComponent {
  static defaultProps = {
    backgroundColor: '#fff',
    textColor: '#000',
  };
  render() {
    const { primaryColor, backgroundColor, backgroundImage, textColor, headerColor, customCSS = '', fonts, bodyColor } = this.props;
    const widthOption = {
      normal: '700px',
      large: '1127px',
      full: '100%',
    };
    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}

        <style
          dangerouslySetInnerHTML={{
            __html: `

:root {
  --ac-color-primary: ${primaryColor};
  --ac-color-bg: ${backgroundColor};
  --ac-color-text: ${textColor};
  --PhoneInputCountrySelectArrow-color: currentColor;
}

/* Color styles */

h1, h2 {
  color: ${headerColor || primaryColor};
}

input.af-string-field,
input.af-password-field,
textarea.af-textarea-field,
input.af-email-field,
.af-phoneNumber-field input.PhoneInputInput{
  color: ${textColor};
}

textarea:focus.af-textarea-field {
  box-shadow: inset 0 0 0 2px ${primaryColor};
}

input:focus.af-string-field,
input:focus.af-password-field {
  box-shadow: inset 0 -2px 0 0 ${primaryColor};
}

.af-form-button {
  background-color: ${primaryColor};
  color: white;
}

:hover.af-form-button {
  background-color: ${primaryColor}99;
}

:disabled.af-form-button {
  background-color: #ccc;
  cursor: initial;
}

.af-form-button.return {
  background-color: #6B6B6B;
}
:hover.af-form-button.return {
  background-color: #6B6B6B99;
}

.af-radio-field:checked {
  background: ${primaryColor};
}

.af-tab--active {
  border-color: ${primaryColor};
}

.textblock__show-more {
  color: ${primaryColor}; 
  font-weight: bold; 
}

.table--simple button {
  background-color: ${primaryColor}; 
  color: white;
}

.checkmark {
  background-color: #eee;
}


input.af-single-checkbox-field:checked ~ .checkmark
{
  background-color: ${primaryColor}; 
}

.af-field-container.af-field-container--RadioField:not(.is-disabled) label:not(.is-active):not(.is-disabled) .af-radio-field:hover,
.af-field-container.af-field-container--CheckboxField.af-field-container--CheckboxField--button:not(.is-active):hover .checkmark {
  background: ${alpha(primaryColor, 0.5)};
}


.af-field-container--RadioField.af-field-container--RadioField--button:not(.is-disabled)>.af-field>div>label:not(.is-disabled):hover,
.af-field-container.af-field-container--CheckboxField.af-field-container--CheckboxField--button:hover {
  border-color: ${alpha(primaryColor, 0.5)};
  background-color: ${alpha(primaryColor, 0.03)};
}

.countdown__container,
.af-field-container--RadioField.af-field-container--RadioField--button>.af-field>div>label.is-active, 
.af-field-container--RadioField.af-field-container--RadioField--button>.af-field>div>label.is-active:hover,
.af-field-container.af-field-container--CheckboxField.af-field-container--CheckboxField--button.is-active, 
.af-field-container.af-field-container--CheckboxField.af-field-container--CheckboxField--button.is-active:hover 
 {
  border-color: ${primaryColor};
  background-color: ${alpha(primaryColor, 0.1)};
}

.countdown__container .content .timeLeft { 
  background-color: ${primaryColor};
}

body {
  background-color : ${bodyColor || '#EEE'};
}

${
  backgroundImage && backgroundImage.uri
    ? `
.af-container__background {
  background-image: url("${Images.maxWidth(backgroundImage, 1920)}");
}
`
    : ''
}


.Step.is-active:before, .Step.is-before:before {
  background-color: ${primaryColor};
  border-color: ${primaryColor};
  color: white;
}
.Step.is-before + .Step:after {
  background-color: ${primaryColor};
}


${customCSS}
`,
          }}
        />
      </>
    );
  }
}
// TODO: add select border ? Doesn't look great ?
// .af-field-container.af-field-container--SelectField.af-field-container--SelectField--button select.is-active
