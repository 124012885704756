import get from 'lodash/get';
import React, { FC, useEffect } from 'react';
import FieldContainer from '../../../components/FieldContainer';
import InputBlocker from '../../../components/InputBlocker';
import { replaceValues } from '../../../components/utils';
import { Error, Errors } from '../../errors';
import AccommodationInfo from '../components/AccommodationInfo';
import Stars from '../components/AccommodationStars';
import { checkIfLevelHasQuotaForDates, getDaysBetweenDates, useAccommodationErrors } from '../utils';

type AccomodationFromChekinProps = {
  accommodations: any[];
  checkInField: string;
  checkOutField: string;
  data: Record<string, any>;
  label?: string;
  name: string;
  onChange: (name: string, value: any) => void;
  registrationErrors: any[];
  required?: boolean;
  strings?: Record<string, any>;
  templates: Record<string, any>;
  type: string;
} & Record<string, any>;

const AccommodationFromCheckin: FC<AccomodationFromChekinProps> = (props) => {
  const { data, required, accommodations, templates, checkInField, checkOutField, onChange, name, registrationErrors, strings, value } = props;
  const { address: addressTemplate = '{address}', website: websiteTemplate = '{website}' } = templates;

  const checkin = get(data, checkInField);
  const checkout = get(data, checkOutField);
  const userDates = getDaysBetweenDates(checkin, checkout);
  const errors = useAccommodationErrors(registrationErrors, strings);
  const isCheckinGreaterThanCheckout = !!checkout && !!checkin && checkout <= checkin;

  useEffect(() => {
    // if accommation level doesn't change but dates do !!
    if (value?.startDate !== checkin || value?.endDate !== userDates[userDates.length - 1]) {
      onChange(name, {
        ...value,
        startDate: checkin,
        endDate: userDates[userDates.length - 1],
      });
    }
  }, [value?.startDate, value?.endDate, checkin, userDates]);

  if (isCheckinGreaterThanCheckout) {
    return (
      <>
        <Error message={get(strings, ['errors', 'invalid-dates']) || 'La date du checkout doit être supérieure à la date du checkin'} />
        <InputBlocker name="accommodation" style={{ width: '100%' }} />
      </>
    );
  }

  return (
    <FieldContainer {...props}>
      <div className="af-field-container--AccommodationReservation">
        <div className="list">
          {accommodations.map((accommodation) => {
            const { _id: accommodationId, collection, title, levels = [], note } = accommodation;
            const address = replaceValues(addressTemplate, accommodation);
            const website = replaceValues(websiteTemplate, accommodation);
            return (
              <div className="accommodation" key={accommodationId}>
                {title && (
                  <h3 className="accommodation__title">
                    {title}
                    <Stars note={note} />
                  </h3>
                )}
                <div className="accommodation__info">
                  <AccommodationInfo icon="link" value={website} />
                  <AccommodationInfo icon="map-marker" value={address} />
                </div>
                <div className="accommodation__levels">
                  {levels.map((level: any) => {
                    const { _id: levelId, name: levelName } = level;
                    const isAvailable = checkIfLevelHasQuotaForDates(level, userDates);
                    const isChecked = levelId === get(data, name)?.accommodationLevelId;

                    return (
                      <div key={levelId} className="level">
                        <label className="label" style={{ opacity: !isAvailable ? 0.5 : 1, display: 'flex' }}>
                          <input
                            className="af-radio-field"
                            checked={isChecked}
                            disabled={!isAvailable}
                            type="radio"
                            name={name}
                            value={levelId}
                            required={required}
                            onChange={(e) =>
                              onChange(name, {
                                collection,
                                accommodationTitle: title,
                                accommodationName: levelName,
                                accommodationLevelId: e.target.value,
                                accommodationId,
                                startDate: checkin,
                                endDate: userDates[userDates.length - 1],
                              })
                            }
                          />
                          <span>{levelName}</span>
                        </label>
                        {!isAvailable && <div className="full">{get(strings, 'no-more-availability') || 'Plus de disponibilité'}</div>}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Errors errors={errors} blocker={false} />
    </FieldContainer>
  );
};

export { AccommodationFromCheckin };
