export enum UpdateMode {
  SummaryWithUpdate = 'summary-with-update',
  SummaryOnly = 'summary-only',
  UpdateButtonWithoutSummary = 'update-button-without-summary',
}

export enum ButtonPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTH = 'both',
}

export type UpdateModeOptions = {
  mode: UpdateMode;
  preSummaryHtml?: string;
  postSummaryHtml?: string;
  button?: {
    position?: ButtonPosition;
    text?: string;
  };
};
