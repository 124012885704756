import { ConditionItem } from '@appcraft/common-utils';
import { evalCondition as evalConditionV2 } from '@appcraft/common-utils/lib/condition/utils';
import cloneDeep from 'lodash/cloneDeep';

type RadioOption = { value: string; label: string; quota?: number; usersCount?: number };

type IField = {
  name: string;
  type: string;
  fields?: IField[];
  tabs?: IField[];
};

type RadioField = IField & {
  options: RadioOption[];
};

function mapField<T extends IField>(originalField: T, mapper: (field: T) => T | null): T | null {
  const field = mapper(originalField);
  if (!field) return null;

  if (field.fields) {
    return {
      ...field,
      fields: field.fields.map((f) => mapField(f as T, mapper)).filter((v) => !!v) as T[],
    };
  }

  if (field.tabs) {
    return {
      ...field,
      tabs: field.tabs.map((f) => mapField(f as T, mapper)).filter((v) => !!v) as T[],
    };
  }
  return field;
}

function mapFieldType<T extends IField>(originalField: IField, type: string, mapper: (field: T) => T | null): T | IField | null {
  return mapField(originalField, (field: IField) => {
    if (field.type === type) return mapper(field as T);
    return field;
  });
}

function mapFieldsOfType<T extends IField>(fields: IField[], type: string, mapper: (field: T) => T | null | T | null): IField[] {
  return fields.map((f) => mapFieldType(f, type, mapper)).filter((f) => !!f) as IField[];
}

export function populateQuotaFields(fields: IField[], countUserFields: Record<string, Record<string, number>>): IField[] {
  return mapFieldsOfType(fields, 'RadioField', (field: RadioField) => {
    const hasQuota = field.options?.some((opt) => typeof opt.quota === 'number');
    if (!hasQuota) return field;

    // Clone now, add to fieldsToUpdate, we will update all fields right after
    const newRadio = cloneDeep(field);
    const counts = countUserFields[newRadio.name] || {};
    newRadio.options.forEach((opt) => {
      if (opt.quota) {
        opt.usersCount = counts[opt.value] ?? 0;
        console.log(opt.value, opt.usersCount);
      }
    });
    console.log('newRadio', newRadio);
    return newRadio;
  });
}

export function filterOptions(data: ConditionItem | null | undefined, options: any[]) {
  return options.filter((opt) => {
    const { visibleBy, showIfOthersAreEmpty = [] } = opt;
    if (showIfOthersAreEmpty) {
      // Check that all others are indeed empty
      if (
        !showIfOthersAreEmpty.every((otherValue: any) => {
          const otherOptions = options.find((o) => o.value === otherValue);
          return !otherOptions || otherOptions.usersCount >= otherOptions.quota;
        })
      ) {
        return false;
      }
    }
    return evalConditionV2(visibleBy, data);
  });
}
