import clone from 'lodash/clone';
import setWith from 'lodash/setWith';
import { customAlphabet } from 'nanoid';

export function setIn(object, path, value) {
  return setWith(clone(object), path, value, clone);
}

const characters = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';

export const createUniqueId = customAlphabet(characters, 12);
