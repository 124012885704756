/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import get from 'lodash/get';
import moment from 'moment-timezone';
import './AccommodationReservation.scss';
import { useTranslation } from 'react-i18next';

type AccommodationReservationSummaryProps = {
  _id: string;
  data: Record<string, any>;
  name: string;
  summary: Record<string, any>;
  style: any;
  datesLabels: Record<string, any>;
};

function formatDate(date: string, format: string) {
  return moment(date).format(format);
}

const AccommodationReservationSummary: FC<AccommodationReservationSummaryProps> = (props) => {
  const { _id, data, name, summary, style, datesLabels } = props;
  const { t } = useTranslation();
  let accommodations = get(data, name) || [];
  accommodations = Array.isArray(accommodations) ? accommodations : [accommodations];

  return (
    <div className="af-field-container--AccommodationReservation summary" style={{ ...style }}>
      <div className="label">{summary?.label || t('accommodations.presence-day')}</div>
      <div className="list">
        {accommodations.map((accommodation: any) => {
          const { startDate, endDate } = accommodation;
          const accommodationStartDate = formatDate(startDate, 'dddd LL');
          const accommodationEndDate = formatDate(endDate, 'dddd LL');
          const dateLabel = get(datesLabels, `${accommodation?.accommodationLevelId}.${startDate}`) ?? get(datesLabels, [startDate]);
          const displayDate = startDate === endDate ? accommodationStartDate : `${accommodationStartDate} - ${accommodationEndDate}`;
          return (
            <label key={_id} className="accommodation">
              <div style={{ width: '100%' }}>
                <span className="date">{dateLabel ?? displayDate}</span>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

AccommodationReservationSummary.defaultProps = {};

export default AccommodationReservationSummary;
