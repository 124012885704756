import pick from 'lodash/pick';
import React, { FC } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import FieldContainer from '../../../components/FieldContainer';
import StringSummary from '../../../components/StringSummary';
import { FieldProps } from '../../field.types';
import { GooglePlace } from '../carbon-footprint.types';
import i18n from '../../../translations';

// // !!! This is where you set `geometry` field to be returned with the place.
// autocomplete.setFields(['address_component', 'geometry']);

type PlaceType = 'airport' | 'train_station' | 'subway_station' | 'bus_station' | string;

export type PlacesAutocompleteFieldProps = Omit<FieldProps<GooglePlace>, 'type'> & {
  types?: PlaceType[];
};

export const PlacesAutocompleteField: FC<PlacesAutocompleteFieldProps> = (props) => {
  const { name, types, value, onChange, mode } = props;

  async function handleChange(place: any) {
    if (!place?.value) {
      onChange(name, null);
    } else {
      // TODO: auto-include geo info in autocomplete ? https://stackoverflow.com/a/64787229/444239
      const geo = await geocodeByPlaceId(place.value.place_id);
      // What should we do if we don't have the GEO ??
      if (geo.length > 0) {
        const newValue = {
          ...pick(place.value, ['description', 'place_id', 'reference']),
          location: geo[0].geometry.location.toJSON(),
        };
        onChange(name, newValue);
      }
    }
  }

  if (mode === 'summary') {
    return <StringSummary {...props} summary={undefined} type="StringField" value={value?.description} />;
  }

  return (
    <FieldContainer {...props} type="SelectField">
      <GooglePlacesAutocomplete
        apiOptions={{ language: 'fr', region: 'fr' }}
        autocompletionRequest={{ types }}
        selectProps={{
          required: props.required,
          name,
          noOptionsMessage: () => {
            return i18n.t('carbon-footprint.labels.no-options');
          },
          value: value
            ? {
                label: value.description,
                value: value,
              }
            : undefined,
          placeholder: i18n.t('carbon-footprint.labels.trip-placeholder'),
          isClearable: true,
          onChange: handleChange,
        }}
      />
    </FieldContainer>
  );
};
