const classesFromType = {
  body: 'html, body, *:not(.fa)',
};

const fontExtensions = {
  '.ttf': 'truetype',
  '.otf': 'opentype',
  '.woff': 'woff',
  '.woff2': 'woff2',
};

export function extension(filename) {
  const matches = filename.match(/\.[0-9a-z]+$/i);
  if (matches) return matches[0];
  return '';
}

function fontFormatFromName(filename) {
  const ext = extension(filename);
  return fontExtensions[ext];
}

export function fontFace(name, type, file) {
  if (!name || !file || !file.uri) return '';
  const format = fontFormatFromName(file.uri);
  if (!format) return '';

  return `
  /* ${type} */
  @font-face {
      font-family: "${name}";
      src: url("${file.uri}") format("${format}");
  ${type === 'italic' || type === 'boldItalic' ? '    font-style: italic;' : ''}
  ${type === 'bold' || type === 'boldItalic' ? '    font-weight: bold;' : ''}
  }`;
}

export function fontFaces(name, files) {
  if (!files) return '';

  return Object.keys(files)
    .map((type) => fontFace(name, type, files[type]))
    .join('\n');
}

export function cssForFont(type, font) {
  if (!font) return null;
  const { name, classes = classesFromType[type], fallbacks, files } = font;
  if (!classes) return null;

  return `
  ${fontFaces(name, files)}
  
  ${classes} {
      font-family: "${name}", ${fallbacks || 'sans-serif'};
  }
  `;
}
