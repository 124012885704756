import get from 'lodash/get';
import { evalCondition as evalConditionV2 } from '@appcraft/common-utils';

function isTrue(value) {
  return value === true || value === 1 || value === '1' || value === 'YES' || value === 'OUI';
}

function isEqual(v1, v2) {
  return (isTrue(v1) && isTrue(v2)) || v1 === v2 || '' + v1 === '' + v2;
}

function isEmpty(value) {
  return value === '' || value === undefined || value === null;
}

function contains(haystack, needle) {
  if (!haystack) return false;
  if (!needle) return false;
  if (typeof haystack === 'string') {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1;
  } else if (Array.isArray(haystack)) {
    return haystack.indexOf(needle) !== -1;
  }
  return false; // What should we do ?
}

function has(array, valueToFind) {
  if (isEmpty(array) || !Array.isArray(array)) return false;
  if (!valueToFind) return true;
  return array.some((e) => e === valueToFind || e?.value === valueToFind);
}

export function evalConditionV1(condition, values) {
  if (!condition) return true;
  if (typeof condition === 'object') {
    const answerByKey = get(values, condition.key);
    switch (condition.op) {
      case 'isEqual':
        return isEqual(answerByKey, condition.value);
      case 'isNotEqual':
        return !isEqual(answerByKey, condition.value);
      case 'contains':
        return contains(answerByKey, condition.value);
      case 'doesntContain':
        return !contains(answerByKey, condition.value);
      case 'isEmpty':
        return isEmpty(answerByKey);
      case 'isNotEmpty':
        return !isEmpty(answerByKey);
      case 'has':
        if (typeof answerByKey === 'object') {
          return has(answerByKey, condition.value);
        }
        return isEqual(answerByKey, condition.value);
      case 'doesntHave':
        if (typeof answerByKey === 'object') {
          return !has(answerByKey, condition.value);
        }
        return !isEqual(answerByKey, condition.value);
      case '>':
      case '<':
      case '>=':
      case '<=': {
        let leftValue = answerByKey;
        if (isEmpty(leftValue) || isEmpty(condition.value)) return false;
        leftValue = parseFloat(leftValue);
        if (isNaN(leftValue)) return false;
        switch (condition.op) {
          case '>':
            return leftValue > condition.value;
          case '<':
            return leftValue < condition.value;
          case '>=':
            return leftValue >= condition.value;
          case '<=':
            return leftValue <= condition.value;
        }
      }
      case 'and':
        return evalConditionV1(condition.left, values) && evalConditionV1(condition.right, values);
      case 'or':
        return evalConditionV1(condition.left, values) || evalConditionV1(condition.right, values);
      default: {
        console.warn('unknown op', condition.op);
        return false;
      }
    }
  } else {
    return condition(values);
  }
}

function evalValue(value, data) {
  if (typeof value === 'object' && value.op === 'get') {
    return get(data, value.key);
  }
  return value;
}

export function mapCondition(condition, data) {
  if (!condition) return condition;
  return {
    ...condition,
    value: evalValue(condition.value, data),
    left: mapCondition(condition.left, data),
    right: mapCondition(condition.left, data),
  };
}

export function evalCondition(condition, data) {
  if (condition?.version === '2') {
    return evalConditionV2(condition, data);
  }
  return evalConditionV1(condition, data);
}

export function injectConditionFieldsV1(condition, fields) {
  if (!condition) return;

  const { op, key } = condition;

  if (op === 'and' || op === 'or') {
    injectConditionFieldsV1(condition.left, fields);
    injectConditionFieldsV1(condition.right, fields);
  } else if (key) {
    if (!fields.includes(key)) {
      fields.push(key);
    }
  }
  return fields;
}

export function injectConditionFieldsV2(condition, fields) {
  if (!condition) return;

  const { op, key } = condition;

  if (op === 'and' || op === 'or') {
    if (condition.expressions?.length) {
      for (const exp of condition.expressions) {
        injectConditionFieldsV2(exp, fields);
      }
    }
  } else if (key) {
    if (!fields.includes(key)) {
      fields.push(key);
    }
  }
  return fields;
}

export function extractConditionFields(condition, fields) {
  if (!condition) return [];

  if (condition.version === '2') {
    return injectConditionFieldsV2(condition, fields);
  }
  return injectConditionFieldsV1(condition, fields);
}
