import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import './checkbox-field.scss';

export function urlify(text) {
  if (!text) return '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

export function markdownUrlify(text, options = {}) {
  if (!text) return '';
  const { onLinkClick } = options;
  const urlRegex = /(\[[^\]]*\]\([^)]*\))/g;
  const splitRegex = /\[([^\]]*)\]\(([^)]*)\)/;
  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      // Split
      const innerMatch = part.match(splitRegex);
      if (innerMatch) {
        const [, urlText, url] = innerMatch;
        return (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer" onClick={onLinkClick}>
            {urlText}
          </a>
        );
      }
    }
    return urlify(part);
  });
}

export default class CheckboxField extends PureComponent {
  constructor(props) {
    super(props);
    const { value, locked } = props;
    const { status: userStatus } = window.__DATA__?.data || {};
    this.state = {
      isLocked: value && locked && userStatus === 'registered',
    };
  }
  render() {
    const { label, name, value, required = false, onChange, disabled, mode, summary } = this.props;
    const { isLocked } = this.state;
    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return <StringSummary {...this.props} value={value} />;
    }

    return (
      <FieldContainer
        {...this.props}
        label={markdownUrlify(label)}
        active={value}
        // template={mode === 'summary' ? 'summary' : template}
        template={mode === 'summary' ? 'summary' : 'button'}
        required={mode === 'summary' ? false : required}
      >
        <input
          className="af-single-checkbox-field"
          type="checkbox"
          checked={value}
          name={name}
          value={value}
          required={required}
          readOnly={disabled}
          disabled={isLocked || mode === 'summary'}
          onChange={(e) => onChange(name, e.target.checked)}
        />
        <span className="checkmark"></span>
      </FieldContainer>
    );
  }
}
