import { extractCurrentFields } from './';
import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';

export function computePrice(product, userCount = 1) {
  const { value = 0, unit, deposit = 100, perPerson } = product.price || {};
  if (perPerson) {
    return toNumber(value) * userCount;
  }
  return toNumber(value);
}

export function computeDeposit(product, userCount) {
  const { deposit = 100 } = product.price || {};
  return Math.floor((computePrice(product, userCount) * toNumber(deposit)) / 100);
}

function computeProductPrices(fields, data, userCount) {
  const productFields = fields.filter((f) => f.type === 'ProductField');
  const selectedProducts = productFields.filter((p) => p.forceValue || data[p.name]);
  const products = selectedProducts.filter((p) => !p.price?.percentage);
  const extras = selectedProducts.filter((p) => !!p.price?.percentage);

  // Compute amounts
  const productsWithAmount = products.map((p) => ({
    ...p,
    amount: computePrice(p, userCount),
    depositAmount: computeDeposit(p, userCount),
  }));

  // Add percentages
  const total = sumBy(productsWithAmount, 'amount');
  const extrasWithAmount = extras.map((p) => {
    const { deposit = 100 } = p.price || {};
    const amount = Math.floor((p.price.percentage * total) / 100);
    const depositAmount = Math.floor((amount * toNumber(deposit)) / 100);
    return {
      ...p,
      amount,
      depositAmount,
    };
  });

  // Compute recap and infos...
  return { products: productsWithAmount, extras: extrasWithAmount };
}

function injectUserHeader(data, user) {
  return { ...data, header: `${user.firstName || ''} ${user.lastName || ''}` };
}

export function getCheckedProducts(form, data, userCount) {
  const fields = extractCurrentFields(form, data);

  const globalPrice = computeProductPrices(fields, data, userCount);

  // Extract list items fields
  const listItemFields = fields.filter((f) => f.type === 'ListItem');
  for (const listItem of listItemFields) {
    const users = _.get(data, listItem.name);
    if (users?.length && listItem.fields?.length) {
      // Check for each user...
      for (const user of users) {
        const userFields = extractCurrentFields(listItem.fields, user);
        const userPrice = computeProductPrices(userFields, user, 1);
        if (userPrice.products?.length || userPrice.extras?.length) {
          globalPrice.products.push(...userPrice.products.map((product) => injectUserHeader(product, user)));
          globalPrice.extras.push(...userPrice.extras.map((product) => injectUserHeader(product, user)));
        }
      }
    }
  }

  return globalPrice;
}

export function getTotalPrice(form, data, userCount) {
  const products = getCheckedProducts(form, data);
  return sumBy(products, (p) => computePrice(p, userCount));
}

export function getDepositPrice(form, data, userCount) {
  const products = getCheckedProducts(form, data);
  return sumBy(products, (p) => computeDeposit(p, userCount));
}

// TODO : broken, remove ??
export function getPrices(form, data, userCount = 1) {
  const products = getCheckedProducts(form, data);
  return {
    total: sumBy(products, (p) => computePrice(p, userCount)),
    deposit: sumBy(products, (p) => computeDeposit(p, userCount)),
  };
}

export function performPayment(action, data) {
  const form = document.createElement('form');
  const node = document.createElement('input');

  form.action = action;
  form.method = 'POST';

  for (const name in data) {
    node.name = name;
    node.value = data[name].toString();
    form.appendChild(node.cloneNode());
  }

  // Pour être envoyé, le formulaire nécessite d'être attaché au document principal.
  form.style.display = 'none';
  document.body.appendChild(form);

  form.submit();

  // Une fois le formulaire envoyé, le supprimer.
  document.body.removeChild(form);
}
