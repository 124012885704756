import React, { FC, Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import './FlightCard.scss';
import { bem } from '../../../../core/bem';
import { computeDuration, divideFlightNumber } from '../../utils/flightUtils';
import { FlightStep } from '../../flight.types';
import moment from 'moment';
import Icon from '../../../../components/Icon';
import { EditableField } from '../../../../components/EditableField/EditableField';

const css = bem('flight-card');

type FlightCardProps = {
  className?: string;
  flights: FlightStep[];
  color?: string;
  disclaimer?: string;
  children?: ReactNode;
  isSummary?: boolean;
  onDelete?: (id?: string) => void;
  onEdit?: (_id: string, newValue: Partial<FlightStep>) => any;
  onEditTime?: (_id: string, type: 'departure' | 'arrival', newTime: string) => any;
};

export const FlightCard: FC<FlightCardProps> = (props) => {
  const { t } = useTranslation();
  const { className, flights, disclaimer, color = 'var(--ac-color-primary)', children, isSummary, onDelete, onEdit, onEditTime } = props;

  const hasConnection = flights.length > 1;

  const [editMode, setEditMode] = useState<Record<string, boolean>>({});

  const computeFlightNumbers = () => {
    if (!hasConnection) {
      return divideFlightNumber(flights[0].flightNumber)?.join(' ');
    }
    return flights.map((flight, index) => `N°${index + 1}: ${flight.flightNumber}`).join(' / ');
  };

  return (
    <div className={cx(css(), className)}>
      <div className={css('header')} style={{ backgroundColor: color }}>
        <div className="flight-number">
          <span className="label">{t(hasConnection ? 'flight.flights' : 'flight.flight')}</span>
          <div className="divider" />
          <span className="number">{computeFlightNumbers()}</span>
        </div>
      </div>
      <div className={css('steps')}>
        {flights.map((step, index) => {
          const {
            _id,
            flightNumber,
            departureTime,
            departureDate,
            departureAirportCode,
            departureAirport,
            departureTerminal,
            departureGate,
            arrivalAirportCode,
            arrivalAirport,
            arrivalTime,
            arrivalDate,
            arrivalTerminal,
            isoDuration,
            duration,
          } = step;
          const isEdited = editMode[_id];
          return (
            <Fragment key={_id}>
              <div className={css('date')}>{moment(departureDate).format('LL')}</div>
              <div className={css('step')} key={`step ${index + 1}`}>
                <div className="departure-and-duration">
                  <div className="stopovers">
                    <div className="circle" />
                    <div className="divider" />
                  </div>
                  <div className="infos">
                    <div className="departure">
                      <span className="time" style={{ color }}>
                        <EditableField
                          edited={isEdited && !!onEdit}
                          value={moment(`2023-12-12 ${departureTime}`)}
                          onChange={(newValue) => onEditTime?.(_id!, 'departure', newValue)}
                          type="time"
                          className="small-input"
                        >
                          {moment(`${departureDate}T${departureTime}`).format('LT')}
                        </EditableField>
                      </span>
                      <span className="airport">
                        <span>
                          {departureAirport} ({departureAirportCode}) - {flightNumber}
                        </span>

                        <span className="airport__infos">
                          {t('flight.terminal')} :{' '}
                          <EditableField
                            edited={isEdited && !!onEdit}
                            value={departureTerminal}
                            onChange={(newValue) => onEdit?.(_id!, { departureTerminal: newValue })}
                            type="string"
                            className="small-input"
                          >
                            <strong>{departureTerminal || '-'}</strong>
                          </EditableField>
                        </span>

                        <span className="airport__infos">
                          {t('flight.gate')} :{' '}
                          <EditableField
                            edited={isEdited && !!onEdit}
                            value={departureGate}
                            onChange={(newValue) => onEdit?.(_id!, { departureGate: newValue })}
                            type="string"
                            className="small-input"
                          >
                            <strong>{departureGate || '-'}</strong>
                          </EditableField>
                        </span>
                      </span>
                    </div>
                    <div className="duration">
                      {(duration || isoDuration) &&
                        t('flight.duration', {
                          duration: duration || computeDuration(isoDuration || ''),
                        })}
                    </div>
                  </div>
                </div>
                <div className="arrival">
                  <div className="circle" />
                  <span className="time" style={{ color }}>
                    <EditableField
                      edited={isEdited && !!onEdit}
                      value={moment(`2023-12-12 ${arrivalTime}`)}
                      onChange={(newValue) => onEditTime?.(_id!, 'arrival', newValue)}
                      type="time"
                      className="small-input"
                    >
                      {moment(`${arrivalDate}T${arrivalTime}`).format('LT')}
                    </EditableField>
                  </span>
                  <span className="airport">
                    <span>
                      {arrivalAirport} ({arrivalAirportCode})
                    </span>

                    <span className="airport__infos">
                      {t('flight.terminal')} :{' '}
                      <EditableField
                        edited={isEdited && !!onEdit}
                        value={arrivalTerminal}
                        onChange={(newValue) => onEdit?.(_id!, { arrivalTerminal: newValue })}
                        type="string"
                        className="small-input"
                      >
                        <strong>{arrivalTerminal || '-'}</strong>
                      </EditableField>
                    </span>
                  </span>
                </div>
                {!isSummary && (
                  <div className="card-actions">
                    {onEdit && (
                      <Icon
                        className="edit-icon"
                        name={isEdited ? 'check' : 'edit'}
                        color={isEdited ? 'green' : 'grey'}
                        onClick={() => setEditMode({ ...editMode, [_id]: editMode[_id] ? !editMode[_id] : true })}
                      />
                    )}
                    {onDelete && <Icon className="delete-icon" name="trash" color="red" onClick={() => onDelete(step._id)} />}
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}
        {children}
        {disclaimer && (
          <div className="disclaimer">
            <Icon name="warning" />
            <span>{disclaimer}</span>
          </div>
        )}
      </div>
    </div>
  );
};
