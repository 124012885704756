import React, { useContext } from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import StringField from './StringField';
import { evalCondition } from '../utils/conditions';
import { DesignContext } from '../core/Design';
import './form-field.scss';

export function getFieldComponent(type) {
  if (type in FormFields.formFields) return FormFields.formFields[type];
  if (type) console.warn('Unkown field type', type);
  return StringField;
}

const FormFields = ({ errors, registrationErrors, data, fields, onChange, onChangeMode, activeTab, mode, formMode }) => {
  const design = useContext(DesignContext);
  if (!fields) return <div></div>;
  return (
    <div>
      {fields.map((field, idx) => {
        const { condition, name, type, summary, hidden, parentCondition } = field;
        if (!(evalCondition(condition, data) && evalCondition(parentCondition, data))) return null;
        if (field.onlyIfUpdate && !window.__DATA__.isUpdate) {
          return null; // Hide !! only for updates...
        }

        const Component = getFieldComponent(type); // console.log("Component", Component);
        const defaultProps = get(design, ['defaultFieldProps', type]);

        const id = name ? idx + '-' + name : '' + idx;

        // Auto-hide
        if ((mode === 'summary' && summary?.hidden) || hidden) {
          return null;
        }

        return (
          <Component
            key={id}
            id={id}
            {...defaultProps}
            {...omit(field, 'ref')}
            onChange={onChange}
            onChangeMode={onChangeMode}
            info={data}
            data={data}
            error={errors && errors[name]}
            value={get(data, name)}
            activeTab={activeTab}
            registrationErrors={registrationErrors}
            mode={mode}
            formMode={formMode}
          />
        );
      })}
    </div>
  );
};

export default FormFields;
