import omit from 'lodash/omit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { FormMode, isUpdateWithSummary } from '../utils/formUtils';

class SubmitButton extends React.PureComponent<any> {
  handleReturn = () => {
    const { onChangeMode } = this.props;
    onChangeMode('form');
  };

  getButtonLabel = () => {
    const { label, mode, summary, text, updateLabel, t } = this.props;
    const isUpdate = window.__DATA__ && window.__DATA__.isUpdate;
    const isSummary = mode === 'summary';

    if (isUpdate && !isSummary) {
      return updateLabel;
    }
    if (isSummary) {
      return summary?.validateButtonLabel || t('btn.next');
    }
    return label || text;
  };

  render() {
    // Text or label, legacy...
    const { text, label, updateLabel, buttonProps, meta, mode, formMode, summary, t, ...props } = this.props;
    const isSummary = mode === 'summary';
    const buttonLabel = this.getButtonLabel();

    // Nothing to render :)
    if (formMode === FormMode.UPDATE_SUMMARY) return null;

    return (
      <div className="af-container" {...omit(props, ['activeTab', 'onChangeMode', 'registrationErrors', 'tReady', 'i18n', 'info', 'data'])}>
        {isSummary && (
          <button type="button" className="af-form-button return" onClick={this.handleReturn} style={{ marginRight: 10 }}>
            {summary?.returnButtonBabel || t('btn.back')}
            {summary?.meta && Object.keys(summary?.meta).map((key) => <meta key={key} itemProp={key} content={summary?.meta[key]} />)}
          </button>
        )}
        <button type="submit" className="af-form-button" {...buttonProps}>
          {buttonLabel || t('btn.submit')}
          {meta && Object.keys(meta).map((key) => <meta key={key} itemProp={key} content={meta[key]} />)}
        </button>
      </div>
    );
  }
}

export default withTranslation()(SubmitButton) as React.ComponentType<any>;
