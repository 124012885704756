/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { extractCurrentFields } from '../utils';
import { ListItemSummary } from './ListItem';
import StringSummary from './StringSummary';
import { WorkshopsSummary } from '../fields/WorkshopRegistrationV2/WorkshopRegistrationV2';
import './Summary.scss';
import AccommodationReservationSummary from '../fields/AccommodationReservation/AccommodationReservationSummary';

type SummaryProps = {
  fields: Record<string, any>[];
  data: Record<string, any>;
};

const Summary = (props: SummaryProps) => {
  const { data, fields = [] } = props;
  return (
    <div className="Summary">
      {extractCurrentFields(fields, data).map((f: any) => {
        if (f.type === 'WorkshopRegistrationV2') return <WorkshopsSummary key={f._id} {...f} {...props} />;
        if (f.type === 'ListItem') return <ListItemSummary key={f._id} {...f} {...props} />;
        if (f.type === 'HiddenField') return null;
        if (f.type === 'AccommodationReservation') return <AccommodationReservationSummary key={f._id} {...f} {...props} />;
        return <StringSummary key={f._id} {...f} value={get(data, f.name, '')} />;
      })}
    </div>
  );
};

Summary.propTypes = {};

export default Summary;
