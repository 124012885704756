/* eslint-disable @typescript-eslint/no-explicit-any */
import every from 'lodash/every';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';
import sumBy from 'lodash/sumBy';
import {
  AccommodationRoomStatus,
  AccommodationRoomUserStatus,
  RoomingAccommodation,
  RoomingAccommodationLevel,
  RoomType,
  User,
} from './rooming.types';

export function getUserFullname(user?: User): string {
  if (!user) return 'Unknown';
  return `${user.firstName} ${user.lastName}`;
}

export const iconForStatus = {
  [AccommodationRoomUserStatus.ACCEPTED]: {
    name: 'check',
    backgroundColor: '#bbefb9',
    color: '#59B655',
  },
  [AccommodationRoomUserStatus.PENDING]: {
    name: 'hourglass-half',
    backgroundColor: '#f7df9b',
    color: '#dfa500',
  },
  [AccommodationRoomUserStatus.REJECTED]: {
    name: 'times',
    backgroundColor: '#fca3a7',
    color: '#C93B33',
  },
  [AccommodationRoomUserStatus.CANCELED]: {
    name: 'times',
    backgroundColor: '#fca3a7',
    color: '#C93B33',
  },
  [AccommodationRoomUserStatus.QUOTA_ERROR]: {
    name: 'exclamation',
    backgroundColor: '#f5cf9a',
    color: '#d37e06',
  },
};

export type RoomRequest = {
  accommodationId?: string;
  accommodationLevelId?: string;
  accommodationLevelType?: string;
  accommodationName?: string;
  hotelName?: string;
  users?: string[];
};

export function areAllRoomsRejected(rooms: RoomType[]): boolean {
  return every(rooms, ({ status }) => status === AccommodationRoomStatus.REJECTED || status === AccommodationRoomStatus.QUOTA_ERROR);
}

export function patchItems<T extends Record<string, any>>(array: T[], idKey: string, id: string, patch: Record<string, any>): T[] {
  return array.map((item) => {
    if (item[idKey] === id) {
      return { ...item, ...patch } as T;
    }
    return item;
  });
}

export function findAccommodationAndLevel(
  accommodations: RoomingAccommodation[],
  room: RoomRequest,
): {
  accommodation?: RoomingAccommodation;
  accommodationLevel?: RoomingAccommodationLevel;
} {
  if (!room.accommodationId) return {};

  const accommodation = accommodations.find((acc) => acc._id === room.accommodationId);
  if (!accommodation) return {};

  const accommodationLevel = accommodation.levels?.find((l) => l._id === room.accommodationLevelId);
  return { accommodation, accommodationLevel };
}

export type LevelType = {
  type: string;
  name: string;
  remainingRooms: number;
  levels: { _id: string; accommodationId: string; type: string; name: string }[];
};

export function computeLevelTypes(accommodations: RoomingAccommodation[] = [], levels: string[]): LevelType[] {
  const groups = groupBy(
    flatten(
      accommodations?.map((acc) =>
        acc.levels?.map((level) => ({
          _id: level._id,
          accommodationId: acc._id,
          type: level.type || level.name,
          name: level.name,
          remainingRooms: level.remainingRooms,
        })),
      ),
    ),
    'type',
  );
  const levelTypes = Object.entries(groups).map(([type, levels]) => ({
    type,
    name: levels[0].name,
    remainingRooms: sumBy(levels, 'remainingRooms'),
    levels,
  }));
  const orderedLevelTypes = compact(levels.map(level => {
    return levelTypes.find(lt => lt.type === level)
  })) as LevelType[]

  return orderedLevelTypes
}

export function computeInitialRoom(accommodations: RoomingAccommodation[], value: Partial<RoomRequest> | undefined, levels: string[]) {
  const types = computeLevelTypes(accommodations, levels);
  if (value) {
    // Add missing info if required
    const initialValue = { ...value };
    // Legacy value, find and add accommodationId
    const accommodationId = accommodations[0]?._id;
    if (!initialValue.accommodationId) initialValue.accommodationId = accommodationId;
    if (!initialValue.accommodationLevelType) {
      const level = accommodations.find((acc) => acc._id === accommodationId)?.levels?.find((l) => l._id === value.accommodationLevelId);
      initialValue.accommodationLevelType = level?.type || level?.name;
    }
    return initialValue;
  }
  const defaultAccommodation = accommodations?.length === 1 ? accommodations[0]._id : undefined;
  const defaultAccommodationType = types.length === 1 ? types[0].type : undefined;
  const defaultLevel = accommodations?.length === 1 && accommodations[0].levels?.length === 1 ? accommodations[0].levels[0]._id : undefined;
  return { accommodationId: defaultAccommodation, accommodationLevelType: defaultAccommodationType, accommodationLevelId: defaultLevel };
}
