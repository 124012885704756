import { useRequest } from 'ahooks';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

const host = window.__DATA__.api.smsConfirmation;

class ValidationService {
  async _send(method, path, body) {
    const res = await fetch(`${host}${path}`, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return res.json();
  }

  async validateCode(phoneNumber, securityCode) {
    return this._send('POST', `/_validate`, { phoneNumber, securityCode });
  }

  sendCode = throttle(
    async (phoneNumber) => {
      return this._send('POST', `/_send`, { phoneNumber });
    },
    1000,
    { leading: true, trailing: false },
  );
}

const validationService = new ValidationService();

export function useConfirmationCode() {
  const { data, loading, run } = useRequest((phoneNumber, value) => validationService.validateCode(phoneNumber, value), { manual: true });
  const [error, setError] = useState(undefined);
  const [confirmationCode, setConfirmationCode] = useState('');
  useEffect(() => {
    if (data) {
      if (data.success) {
        setConfirmationCode(data.confirmationCode);
      } else {
        setError(data.error);
      }
    }
  }, [data]);
  return { loading, confirmationCode, error, checkCode: run };
}

export default validationService;
