import React, { PureComponent } from 'react';
import cx from 'classnames';
import ShowMore from 'react-show-more';
import { replaceValues } from './utils';

export default class TextBlock extends PureComponent {
  render() {
    const {
      text = '',
      className,
      html,
      data,
      style,
      showMore,
      linesCount = 3,
      moreText = 'Voir plus',
      lessText = 'Voir moins',
      extra,
      mode,
      summary,
    } = this.props;
    //TODO @patrick: handle extra :{level}

    const lines = replaceValues(text, data).split('\n');

    if (mode === 'summary') {
      // TextBlocks are hidden by default
      const { hidden = true } = summary || {};
      if (hidden) return null;
    }
    // Will inject a br at all but last line
    if (showMore) {
      return (
        <ShowMore lines={linesCount} more={moreText} less={lessText} anchorClass="textblock__show-more">
          {text}
        </ShowMore>
      );
    }
    return (
      <div className={cx(className, 'af-field-container--TextBlock')} style={{ ...style }}>
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: replaceValues(html, data) }} />
        ) : (
          lines.map((t, index) => (
            <React.Fragment key={index}>
              {t}
              {index !== lines.length - 1 && <br />}
            </React.Fragment>
          ))
        )}
      </div>
    );
  }
}
