import orderBy from 'lodash/orderBy';
import moment from 'moment';

const formatList = [moment.ISO_8601, 'YYYY-MM-DD HH:mm'];

export type Slot = {
  startDate: string;
  endDate: string;
  durations: number[];
};

export type SlotItem = { startDate: string; endDate: string };

export function computeSlots(slots: Slot[]): SlotItem[] {
  const allSlots = [];
  for (const slot of slots) {
    const { startDate, endDate, durations } = slot;
    const start = moment(startDate, formatList);
    const end = moment(endDate, formatList);
    while (start.isBefore(end)) {
      // Generate slot for durations
      for (const duration of durations) {
        const slotEnd = start.clone().add(duration, 'minutes');
        if (slotEnd.isSameOrBefore(end)) {
          allSlots.push({ startDate: start.toISOString(), endDate: slotEnd.toISOString(), duration });
        }
      }
      start.add(durations[0], 'minutes');
    }
  }
  return orderBy(allSlots, ['startDate', 'endDate']);
}
