import React, { PureComponent } from "react";
import FieldContainer from "../components/FieldContainer";
import { getCheckedProducts, getPrices } from "../utils/payment";
import "./payment-cb.css";
// import { CardElement } from "react-stripe-elements";
import { StringField } from ".";

// Test card number 4242424242424242
// With 3d secure   4000000000003220
const handleChange = change => {
  console.log("[change]", change);
};
export default class PaymentCB extends PureComponent {
  static defaultProps = {
    value: "",
    required: false
  };

  getFields() {
    // TODO : fetch real fields, add store :/
    return window.__DATA__.fields;
  }

  getCheckedProducts() {
    return getCheckedProducts(this.getFields(), this.props.data);
  }

  getUserCount() {
    const { data } = this.props;
    return (data.users && data.users.length) || 1;
  }

  render() {
    const { onlyDeposit, data, onChange, ...props } = this.props;
    return null// Empty for now, will be handled by the server...

    // const { total, deposit } = getPrices(this.getFields(), data, this.getUserCount());

    // // console.log("products", products);
    // const { payment = {} } = data;
    // return (
    //   <div className="af-payment">
    //     {onlyDeposit && <h3>Total à payer : {deposit}€</h3>}
    //     {!onlyDeposit && <h3>Total à payer : {total}€</h3>}
    //     <StringField
    //       label="Nom"
    //       data={data}
    //       value={payment.lastName}
    //       onChange={onChange}
    //       required
    //       name="payment[lastName]"
    //     />
    //     <StringField
    //       label="Prénom"
    //       data={data}
    //       value={payment.firstName}
    //       onChange={onChange}
    //       required
    //       name="payment[firstName]"
    //     />
    //     <FieldContainer {...props} required label="Carte">
    //       <CardElement onReady={this.handleReady} onChange={handleChange} />
    //     </FieldContainer>
    //   </div>
    // );
  }
}
