import PropTypes from 'prop-types';
import React from 'react';
import './AccommodationReservation.scss';
import { AccommodationFromCheckin } from './variants/AccommodationFromChekin';
import { AccommodationPerDate } from './variants/AccommodationPerDate';
import AccommodationReservationSummary from './AccommodationReservationSummary';

const AccommodationReservationVariants = {
  'default': AccommodationFromCheckin,
  'per-date': AccommodationPerDate,
};

const AccommodationReservation = (props) => {
  const { variant = 'default', mode, summary, ...rest } = props;

  if (mode === 'summary') {
    if (summary?.hidden) {
      return null;
    }
    return <AccommodationReservationSummary {...props} />;
  }

  const Component = AccommodationReservationVariants[variant] || AccommodationReservationVariants.default;

  return <Component {...rest} />;
};

AccommodationReservation.defaultProps = {
  accommodations: [],
  data: {},
  label: undefined,
  registrationErrors: [],
  required: false,
  strings: {},
  templates: {},
  variant: 'default',
};

AccommodationReservation.propTypes = {
  accommodations: PropTypes.array,
  checkInField: PropTypes.string,
  checkOutField: PropTypes.string,
  data: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  registrationErrors: PropTypes.arrayOf(PropTypes.any),
  required: PropTypes.bool,
  strings: PropTypes.object,
  templates: PropTypes.object,
  variant: PropTypes.string,
};

export default AccommodationReservation;
