/* eslint-disable @typescript-eslint/no-explicit-any */
import keyBy from 'lodash/keyBy';
import React, { FC } from 'react';
import RoomingCreate from './components/RoomingCreate';
import RoomingInvitations from './components/RoomingInvitations';
import { AccommodationRoomStatus, AccommodationRoomUserStatus, CandidateUser, RoomingAccommodation, RoomType } from './rooming.types';
import './RoomingField.scss';
import { patchItems } from './utils';
import { Errors } from '../errors';
import { useTranslation } from 'react-i18next';

type RoomingFieldProps = {
  name: string;
  onChange: any;
  value: {
    roomRequest: any;
    invitationUpdates: Record<string, AccommodationRoomUserStatus>;
  };
  label?: string;
  invitations: RoomType[];
  candidateRoommates: CandidateUser[];
  accommodation: RoomingAccommodation;
  accommodations: RoomingAccommodation[];
  roomingSession: any;
  required?: boolean;
  mode: string;
  summary: any;
};

const RoomingField: FC<RoomingFieldProps> = (props): JSX.Element | null => {
  const {
    label,
    accommodations,
    candidateRoommates,
    invitations = [],
    name,
    value,
    onChange,
    required = false,
    roomingSession,
    mode,
    summary,
  } = props;
  const { t } = useTranslation();
  const me = window.__DATA__.data;

  // Nothing to show ?
  if (!accommodations?.length) {
    return null;
  }

  const users = candidateRoommates;
  const usersById = keyBy(candidateRoommates, '_id');

  const myInvitations = invitations.filter((i) => i.owner?._id === me._id);
  const hasPendingInvitation = !!myInvitations.find((i) => i.status === AccommodationRoomStatus.PENDING);
  const hasValidatedInvitation = !!invitations.find((i) => i.status === AccommodationRoomStatus.VALID);

  const { roomRequest, invitationUpdates = {} } = value || {};

  const isRoomEmpty = required && !value?.roomRequest && !invitations?.length;

  function handleCreateRoom(room: any) {
    onChange(name, { ...value, roomRequest: room });
  }

  const currentInvitations = invitations.map((invitation) => {
    const status = invitationUpdates[invitation._id];
    if (!status) return invitation;

    // Patch user status
    return {
      ...invitation,
      users: patchItems(invitation.users, 'userId', me._id, { status }),
    };
  });

  const hasPendingCurrentInvitationRejected = currentInvitations.every(
    (i) =>
      (i.status === AccommodationRoomStatus.PENDING &&
        i.users.some((u) => u.userId === me._id && u.status === AccommodationRoomUserStatus.REJECTED)) ||
      i.status === AccommodationRoomStatus.QUOTA_ERROR,
  );

  function onStatusChange(roomId: string, roomUserId: string | undefined, status: AccommodationRoomUserStatus) {
    onChange(name, {
      ...value,
      invitationUpdates: {
        ...invitationUpdates,
        [roomId]: status,
      },
    });
  }

  const canCreate = !hasPendingInvitation && !hasValidatedInvitation && hasPendingCurrentInvitationRejected;
  return (
    <div className="af-field-container--RoomingField">
      <h2 className="label">{label || t('rooming.your-accommodation')}</h2>
      <RoomingInvitations
        accommodations={accommodations}
        invitations={currentInvitations}
        usersById={usersById}
        onStatusChange={onStatusChange}
        mode={mode}
      />
      {canCreate && <RoomingCreate accommodations={accommodations} levels={roomingSession.accommodation.levels} users={users} value={roomRequest} onChange={handleCreateRoom} />}
      {isRoomEmpty && <Errors name={name} onlyBlocker />}
    </div>
  );
};

export default RoomingField;
