import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import querystring from 'querystring';
import { getDefaultData } from '../utils';
import { sendJson } from '../utils/fetch';
import { appendQueryString } from '../utils/urlUtils';

const { submitUrl, lang } = window.__DATA__;
const currentUrl = window.location.href.split('?')[0];

const specialValues = {
  true: true,
  false: false,
  null: null,
  undefined: undefined,
};

export function parseUrlQuery() {
  return mapValues(querystring.parse(window.location.search.substring(1).toString()), (value) =>
    isString(value) && specialValues.hasOwnProperty(value) ? specialValues[value] : value,
  );
}

function isUndefinedOrNull(value) {
  return isNull(value) || isUndefined(value);
}

const params = omitBy({ lang, ...parseUrlQuery() }, isUndefinedOrNull);

const host = appendQueryString(submitUrl || currentUrl, params);

const apiHost = window.__DATA__.api.api ?? `${currentUrl}/api`;

class RegistrationV2Service {
  addResponse(body) {
    return sendJson('POST', host, body);
  }

  patchResponse(patch) {
    return sendJson('PATCH', host, patch);
  }

  upsertResponse(body) {
    const defaultData = getDefaultData();
    if (!defaultData?._id) {
      return this.addResponse(body);
    } else {
      return this.patchResponse(body);
    }
  }

  getAvailableLocations(slot, appointmentSelectFieldId) {
    return sendJson('POST', apiHost + '/appointments/available-locations', { slot, appointmentSelectFieldId });
  }
}

export default new RegistrationV2Service();
