import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FieldContainer from '../components/FieldContainer';
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import sumBy from 'lodash/sumBy';
import BlockingInput from '../components/BlockingInput';
import { getNumericValue, formatValue } from '../utils/numericUtils';

const defaultStrings = {
  maxTotalExceeded: 'Le total doit être égal à ',
  totalText: 'Total = ',
};

function computeTotal(field, data) {
  const { fields } = field;
  return sumBy(fields, ({ name }) => getNumericValue(field, data, name));
}

export default class MultiNumericField extends PureComponent {
  getTotal = () => {
    const { data } = this.props;
    return computeTotal(this.props, data);
  };

  static validate = (field, data) => {
    const { maxTotal } = field;
    if (!maxTotal) return true;
    const total = computeTotal(field, data);
    return total === maxTotal;
  };

  isValidTotal = () => {
    const { data } = this.props;
    return MultiNumericField.validate(this.props, data);
  };

  getValue = (name) => {
    const { data } = this.props;
    return getNumericValue(this.props, data, name);
  };

  getStrings() {
    return { ...defaultStrings, ...this.props.strings };
  }

  render() {
    const { disabled, formatter, fields, max, maxTotal, min, onChange, precision, step, vertical, required } = this.props;
    const { maxTotalExceeded, totalText } = this.getStrings();

    return (
      <FieldContainer {...this.props}>
        <div className="af-multi-numeric-container" style={{ display: vertical ? 'block' : 'flex' }}>
          {fields.map((field, idx) => {
            const { name, label } = field;
            const value = this.getValue(name);
            return (
              <label
                key={idx}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  cursor: 'pointer',
                  marginRight: 20,
                  marginTop: 8,
                }}
              >
                <span style={{ flex: 1 }}>
                  <span style={{ marginRight: 8 }}>•</span>
                  {label}
                </span>
                <InputNumber
                  className="af-multi-numeric-input"
                  disabled={disabled}
                  formatter={(value) => formatValue(value, formatter)}
                  min={min}
                  max={max}
                  name={name}
                  onChange={(value) => onChange(name, value)}
                  precision={precision}
                  readOnly={disabled}
                  step={step}
                  style={{ width: 100 }}
                  value={value}
                />
              </label>
            );
          })}
          {maxTotal && !this.isValidTotal() && (
            <div style={{ display: 'flex' }}>
              {/* Inject an empty input if this field is required and the total isn't 100% */}
              {required && <BlockingInput name={fields && fields[0] && fields[0].name} />}
              <div>
                <div className="af-message af-message--error">
                  {maxTotalExceeded} {formatValue(maxTotal, formatter)}
                </div>
              </div>
              <p
                style={{
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  marginRight: 20,
                  fontSize: 18,
                  color: 'black',
                }}
              >
                {totalText} {formatValue(Math.round(this.getTotal() * 100) / 100, formatter)}
              </p>
            </div>
          )}
        </div>
      </FieldContainer>
    );
  }
}

MultiNumericField.defaultProps = {
  disabled: false,
  fields: [],
  formatter: undefined,
  min: 0,
  max: undefined,
  maxTotal: undefined,
  precision: undefined,
  step: 1,
  vertical: false,
};

MultiNumericField.propTypes = {
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, label: PropTypes.string })),
  formatter: PropTypes.string,
  max: PropTypes.number,
  maxTotal: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  precision: PropTypes.number,
  step: PropTypes.number,
  vertical: PropTypes.bool,
};
