import every from 'lodash/every';
import identity from 'lodash/identity';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { hasRequiredTransportData } from '../fields/CarbonFootprintTransportField/utils/carbonFootprint.utils';
import MultiNumericField from '../fields/MultiNumericField';
import { AccommodationRoomStatus } from '../fields/RoomingField/rooming.types';
import { evalCondition } from './conditions';
import { getEmailDomainRegex, validateEmail } from './emailUtils';
import { evalIfNeeded, isExpression } from './sanitizeData';

function cleanObject(obj) {
  if (!obj) return {};
  return pickBy(obj, identity); // Remove falsey values
}

export function hasOriginalData(key) {
  return !isEmpty(window?.__DATA__?.data?.[key]);
}

export function extractFields(formFields, options) {
  if (!formFields) return [];

  const fieldNames = [];

  function iterate(fields) {
    if (!fields) return;

    for (const field of fields) {
      if (field.name || options?.addAllFields) {
        fieldNames.push(field);
      }
      iterate(field.fields);
      iterate(field.tabs);
    }
  }

  iterate(formFields);
  return fieldNames;
}

export function extractCurrentFields(fields, data, parentData) {
  if (!fields) return [];
  let fieldNames = [];
  for (const field of fields) {
    if (evalCondition(field.condition, data) && (!parentData || evalCondition(field.parentCondition, parentData)) && !field.hidden) {
      if (field.name || field.type === 'MultiNumericField' || field.type === 'WorkshopRegistrationV2') {
        fieldNames.push(field);
      }
      if (field.fields && field.type !== 'ListItem' && field.type !== 'MultiNumericField') {
        fieldNames = [...fieldNames, ...extractCurrentFields(field.fields, data, parentData)];
      }
      if (field.tabs) {
        fieldNames = [...fieldNames, ...extractCurrentFields(field.tabs, data, parentData)];
      }
    }
  }
  return fieldNames;
}

export function extractRequiredFields(fields, data, parentData) {
  return extractCurrentFields(fields, data, parentData).filter(
    (f) => (f.required || f.type === 'ListItem' || f.type === 'WorkshopRegistrationV2' || f.type === 'RoomingField') && !f.disabled,
  );
}

export function validateListItems(listItemField, items, parentData) {
  const { minItems, maxItems, fields } = listItemField;
  if (minItems && items.length < minItems) return false;
  if (maxItems && items.length > maxItems) return false;
  // console.log("validateListItems")

  for (const item of items) {
    if (!hasRequiredData(fields, item, parentData)) {
      return false;
    }
  }
  return true;
}

function isValidRequiredField(field, data) {
  switch (field.type) {
    case 'EmailField':
      const { name, whitelist = {}, blacklist = {} } = field;
      const email = data[name];
      if (!email) return false;
      const { domains: whitelistedDomains } = whitelist || {};
      const { domains: blacklistedDomains } = blacklist || {};
      const domainRegex = getEmailDomainRegex({ whitelist: whitelistedDomains, blacklist: blacklistedDomains });
      const { isValid } = validateEmail(email, domainRegex);
      if (!isValid) return false;
      return true;
    case 'DateSelectField': {
      const { name, format = 'YYYY-MM-DD' } = field;
      const date = data[name];
      if (!date) return false;
      return moment(date, format).isValid();
    }
    case 'ListItem': {
      const items = data[field.name] || [];
      return validateListItems(field, items, data);
    }
    case 'RoomingField': {
      const { invitations = [] } = field;
      const pendingOrValidInvitations = invitations.filter(
        (i) => i.status !== AccommodationRoomStatus.REJECTED && i.status !== AccommodationRoomStatus.QUOTA_ERROR,
      );
      return !!data[field.name] || pendingOrValidInvitations.length > 0;
    }
    case 'MultiSelectField': {
      const { minItems, maxItems, name } = field;
      const items = data[name] || [];
      if (minItems && items.length < minItems) return false;
      if (maxItems && items.length > maxItems) return false;
      return items.length > 0;
    }
    case 'MultiNumericField': {
      return MultiNumericField.validate(field, data);
    }
    case 'WorkshopRegistrationV2': {
      const { workshopsRegistrations = [] } = data;
      const { minCount, required } = field;
      const selectedWorkshops = workshopsRegistrations?.filter((registration) => field.workshops?.some((w) => w._id === registration.sessionId));
      return (!required || selectedWorkshops.length > 0) && (!minCount || selectedWorkshops.length >= Number(minCount));
    }
    case 'FlightField': {
      const { name, required } = field;
      const flightData = data[name];
      if (!required) return true;
      return flightData?.steps?.length > 0;
    }
    case 'CarbonFootprintTransportField': {
      return hasRequiredTransportData(data);
    }
    default: {
      return !!data[field.name];
    }
  }
}

// TODO: validate when parsing tree instead of extracting first :/ or memoize ?
function hasRequiredDataInFlatFields(fields, data) {
  return every(fields, (field) => isValidRequiredField(field, data));
}

export function hasRequiredData(fields, data, parentData) {
  return hasRequiredDataInFlatFields(extractRequiredFields(fields, data, parentData), data);
}

export function initializeData(form, params) {
  const fields = extractFields(form); //.map(f => f.name);
  const defaultData = (window.__DATA__ && window.__DATA__.data) || {};
  const defaultValues = keyBy(
    fields.filter((f) => f.hasOwnProperty('defaultValue') && !isExpression(f.defaultValue)),
    'name',
  );
  const defaultParams = mapValues(defaultValues, (field) => evalIfNeeded(field.defaultValue, defaultData));
  if (!params) {
    params = {
      ...defaultParams,
      ...defaultData,
    };
  } else {
    params = {
      ...defaultParams,
      ...defaultData,
      ...cleanObject(params),
    };
  }
  // console.log("params", params)
  // const fieldNames = fields.map(f => f.name);
  return mapValues(params /*pick(params, fieldNames)*/, (v) => {
    if (v === 'true') return true;
    else if (v === 'false') return false;
    return v;
  });
}

export function hasProducts(fields) {
  // TODO : optimize (simple recursion and/or memoize ?)
  return !!extractFields(fields).find((f) => f.type === 'ProductField');
}

export function isUpdate() {
  return window.__DATA__ && window.__DATA__.isUpdate;
}

export function isServiceV2() {
  return window.__DATA__?.api?.serviceVersion === 'v2';
}

export function getDefaultData() {
  return window.__DATA__?.data || {};
}
