import React, { PureComponent } from "react";
import FieldContainer from "../components/FieldContainer";
import "./product-field.css"

export default class ProductField extends PureComponent {
  
  render() {
    const { label, name, required = false, onChange, disabled, forceValue } = this.props;

    let {value} = this.props
    if (forceValue !== undefined){
      value = forceValue
    }

    return (
      <FieldContainer {...this.props} isRight>
        <input
          className="af-single-checkbox-field"
          type="checkbox"
          checked={value}
          name={name}
          value={value || false}
          required={required}
          readOnly={disabled}
          onChange={e => onChange(name, e.target.checked)}
        />
      </FieldContainer>
    );
  }
}
