import moment from "moment";
import { useMemo } from "react";

import every from "lodash/every";
import get from "lodash/get";

export function getDaysBetweenDates(start, end) {
  if (!start || !end) {
    return [];
  }

  const now = moment(start, "YYYY-MM-DD");
  const endDate = moment(end, "YYYY-MM-DD");
  const dates = [];
  while (now.isBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
}

export function checkIfLevelHasQuotaForDates(level, dates) {
  return every(dates, (date) => {
    const quotaInfo = level?.dates?.[date];
    if (!quotaInfo?.quota) return false;
    const { quota, userCount = 0 } = quotaInfo;
    return userCount < quota;
  });
}

const accommodationErrors = [
  "E_MISSING_ACCOMMODATION",
  "E_MISSING_ACCOMMODATION_LEVEL",
  "E_INVALID_RESERVATION_DATES",
  "E_ACCOMMODATION_FULL",
  "E_RESERVATION_NOT_FOUND",
  "E_ACCOMMODATION_BAD_REQUEST_PARAM",
];

function getErrorLabel(code, strings) {
  const errorString = get(strings, ["errors", code]);
  switch (code) {
    case "E_ACCOMMODATION_FULL":
      return errorString || "Malheureusement, il n'y a plus de disponibilité.";
    case "E_INVALID_RESERVATION_DATES":
      return errorString || "Les dates choisies sont invalides.";
    case "E_MISSING_ACCOMMODATION_LEVEL":
      return errorString || "Veuillez choisir votre hotel.";
    default:
      return get(strings, ["errors", "E_DEFAULT"]) || "Une erreur est survenue à la réservation de l'hôtel.";
  }
}

export function useAccommodationErrors(registrationErrors, strings) {
  return useMemo(() => {
    const filteredErrors = registrationErrors.filter(({ code }) => accommodationErrors.includes(code));
    return filteredErrors.map((err) => ({
      ...err,
      message: getErrorLabel(err.code, strings),
    }));
  }, [registrationErrors, JSON.stringify(strings)]);
}
