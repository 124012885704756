import React, { PureComponent } from 'react';
import { replaceValues } from './utils';

export default class Header extends PureComponent {
  render() {
    const { id, text = '', data, header, style } = this.props;
    const Component = header || 'h2';
    return (
      <Component id={id} style={style}>
        {replaceValues(text, data)}
      </Component>
    );
  }
}
