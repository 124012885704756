import React, { Component } from 'react';

class Message extends Component {
  render() {
    const { className, error, children, ...props } = this.props;
    return (
      <div {...this.props} className={`af-message ${error ? 'af-message--error' : ''} ${className || ''}`}>
        {children}
      </div>
    );
  }
}

export default Message;
