import React, { FC } from 'react';

type ErrorProps = {
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
};

const Error: FC<ErrorProps> = ({ message, children }) => (
  <div className="af-message af-message--error">
    {message}
    {children}
  </div>
);

export { Error };
