/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon';
import { bem } from '../../../core/bem';
import { RoomUser, User } from '../rooming.types';
import { getUserFullname, iconForStatus } from '../utils';
import './UserStatus.scss';

const css = bem('UserStatus');

type UserStatusProps = {
  roomingUser: RoomUser;
  user?: User;
  isOrganizer?: boolean;
};

const UserStatus = ({ roomingUser, user, isOrganizer }: UserStatusProps): JSX.Element | null => {
  const { t } = useTranslation();
  if (!roomingUser) return null;
  const { status } = roomingUser;
  const { name, color, backgroundColor } = get(iconForStatus, status) || {};
  return (
    <div className={css()}>
      <div className={css('icon')} style={{ backgroundColor }}>
        <Icon name={name} style={{ color }} />
      </div>
      <div className={css('fullname')}>
        {getUserFullname(user)}
        {isOrganizer && <div className="organizer">{t(`rooming.organizer`)}</div>}
      </div>
    </div>
  );
};

UserStatus.defaultProps = {
  isOrganizer: false,
};

export default UserStatus;
