import React, { PureComponent } from "react";

import Dropzone from "react-dropzone";
import querystring from "querystring";

function uploadAssetFile(file, options) {
  var formData = new FormData();
  formData.append("file", file);

  let url = window.__DATA__.api.image;
  if (options) {
    url += "?" + querystring.stringify(options);
  }

  return fetch(url, {
    method: "POST",
    body: formData
  }).then(res => res.json());
}

const Image = ({ src, style, ...props }) => (
  <div
    style={{ ...style, backgroundImage: `url(${src})`, backgroundPosition: "center", backgroundSize: "cover" }}
    {...props}
  />
);

const styles = {
  color: {
    width: 36,
    height: 14,
    borderRadius: 2
    // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
  },
  swatch: {
    padding: 5,
    background: "#fff",
    borderRadius: 1,
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  },
  popover: {
    position: "absolute",
    zIndex: 2
  },
  cover: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.25)"
  },
  dropzoneContainer: {
    cursor: "pointer",
    width: 50,
    height: 50,
    display: "table",
    position: "relative",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "rgba(0, 0, 0, 0.35)",
    backgroundColor: "#F7F7F7",
    borderRadius: 0
  },
  dropzoneActive: {
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eee"
  },
  dropzoneRejected: {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee"
  },
  dropzoneText: {
    display: "table-cell",
    width: "100%",
    height: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: 12
  }
};

export default class ImageUploader extends PureComponent {
  state = {
    uploading: false
  };
  handleDrop = async (accepted, rejected) => {
    const { maxWidth, maxHeight } = this.props;
    if (accepted.length > 0) {
      this.setState({ uploading: true });
      try {
        const json = await uploadAssetFile(accepted[0], {
          maxWidth,
          maxHeight
        });
        const { onChange, field } = this.props;
        if (onChange) {
          this.setState({ uploading: false });
          onChange(field, json);
        }
      } catch (e) {
        this.setState({ uploading: false });
        console.log(e);
      }
    } else {
      console.warn("no images to upload");
    }
  };

  handleRemove = e => {
    e.stopPropagation();
    e.preventDefault();
    const { onChange, field } = this.props;
    if (onChange) {
      onChange(field, null);
    }
  };

  render() {
    const { value, style = {}, placeholder, required, disabled } = this.props;
    const { uploading } = this.state;
    if (value && value.uri) {
      return (
        <div
          className="ac-table__image"
          style={{ ...styles.dropzoneContainer, cursor: "normal", padding: 0, ...style }}
        >
          <Image src={value.uri} style={{ width: "100%", height: "100%" }} />
          <div
            onClick={this.handleRemove}
            style={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              textAlign: "center",
              lineHeight: style.height ? style.height + "px" : "50px",
              cursor: "pointer"
            }}
          >
            ×
          </div>
        </div>
      );
    }
    return (
      <Dropzone
        style={{ ...styles.dropzoneContainer, ...style }}
        activeStyle={styles.dropzoneActive}
        rejectStyle={styles.dropzoneRejected}
        accept="image/jpg, image/jpeg, image/png, image/gif"
        multiple={false}
        onDrop={this.handleDrop}
        inputProps={{ required, disabled }}
      >
        <div style={styles.dropzoneText}>{uploading ? "upload..." : placeholder}</div>
      </Dropzone>
    );
  }
}
