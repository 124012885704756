import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FieldContainer from "../components/FieldContainer";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import toNumber from "lodash/toNumber";

const SliderWithTooltip = createSliderWithTooltip(Slider);
class SliderField extends PureComponent {
  render() {
    const { disabled, marks, max, min, name, onChange, showDots, showTooltip, step, styles, vertical, value } =
      this.props;
    const { trackStyle = {}, railStyle = {}, handleStyle = {}, dotStyle = {}, activeDotStyle = {} } = styles;
    const SliderComponent = showTooltip ? SliderWithTooltip : Slider;
    return (
      <FieldContainer {...this.props} vertical={vertical}>
        <div style={{ marginTop: vertical ? 10 : 0 }}>
          <SliderComponent
            className="af-slider-field"
            disabled={disabled}
            dots={showDots}
            marks={marks}
            max={max}
            min={min}
            onChange={(value) => onChange(name, value)}
            step={step}
            value={toNumber(value)}
            activeDotStyle={activeDotStyle}
            dotStyle={dotStyle}
            handleStyle={handleStyle}
            railStyle={railStyle}
            trackStyle={trackStyle}
          />
          {value && <input type="hidden" name={name} value={value} />}
        </div>
      </FieldContainer>
    );
  }
}

SliderField.defaultProps = {
  disabled: false,
  max: 10,
  min: 0,
  showDots: true,
  showTooltip: true,
  step: 1,
  styles: {},
  vertical: false,
};

Slider.propTypes = {
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  showDots: PropTypes.bool,
  showTooltip: PropTypes.bool,
  step: PropTypes.number,
  styles: PropTypes.object,
  value: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
};

export default SliderField;
