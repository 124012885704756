import React, { FC, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { FieldProps } from '../field.types';
import { useSetState } from 'ahooks';
import './FlightField.scss';
import { checkFlightNumberFormat, formatFlightInfos, formatFlightData, computeDuration, getIsoDuration } from './utils/flightUtils';
import { FlightStep, Flights } from './flight.types';
import { FlightCard } from './components/FlightCard/FlightCard';
import { useTranslation } from 'react-i18next';
import { FlightSearch } from './components/FlightSearch/FlightSearch';
import flightService from '../../services/flight.service';
import { useLoadingModal } from '../../utils/loader.utils';
import moment from 'moment';

type FlightFieldProps = FieldProps<Flights> & {
  description?: string;
  disclaimer?: string;
  allowMultiple?: boolean;
};

const FlightField: FC<FlightFieldProps> = (props) => {
  const { label, description, disclaimer, allowMultiple = true, onChange, name: fieldName, value: flights, disabled, mode, required } = props;

  if (disabled && !flights) return null;
  const { steps = [] } = flights || {};

  const { t } = useTranslation();

  const [{ flightNumber, departureTime }, setFlightState] = useSetState({ flightNumber: '', departureTime: '' });
  const [{ addConnection, error }, setState] = useSetState({ addConnection: false, error: '' });

  const [isLoading, setIsLoading] = useState(false);

  const isSummary = mode === 'summary';
  const stepsById = keyBy(steps, '_id');

  useLoadingModal(isLoading, () => setIsLoading(false));

  const onChangeAddFlight = (addFlightState: { addConnection?: boolean }) => {
    setFlightState({ departureTime: '', flightNumber: '' });
    setState(addFlightState);
  };

  const handleChange = (name: string, newValue: string) => {
    if (name === 'flightNumber' && newValue.length > 8) return;
    setFlightState({ [name]: formatFlightData(name, newValue) });
  };

  const changeFlightInfos = (flightInfos: FlightStep) => {
    const newSteps = [...steps, { ...flightInfos, flightNumber }];
    onChange(fieldName, formatFlightInfos(newSteps));
    setFlightState({ departureTime: '', flightNumber: '' });
    setState({ addConnection: false, error: '' });
  };

  const handleEdit = (flightId: string, flightInfos: Partial<FlightStep>) => {
    const newSteps = steps.map((step) => {
      if (step._id === flightId) {
        return { ...step, ...flightInfos };
      }
      return step;
    });

    onChange(fieldName, formatFlightInfos(newSteps));
  };

  const handleEditTime = (_id: string, type: 'departure' | 'arrival', newTime: string) => {
    const editedFlight = stepsById[_id];

    const { isoDuration, newIsoDepartureTime, newIsoArrivalTime } = getIsoDuration(editedFlight, type, newTime);

    const duration = computeDuration(isoDuration);
    if (type === 'departure') {
      handleEdit(_id, {
        departureTime: newTime,
        departureIsoDate: newIsoDepartureTime,
        departureDate: moment(newIsoDepartureTime).format('YYYY-MM-DD'),
        arrivalIsoDate: newIsoArrivalTime,
        arrivalDate: moment(newIsoArrivalTime).format('YYYY-MM-DD'),
        isoDuration,
        duration,
      });
    }
    if (type === 'arrival') {
      handleEdit(_id, {
        arrivalIsoDate: newIsoArrivalTime,
        arrivalDate: moment(newIsoArrivalTime).format('YYYY-MM-DD'),
        arrivalTime: newTime,
        departureIsoDate: newIsoDepartureTime,
        departureDate: moment(newIsoDepartureTime).format('YYYY-MM-DD'),
        isoDuration,
        duration,
      });
    }
  };

  const searchFlight = async () => {
    if (disableSearch || !flightNumber || !departureTime) return;
    setIsLoading(true);
    const { result } = await flightService.getFlight(flightNumber, departureTime);

    setIsLoading(false);
    if (result?.length) {
      changeFlightInfos(result[0]);
      return;
    }
    setState({ error: t('flight.error') });
  };

  const deleteFlight = (id?: string) => {
    if (!id) return;
    const newSteps = steps?.filter((f) => f._id !== id);
    onChange(fieldName, formatFlightInfos(newSteps));
  };

  const disableSearch = useMemo(() => {
    if (!flightNumber || !departureTime) return true;
    return !checkFlightNumberFormat(flightNumber);
  }, [flightNumber, departureTime]);

  return (
    <div className="flight-field-container">
      <h3>{label}</h3>
      {!isSummary ? (
        <>
          <div className="description">{description}</div>
          {!steps?.length && (
            <FlightSearch
              flightNumber={flightNumber}
              departureTime={departureTime}
              onChange={handleChange}
              onSearch={searchFlight}
              isLoading={isLoading}
              disabled={disableSearch}
              error={error}
              required={required}
            />
          )}
        </>
      ) : (
        !steps?.length && <span>{t('flight.no-flight-ticket')}</span>
      )}
      {!!steps?.length && (
        <>
          <FlightCard
            flights={steps}
            onDelete={deleteFlight}
            onEdit={handleEdit}
            onEditTime={handleEditTime}
            disclaimer={disclaimer}
            isSummary={isSummary}
          >
            {!isSummary && allowMultiple ? (
              addConnection ? (
                <FlightSearch
                  className="search-flight--spaced"
                  flightNumber={flightNumber}
                  departureTime={departureTime}
                  onChange={handleChange}
                  onCancel={() => onChangeAddFlight({ addConnection: false })}
                  onSearch={searchFlight}
                  isLoading={isLoading}
                  disabled={disableSearch}
                  error={error}
                  required={required}
                />
              ) : (
                <button type="button" className="add-connection-button" onClick={() => onChangeAddFlight({ addConnection: true })}>
                  + {t('flight.add-flight')}
                </button>
              )
            ) : null}
          </FlightCard>
        </>
      )}
    </div>
  );
};

export default FlightField;
