import React from 'react';
import PropTypes from 'prop-types';
import FieldContainer from './FieldContainer';
import i18n from '../translations';
import { markdownUrlify } from '../fields/CheckboxField';

function renderValue(type, value, summary) {
  const t = i18n.t;
  switch (type) {
    case 'CheckboxField': {
      const { values = {} } = summary || {};
      if (!value) return values['false'] || t('summary.checkbox.false');
      return values['true'] || t('summary.checkbox.true');
    }
    default:
      return value;
  }
}

const StringSummary = (props) => {
  const { summary, label, value, type, ...otherProps } = props;

  if (summary?.hidden) {
    return null;
  }

  // Auto-hide if empty
  if ((value === undefined || value === null || value === '') && type !== 'CheckboxField') {
    // TODO: handle `summary.messageIfEmpty` if required
    return null;
  }

  return (
    <FieldContainer {...otherProps} type={type || 'recap'} required={false} label={markdownUrlify(summary?.label || label)}>
      <div className="af-string-field-summary">
        <span className="value">{renderValue(type, value, summary)}</span>
      </div>
    </FieldContainer>
  );
};

StringSummary.propTypes = {
  label: PropTypes.string,
  summary: PropTypes.object,
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
};

StringSummary.defaultProps = {
  label: '',
  summary: {},
  value: undefined,
};

export default StringSummary;
