import cx from 'classnames';
import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import { isFieldLocked } from '../components/utils';
import { ReactComponent as AngleDownIcon } from './../assets/angle-down.svg';
import './select-field.scss';
import { filterOptions } from '../utils/fieldUtils';

const SelectArrow = () => (
  <div className="Select__IndicatorContainer">
    <span className="Select__IndicatorBar"></span>
    <div aria-hidden="true" className="Select__IndicatorArrow">
      <AngleDownIcon />
    </div>
  </div>
);

export default class SelectField extends PureComponent {
  render() {
    const { data, label, name, options, value, onChange, required = false, summary, mode, locked, disabled } = this.props;
    const visibleOptions = filterOptions(data, options);
    const isLocked = isFieldLocked(name, locked);
    if (mode === 'summary') {
      const labelValue = options.find((o) => o.value === value)?.label;
      return <StringSummary label={label} value={labelValue} summary={summary} />;
    }
    return (
      <FieldContainer {...this.props}>
        <select
          name={name}
          required={required}
          onChange={(e) => onChange(name, e.target.value)}
          value={value || ''}
          className={cx('af-select-field', { 'is-active': value })}
        >
          {/* Auto-hide empty option if another value is selected */}
          {(!value || !required) && (
            <option value="" disabled={disabled || isLocked || required} hidden={required} selected={!value && required}></option>
          )}
          {visibleOptions.map((option) => {
            const { value, label } = option;
            return (
              <option key={value} value={value} disabled={disabled || isLocked}>
                {label}
              </option>
            );
          })}
        </select>
        <SelectArrow />
      </FieldContainer>
    );
  }
}
