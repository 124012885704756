import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import React, { Component, createContext } from 'react';
import { isSummaryModeForced } from '../../Form';
import { extractCurrentFields, hasRequiredData } from '../../utils';
import { evalCondition } from '../../utils/conditions';
import { scrollToTop } from '../../utils/domUtils';
import { FormMode } from '../../utils/formUtils';
import FormFields from '../FormFields';
import SubmitButton from '../SubmitButton';
import { FieldProps } from '../field.types';
import '../tabs.scss';
import { Header } from './Header';
import Tab from './Tab';

type TabsContextType = { hideTabHeader: boolean; onTabChange?: (tabIndex: number) => void };
export const TabsContext = createContext<TabsContextType>({ hideTabHeader: false, onTabChange: undefined });

function hasFields(tab: Record<string, any>, data: Record<string, any>) {
  const { fields } = tab;
  return !!fields.find((f: any) => evalCondition(f.condition, data));
}
type GenericFieldProps = {
  registrationErrors: any;
  onChangeMode: any;
};

type TabsProps = {
  activeTab: number;
  tabsName?: string;
  persistOnTabChange?: boolean;
  data: Record<string, any>;
  tabs: Array<any>;
  autoHideEmptyTabs?: boolean;
  onChange: (fieldName: string, value: any) => void;
  showNextTabOnUpdate?: boolean;
  showPreviousButton?: boolean;
  errors: any;
  strings: Record<string, any>;
  mode: string;
  formMode: string;
  summary: any;
  value: any;
} & GenericFieldProps;

type TabsState = {
  activeIndex: number;
};

type TabField = any;

export function getCurrentTabs({
  tabs,
  autoHideEmptyTabs,
  data,
}: {
  tabs: TabField[];
  autoHideEmptyTabs: boolean | undefined;
  data: any;
}): TabField[] {
  return tabs
    .filter((tab) => !tab.hidden && evalCondition(tab.condition, data))
    .filter((tab) => !autoHideEmptyTabs || hasFields(tab, data))
    .map((tab) => ({
      ...tab,
      isFinished: hasRequiredData(tab.fields, data),
    }));
}

class Tabs extends Component<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props);
    const { activeTab, data, tabs } = props;
    this.state = {
      activeIndex: (data?.step ? Math.min(tabs.length - 1, data?.step) : activeTab) || 0,
    };
  }

  handleTabChange = (activeIndex: number) => {
    const { onTabChange } = this.context;
    if (onTabChange) {
      // Bypass state
      onTabChange(activeIndex);
      return;
    }
    this.setState({ activeIndex }, scrollToTop);
  };

  getCurrentTabs = () => {
    const { tabs, autoHideEmptyTabs, data } = this.props;

    return getCurrentTabs({ tabs, autoHideEmptyTabs, data });
  };

  componentWillReceiveProps = (nextProps: TabsProps) => {
    const { activeTab } = this.props;
    if (activeTab !== nextProps.activeTab) {
      this.setState({ activeIndex: nextProps.activeTab }, scrollToTop);
    }
  };

  render() {
    const {
      activeTab,
      data,
      errors,
      onChange,
      showNextTabOnUpdate,
      strings,
      persistOnTabChange,
      mode,
      formMode,
      registrationErrors,
      summary,
      showPreviousButton,
      ...rest
    } = this.props;
    const { hideTabHeader } = this.context;
    const { activeIndex } = this.state;
    const currentTabs = this.getCurrentTabs();
    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      const isSummaryForced = isSummaryModeForced() || formMode === FormMode.UPDATE_SUMMARY;
      return (
        <div className="tabs__summary">
          {currentTabs
            .filter((t) => !t.summary?.hidden)
            .map((tab, index) => {
              const { _id, label, fields = [] } = tab;
              const allTabFields = extractCurrentFields(fields, data) as FieldProps<any>[];
              const fieldNames = allTabFields.map((f) => f.name).filter((v) => v);
              const tabData = pick(data, fieldNames);
              const isTabEmpty = Object.values(tabData).every(isEmpty);
              if (isTabEmpty) return null;
              return (
                <div key={_id || index} className={`tab tab_${_id}`}>
                  <h1>{label}</h1>
                  <FormFields
                    {...rest}
                    data={data}
                    mode={mode}
                    formMode={formMode}
                    fields={fields}
                    errors={errors}
                    onChange={onChange}
                    activeTab={activeTab}
                    registrationErrors={registrationErrors}
                  />
                </div>
              );
            })}
          {!isSummaryForced && <SubmitButton mode={mode} summary={summary} onChangeMode={rest.onChangeMode} />}
        </div>
      );
    }
    return (
      <div className="af-tabs">
        {!hideTabHeader && (
          <Header activeIndex={activeIndex} tabs={currentTabs} data={data} onSelect={this.handleTabChange} persistOnTabChange={persistOnTabChange} />
        )}
        {currentTabs.map((col, index) => (
          <Tab
            key={index}
            strings={strings}
            onSelect={this.handleTabChange}
            index={index}
            active={index === activeIndex}
            isLast={index === currentTabs.length - 1}
            {...col}
            data={data}
            errors={errors}
            onChange={onChange}
            showNextTabOnUpdate={showNextTabOnUpdate}
            showPreviousButton={showPreviousButton}
            persistOnTabChange={persistOnTabChange}
            registrationErrors={registrationErrors}
          />
        ))}
      </div>
    );
  }
}

Tabs.contextType = TabsContext;

export { Tabs };
