import React, { PureComponent } from 'react';
import Images from '../utils/Images';

export default class ImageBlock extends PureComponent {
  render() {
    const { image, name, style = {}, width = '100%', height = 'auto' } = this.props;
    return (
      <div className="af-field-container af-field-container--Image">
        <img src={Images.maxWidth(image, 1200)} style={{ ...style }} alt={name} width={width} height={height} />
      </div>
    );
  }
}
