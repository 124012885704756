import toNumber from "lodash/toNumber";
import get from "lodash/get";

export function formatValue(value, format) {
  if (!format) return value;
  return `${value}${format}`;
}

export function getNumericValue(field, data, name) {
  const { formatter } = field;
  const value = get(data, name) || 0;
  if (!formatter) return value;
  if (typeof value === "string") return toNumber(value.replace(formatter, ""));
  return toNumber(value);
}
