import React, { PureComponent } from 'react';
import { evalCondition } from '../utils/conditions';
import Quota from './Quota';
import { computePercentage } from './Quota/quota.utils';
import { replaceValues } from './utils';
import './workshop.css';

const defaultLabel = 'Vous pouvez vous inscrire à {count} activités';

const defaultStrings = {
  selectedCount: 'Activités sélectionnées',
  alreadyAtLimit: 'Vous avez déjà {count} ateliers',
};

async function put(url, data) {
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await res.json();
}

function hasValue(quota) {
  return quota || quota === 0 || quota === '0';
}

function computeRoomLeft(slots) {
  let roomLeft = 0,
    quota = 0;
  for (const slot of slots) {
    if (!hasValue(slot.quota)) return null; // No quota management
    roomLeft += slot.roomLeft;
    quota += slot.quota;
  }
  return { roomLeft, quota };
}

class Arrow extends PureComponent {
  render() {
    const { direction = 'right', ...props } = this.props;
    return <i {...props} className={`af-arrow af-arrow--${direction}`} />;
  }
}

export class Accordion extends PureComponent {
  static defaultProps = {
    defaultOpen: false,
  };
  state = {
    open: this.props.defaultOpen,
  };

  toggle = () => this.setState((state) => ({ open: !state.open }));

  render() {
    const { header, children } = this.props;
    const { open } = this.state;
    return (
      <div>
        <div style={{ display: 'table' }}>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'top',
              padding: '0px 12px',
              cursor: 'pointer',
            }}
            onClick={this.toggle}
          >
            <Arrow direction={open ? 'down' : 'right'} />
          </div>
          <div style={{ display: 'table-cell', verticalAlign: 'top' }}>
            <div onClick={this.toggle} style={{ cursor: 'pointer' }}>
              {header}
            </div>
            <div style={{ display: open ? 'block' : 'none' }}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

function hasTag(slotTags, currentTags) {
  if (slotTags) {
    for (const tag of slotTags) {
      if (currentTags[tag]) return true;
    }
  }
  return false;
}

function hasSlot(slots, user) {
  for (const s of slots) {
    if (user[s.id || s.key]) return true;
  }
  return false;
}

function findWorkshop(workshops, key) {
  for (const w of workshops) {
    if (w.key === key) return w;
    if (w.slots) {
      for (const s of w.slots) {
        if (s.key === key) return s;
      }
    }
  }
  return null;
}

export default class WorkshopRegistration extends PureComponent {
  state = {
    data: this.props.data,
    workshops: this.props.workshops,
  };

  getCount() {
    const { count } = this.props;
    if (typeof count === 'string' && count[0] === '{') {
      const countText = replaceValues(count, this.props.data);
      return parseInt(countText);
    }
    return count;
  }

  toggle = async (id, value, slotSize) => {
    const { url, strings } = this.props;

    const count = this.getCount();

    if (value) {
      if (this.computeCount() > count + (slotSize || 1)) {
        const label = strings.alreadyAtLimit || defaultStrings.alreadyAtLimit;
        alert(label.replace('{count}', count));
        return; // Can't toggle
      }
      const w = findWorkshop(this.state.workshops, id);
      const usedTags = this.computeCurrentTags();
      if (w && hasTag(w.tags, usedTags)) {
        // alert("Activité incompatible avec vos autres activités");
        return;
      }
    }

    try {
      const res = await put(url, {
        [id]: value,
      });
      const { user, workshops } = res;
      if (user && workshops) {
        this.setState({
          data: user,
          workshops,
        });
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  computeCount = () => {
    const { data, workshops } = this.state;
    let count = 0;
    for (const w of workshops) {
      if (w.slots) {
        for (const s of w.slots) {
          if (data[s.id || s.key]) count += s.slotSize || 1;
        }
      } else {
        if (data[w.id || w.key]) count += w.slotSize || 1;
      }
    }
    return count;
  };

  computeCurrentTags = () => {
    const { data, workshops } = this.state;
    const tags = {};
    function addTags(newTags) {
      if (newTags) {
        for (const t of newTags) tags[t] = true;
      }
    }
    for (const w of workshops) {
      if (w.slots) {
        for (const s of w.slots) {
          if (data[s.id || s.key]) {
            addTags(s.tags);
          }
        }
      } else {
        if (data[w.id || w.key]) {
          addTags(w.tags);
        }
      }
    }
    return tags;
  };

  async registerQuota(slot, quota) {
    const { url } = this.props;
    try {
      const res = await put(url, {
        [slot.key]: quota,
      });
      const { user, workshops } = res;
      if (user && workshops) {
        this.setState({
          data: user,
          workshops,
        });
        if (user[slot.key] != quota) {
          // Failed
          return 'INSUFFICIENT_REMAINING';
        } else {
          return 'OK';
        }
      }
      return 'ERROR';
    } catch (e) {
      console.log('ERROR', e);
      return 'ERROR';
    }
  }

  getConfirmInfo(obj) {
    const { confirmIfAboveValue, confirmIfAboveMessage } = obj;
    if (!confirmIfAboveMessage) return null;
    return { value: confirmIfAboveValue, message: confirmIfAboveMessage };
  }

  handleQuotaChange = (e, workshop, slot) => {
    e.preventDefault();
    e.stopPropagation();

    const askForQuota = async (message) => {
      const { data = {} } = this.props;
      const current = data[slot.key] || 0;

      const value = window.prompt(
        `Change quota for ${workshop.name} - ${slot.name} ?
You currently allocated quota is ${current}
${message}`,
        current,
      );
      if (value !== null) {
        try {
          const intValue = parseInt(value.trim(), 10);
          if (isNaN(intValue)) {
            askForQuota('Invalid value, please try again');
          } else {
            // Contact server
            const result = await this.registerQuota(slot, intValue);
            switch (result) {
              case 'OK': {
                // Check quota
                const confirmInfo = this.getConfirmInfo(slot) || this.getConfirmInfo(workshop);
                if (confirmInfo && intValue >= confirmInfo.value) {
                  window.alert(confirmInfo.message);
                }
                // Use timeout to let the UI refresh first
                // return setTimeout(() => window.alert("Your desired quota has been allocated"), 100);
                return;
              }
              case 'INSUFFICIENT_REMAINING':
                return askForQuota('Insufficient remaining space');
              case 'ERROR':
              default:
                return askForQuota('An error occured, please try again');
            }
          }
        } catch (e) {
          askForQuota('Invalid value, please try again');
        }
      }
    };
    askForQuota('');
  };

  render() {
    const { label = defaultLabel, style, strings = {}, data: userData, alwaysOpen = false, quotaMode } = this.props;
    const { data, workshops } = this.state;
    // console.log("toggle url", url)
    const registeredCount = this.computeCount();
    const count = this.getCount();
    const remainingSlots = count - registeredCount;
    const usedTags = this.computeCurrentTags();

    const { selectedCount = defaultStrings.selectedCount } = strings;
    // defaultStrings;

    const registreredLabel = `${selectedCount} : ${registeredCount} ${count ? `/ ${count}` : ''}`;

    return (
      <div className="af-field-container--WorkshopRegistration" style={{ ...style }}>
        <div>{label.replace('{count}', count)}</div>

        {workshops.map((workshop) => {
          if (!evalCondition(workshop.condition, userData)) {
            return null;
          }
          if (workshop.type === 'Header') {
            return <h3 style={{ marginTop: 8, marginBottom: 8, ...workshop.style }}>{workshop.label}</h3>;
          }
          if (workshop.type === 'Text') {
            return (workshop.text || '').split('\n').map((text) => <p style={workshop.style}>{text}</p>);
          }
          const { usePercentages } = workshop;
          const hasSlots = !!workshop.slots;
          if (hasSlots) {
            // Compute total available
            const slotsLeft = computeRoomLeft(workshop.slots);

            // Has a free slot ?
            const isAvailable = !!workshop.slots.find((slot) => data[slot.key] || !hasTag(slot.tags, usedTags));

            return (
              <Accordion
                key={workshop.key}
                style={{ margin: 0 }}
                defaultOpen={alwaysOpen || hasSlot(workshop.slots, data)}
                header={
                  <div style={{ opacity: isAvailable ? 1 : 0.5 }}>
                    <div style={{ padding: '2px 0' }}>
                      {workshop.name} <Quota {...slotsLeft} strings={strings} usePercentages={usePercentages} quotaMode={quotaMode} />
                    </div>
                    {workshop.description && (
                      <div
                        style={{
                          fontWeight: 'normal',
                          fontSize: '0.9em',
                          lineHeight: '1.5em',
                          opacity: 0.85,
                        }}
                      >
                        {workshop.description.split('\n').map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              >
                {this.renderSlots(workshop, { usedTags, remainingSlots })}
              </Accordion>
            );
          }
          return (
            <div key={workshop.key} style={{ margin: 0 }}>
              <label style={{ display: 'table', width: '100%' }}>
                <input
                  type="checkbox"
                  style={{ display: 'table-cell' }}
                  name={workshop.key}
                  checked={!!data[workshop.key]}
                  onChange={(evt) => this.toggle(workshop.key, evt.target.checked)}
                />
                <div style={{ display: 'table-cell', width: '100%' }}>
                  <div>
                    {workshop.name} <Quota roomLeft={workshop.roomLeft} quota={workshop.quota} quotaMode={quotaMode} />
                  </div>
                  {workshop.description && (
                    <div
                      style={{
                        fontWeight: 'normal',
                        fontSize: '0.9em',
                        lineHeight: '1.5em',
                        opacity: 0.85,
                      }}
                    >
                      {workshop.description.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  )}
                </div>
              </label>
            </div>
          );
        })}
        <div className="text--selected-count" style={{ paddingTop: 8 }}>
          {registreredLabel}
        </div>
      </div>
    );
  }

  renderSlots(workshop, { usedTags, remainingSlots }) {
    const { strings = {}, data: userData, quotaMode } = this.props;
    const { data } = this.state;
    const { slots, workshopType, usePercentages } = workshop;

    const visibleSlots = slots.filter((slot) => evalCondition(slot.condition, userData));

    if (workshopType === 'quota') {
      return (
        <table className="table--simple table--workshop-quota" style={{ width: '100%' }}>
          <tbody>
            {visibleSlots.map((slot, index) => (
              <tr key={index} onClick={(e) => this.handleQuotaChange(e, workshop, slot)}>
                <td className="col--name">{slot.name}</td>
                <td className="col--allocated">
                  <button type="button" className="af-button--small" onClick={(e) => this.handleQuotaChange(e, workshop, slot)}>
                    <i className={`fa fa-pencil`} />
                  </button>
                  {data[slot.key] || 0} allocated
                </td>
                {!slot.quota && <td className="col--remaining" />}
                {slot.quota && <td className="col--remaining">{computePercentage(slot.roomLeft, slot.quota)} remaining</td>}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <div>
        {visibleSlots.map((slot, index) => {
          const lockedByTag = hasTag(slot.tags, usedTags);
          const lockedBySlots = slot.slotSize ? remainingSlots < slot.slotSize : false;
          const disabled = lockedByTag || lockedBySlots;
          return (
            <label
              key={index}
              style={{
                display: 'table',
                width: '100%',
                padding: 0,
                fontWeight: '0.9em',
                opacity: !data[slot.key] && disabled ? 0.5 : 1, // Show disabled if tag-stuck and not used
              }}
            >
              <input
                type="checkbox"
                style={{ display: 'table-cell' }}
                name={slot.key}
                checked={!!data[slot.key]}
                onChange={(evt) => this.toggle(slot.key, evt.target.checked, slot.slotSize)}
              />
              <div style={{ display: 'table-cell', width: '100%' }}>
                <div>
                  {slot.name}{' '}
                  <Quota roomLeft={slot.roomLeft} quota={slot.quota} strings={strings} usePercentages={usePercentages} quotaMode={quotaMode} />
                </div>
                {slot.description && (
                  <div
                    style={{
                      fontWeight: 'normal',
                      fontSize: '0.9em',
                      lineHeight: '1.5em',
                      opacity: 0.85,
                    }}
                  >
                    {slot.description.split('\n').map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </div>
                )}
              </div>
            </label>
          );
        })}
      </div>
    );
  }
}
