import React from "react";
import HiddenField from "./HiddenField";

export type HiddenFieldValueType = string | number | readonly string[];
export type HiddenFieldKeyValuePair = [name: string, value: HiddenFieldValueType];

export interface HiddenFieldsProps {
  kvPairs?: HiddenFieldKeyValuePair[];
}

const HiddenFields: React.FC<HiddenFieldsProps> = ({ kvPairs }) => {
  if (!kvPairs || kvPairs.length === 0) return null;
  return (
    <>
      {kvPairs.map(([key, value]) => (
        <HiddenField key={key} name={key} value={value} />
      ))}
    </>
  );
};

export default HiddenFields;
