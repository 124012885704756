import React, { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FlightSearchInputs } from './FlightSearchInputs';
import { SearchButton } from '../SearchButton/SearchButton';

interface FlightSearchProps {
  flightNumber: string;
  departureTime: string;
  onChange: (name: string, value: string) => void;
  onCancel?: () => void;
  onSearch: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  className?: string;
}

export const FlightSearch: FC<FlightSearchProps> = (props) => {
  const { className, flightNumber, departureTime, onChange, onCancel, onSearch, isLoading, disabled, error, required } = props;
  const { t } = useTranslation();

  return (
    <div className={cx('search-flight', className)}>
      <FlightSearchInputs flightNumber={flightNumber} departureTime={departureTime} onChange={onChange} required={required} />
      {error && <p className="search-error">{error}</p>}
      <div className="buttons-wrapper">
        {onCancel && (
          <button type="button" className="add-connection-button" onClick={onCancel}>
            {t('btn.cancel')}
          </button>
        )}
        <SearchButton onClick={onSearch} isLoading={isLoading} disabled={disabled} />
      </div>
    </div>
  );
};
