import cx from 'classnames';
import React from 'react';
import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import { hasOriginalData } from '../utils';

export const NumberField = (props: any): JSX.Element | null => {
  const { id, label, placeholder, name, min, max, value, onChange, required, disabled, locked, mode, error: propsError } = props;

  const onValueChange = (v: string) => {
    if (!v) {
      // Empty string
      onChange(name, null);
    } else if (!isNaN(+v)) {
      onChange(name, +v);
    }
  };

  const error = isNaN(+value) || propsError;

  if (disabled && !value) return null; // Hide disabled field
  if (mode === 'summary') {
    return <StringSummary {...props} value={value} />;
  }

  return (
    <FieldContainer {...props} error={error} className={cx('mode', mode)}>
      <input
        id={id}
        className={cx('af-number-field', { 'has-error': error })}
        name={name}
        type="number"
        placeholder={placeholder || label}
        value={value}
        required={required}
        readOnly={disabled || (locked && hasOriginalData(name))}
        onChange={(e) => onValueChange(e.target.value)}
        min={min}
        max={max}
      />
    </FieldContainer>
  );
};
