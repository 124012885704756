/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { AccommodationRoomStatus, AccommodationRoomUserStatus, RoomingAccommodation, RoomType, CandidateUser } from '../rooming.types';
import RoomingButton from './Button';
import UserStatus from './UserStatus';
import cx from 'classnames';
import Icon from '../../../components/Icon';
import { useTranslation } from 'react-i18next';
import './Invitation.scss';
import i18n from '../../../translations';

export type OnStatusChange = (roomId: string, roomUserId: string | undefined, status: AccommodationRoomUserStatus) => void;

function getRoomDescription(room: RoomType) {
  const { status } = room;
  switch (status) {
    case AccommodationRoomStatus.QUOTA_ERROR:
      return i18n.t('rooming.room-quota-reached');
    case AccommodationRoomStatus.VALID:
      return i18n.t('rooming.room-has-been-validated');
    case AccommodationRoomStatus.REJECTED:
      return i18n.t('rooming.room-has-been-rejected-by-you');
    default:
      const { users = [] } = room;
      if (users.every((u) => u.status === AccommodationRoomUserStatus.ACCEPTED)) return i18n.t('rooming.you-have-validated-the-room');
      if (users.some((u) => u.status === AccommodationRoomUserStatus.REJECTED)) return i18n.t('rooming.you-have-rejected-the-room');
      return '';
  }
}

type InvitationProps = {
  accommodations: RoomingAccommodation[];
  room: RoomType;
  usersById: Record<string, CandidateUser>;
  onStatusChange: OnStatusChange;
  mode: string;
};

export const Invitation: FC<InvitationProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const { accommodations, room, usersById, onStatusChange, mode } = props;
  const me = window.__DATA__.data;
  const { _id: roomId, users = [], owner, status: roomStatus } = room;
  const accommodation = accommodations.find((a) => a._id === room.accommodationId);
  const level = accommodation?.levels.find((l) => l._id === room.levelId);
  const isOwner = owner._id === me._id;
  const { _id: roomUserId, status: userStatus } = users.find((u: any) => u.userId === me._id) || {};
  return (
    <div className={cx('af-card af-card--rooming-invitation', isOwner ? 'af-card--rooming-invitation--other' : 'af-card--rooming-invitation--me')}>
      <h3>
        <Icon name="hotel" /> {accommodation?.title} ({level?.name})
      </h3>
      <div>
        <div className="rooming-count">{t('rooming.roommates', { count: room.users.length })}</div>
        {room.users.map((u) => (
          <div key={u.userId}>
            <UserStatus roomingUser={u} user={usersById[u.userId]} isOrganizer={room.owner?._id === u.userId} />
          </div>
        ))}
      </div>
      {!isOwner && roomStatus === AccommodationRoomStatus.PENDING && userStatus === AccommodationRoomUserStatus.PENDING && mode !== 'summary' && (
        <div className="af-card__actions">
          <RoomingButton
            error
            icon="times"
            content={t(`rooming.refuse-roommate`)}
            onClick={() => onStatusChange(roomId, roomUserId, AccommodationRoomUserStatus.REJECTED)}
          />
          <RoomingButton
            success
            icon="check"
            content={t(`rooming.accept-roommate`)}
            onClick={() => onStatusChange(roomId, roomUserId, AccommodationRoomUserStatus.ACCEPTED)}
          />
        </div>
      )}
      <div className="description">{getRoomDescription(room)}</div>
    </div>
  );
};
