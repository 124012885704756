import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FieldContainer from '../../../components/FieldContainer';
import Icon from '../../../components/Icon';
import { translateOptions } from '../../../translations/translations.utils';
import { setIn } from '../../../utils/objectUtils';
import SelectField from '../../SelectField';
import { CarbonFootprintTransport } from '../carbon-footprint.types';
import { computeTransportCO2Kg, roundValue } from '../utils/carbonFootprint.utils';
import FromAndToFields from './FromAndToFields';
import './TransportFootprint.scss';

export const transportTypes = orderBy(
  translateOptions(
    ['none', 'walk', 'bike', 'electric-bike', 'electric-scooter', 'plane', 'tgv', 'ter', 'car', 'carpooling', 'metro', 'bus'],
    'carbon-footprint.transports',
  ),
  // Order by label, with `none` first
  ({ value, label }) => (value === 'none' ? '' : label),
);

export const engineTypes = translateOptions(
  ['essence', 'diesel', 'electrique', 'hybride essence', 'hybride diesel', 'hydrogene'],
  'carbon-footprint.motorisations',
);

const restrictedTypes: Record<string, string[]> = {
  plane: ['airport'],
};

type TransportFootprintProps = {
  index: number;
  mode?: 'summary';
  value?: CarbonFootprintTransport;
  onChange: (value: CarbonFootprintTransport) => void;
  onDelete?: () => void;
};

export const TransportFootprint: FC<TransportFootprintProps> = ({ index, mode, value: entry, onChange, onDelete }) => {
  const { t } = useTranslation();

  async function handleChange(key: string, value: unknown) {
    const prevValue = (entry as any)?.[key] as string | undefined;
    const newEntry = setIn(omit(entry || {}, 'computed'), key, value) as CarbonFootprintTransport;
    if (key === 'transportType' && (value === 'car' || prevValue === 'car')) {
      // Flush engineType
      newEntry.engineType = undefined;
    }
    const { from, to, distanceKm } = newEntry;
    if ((from && to) || typeof distanceKm === 'number') {
      const computed = await computeTransportCO2Kg(newEntry);
      if (computed) {
        newEntry.computed = computed;
      }
    }
    onChange(newEntry);
  }
  return (
    <div className="TransportFootprint">
      <div className="TransportFootprint__header">
        <h4>{t('carbon-footprint.labels.trip', { index: index + 1 })}</h4>
        {onDelete && (
          <button type="button" className="af-form-button" onClick={onDelete}>
            <Icon name="trash" />
          </button>
        )}
      </div>
      <SelectField
        type="SelectField"
        name="transportType"
        label={t('carbon-footprint.labels.transport-type')}
        required
        mode={mode}
        value={entry?.transportType}
        options={transportTypes}
        onChange={handleChange}
      />

      {entry?.transportType === 'car' && (
        <SelectField
          type="SelectField"
          name="engineType"
          label={t('carbon-footprint.labels.engine')}
          required
          mode={mode}
          value={entry?.engineType}
          options={engineTypes}
          onChange={handleChange}
        />
      )}
      {entry?.transportType && entry?.transportType !== 'none' && entry?.transportType !== 'carpooling' && (
        <FromAndToFields
          // Force reboot the fields if we change the autocomplete keys
          key={JSON.stringify(restrictedTypes[entry.transportType])}
          mode={mode}
          from={entry?.from}
          to={entry?.to}
          distanceKm={entry?.distanceKm}
          types={restrictedTypes[entry.transportType]}
          onChange={handleChange}
        />
      )}
      {!!entry?.computed && (
        <>
          <FieldContainer label={t('carbon-footprint.labels.carbon-footprint')} type="StringField">
            {roundValue(entry.computed.CO2Kg)} kgCO<sub>2</sub> ({roundValue(entry.computed.distanceKm)} km)
          </FieldContainer>
        </>
      )}
    </div>
  );
};
