import { useMount } from 'ahooks';
import get from 'lodash/get';
import padStart from 'lodash/padStart';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { getTimeLeft } from '../utils/dateUtils';
import './Countdown.scss';
import Summary from './Summary';

export function useAutoRefresh(milliseconds) {
  const [now, setNow] = useState(Date.now());

  useMount(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, milliseconds);
    return () => clearInterval(interval);
  });
  return now;
}

export function useTimeLeft(date) {
  const now = useAutoRefresh(1000);
  return getTimeLeft(now, date);
}

const Countdown = ({ data, session, onCancelSession }) => {
  const { ticketing } = window.__DATA__;
  const timeLeft = useTimeLeft(session?.expirationDate);
  const { fields = [], strings = {} } = ticketing;
  const text = get(
    strings,
    'fill-session-before-expiration',
    `Votre session expirera au bout de ${moment.duration(session?.bookingDuration).minutes()} minutes`,
  );
  const cancelLabel = get(strings, 'cancel-button', 'Annuler');
  const anotherSessionLabel = get(strings, 'another-session-will-begin-in-3s', 'Une autre session recommancera dans 3s.');
  const expiredSession = get(strings, 'session-expired', 'Le temps de validation a été écoulé. La session a expiré !');
  useEffect(() => {
    if (!timeLeft) {
      Swal.fire({
        icon: 'info',
        title: expiredSession,
        html: anotherSessionLabel,
        timer: 3000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload(false);
        }
      });
    }
  }, [timeLeft]);

  return (
    <div className="countdown">
      <div className="countdown__container">
        <div className="content">
          <div className="timeLeft">
            {padStart(timeLeft?.minutes || 0, 2, '0')}:{padStart(timeLeft?.seconds || 0, 2, '0')}
          </div>
          <div>
            {text && <div className="text">{text}</div>}
            <button type="button" className="countdown__back af-form-button" onClick={onCancelSession} disabled={!timeLeft}>
              {cancelLabel}
            </button>
          </div>
        </div>
        <div className="countdown__summary">
          <Summary fields={fields} data={data} />
        </div>
      </div>
    </div>
  );
};

Countdown.propTypes = {
  data: PropTypes.object.isRequired,
  onCancelSession: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default Countdown;
