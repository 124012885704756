import { UpdateMode } from '../components/summary/summary.types';

export const FormMode = {
  TICKETING: 'ticketing',
  FORM: 'form',
  SUMMARY: 'summary',
  UPDATE_SUMMARY: 'summary-with-update',
  CONFIRMATION: 'confirmation',
};

export function isUpdateWithSummary() {
  const { options, isUpdate } = window.__DATA__;
  const { updateMode } = options || {};
  return (
    isUpdate &&
    (updateMode?.mode === UpdateMode.SummaryWithUpdate ||
      updateMode?.mode === UpdateMode.SummaryOnly ||
      updateMode?.mode === UpdateMode.UpdateButtonWithoutSummary)
  );
}
