/*eslint-disable @typescript-eslint/no-var-requires*/
import trim from 'lodash/trim';
import orderBy from 'lodash/orderBy';
import { FlightStep, Flights } from '../flight.types';
import i18n from '../../../translations';
import moment, { Duration } from 'moment';

export const FLIGHT_NUMBER_REGEX = /^[A-Z,0-9]{2,3}[0-9]{1,4}$/;

export const checkFlightNumberFormat = (flightNumber: string) => {
  const isMatching = new RegExp(FLIGHT_NUMBER_REGEX).test(flightNumber);
  return isMatching;
};

export const divideFlightNumber = (flightNumber: string): RegExpMatchArray | null => {
  if (!flightNumber) return null;
  if (flightNumber.length < 3) {
    return [flightNumber];
  }
  if (/[^a-zA-Z]/.test(flightNumber[2])) {
    return [flightNumber.slice(0, 2), flightNumber.slice(2)];
  }
  return [flightNumber.slice(0, 3), flightNumber.slice(3)];
};

export const formatFlightData = (name: string, value: string) => {
  if (name === 'flightNumber') {
    return trim(value, '_ ').replace(' ', '').toUpperCase();
  }
  return value;
};

export const computeDuration = (isoDuration: string) => {
  const hasHours = isoDuration.includes('H');
  const splittedHour = isoDuration.split('T')[1]?.split('H');
  if (hasHours) {
    return i18n.t('dates.relative-time.time', { hours: splittedHour?.[0] || 0, minute: splittedHour?.[1]?.split('M')[0] || 0 });
  }
  return i18n.t('dates.relative-time.time', { hours: 0, minute: splittedHour?.[0]?.split('M')[0] || 0 });
};

export const formatFlightInfos = (steps: FlightStep[]): Flights => {
  if (!steps?.length) return { steps: [] };
  const orderedSteps = orderBy(steps, ['departureDate', 'departureTime'], ['asc', 'asc']);

  let duration: Duration | undefined;

  orderedSteps.forEach((step) => {
    const hasHours = step.isoDuration?.includes('H');
    const splittedDuration = step.isoDuration?.split('T')[1]?.split('H');
    if (!splittedDuration) return;
    const addedDuration = hasHours
      ? moment.duration({ hours: Number(splittedDuration[0]), minutes: Number(splittedDuration[1].split('M')[0]) })
      : moment.duration({ hours: 0, minutes: Number(splittedDuration[0].split('M')[0]) });

    duration = moment.duration(duration || { minutes: 0, hours: 0 }).add(addedDuration);
  });

  const firstStep = orderedSteps[0];
  const lastStep = orderedSteps[orderedSteps.length - 1];

  return {
    steps: orderedSteps.map((s) => ({ ...s, duration: computeDuration(s.isoDuration || '') })),
    flightNumber: lastStep.flightNumber,
    departureTime: firstStep.departureTime,
    departureDate: firstStep.departureDate,
    departureIsoDate: firstStep.departureIsoDate,
    departureAirportCode: firstStep.departureAirportCode,
    departureAirport: firstStep.departureAirport,
    arrivalTime: lastStep.arrivalTime,
    arrivalDate: lastStep.arrivalDate,
    arrivalIsoDate: lastStep.arrivalIsoDate,
    arrivalAirportCode: lastStep.arrivalAirportCode,
    arrivalAirport: lastStep.arrivalAirport,
    duration: moment.utc(duration?.as('milliseconds')).format('H[h]mm[min]'),
  };
};

export const getIsoDuration = (
  editedFlight: FlightStep,
  type: 'departure' | 'arrival',
  newTime: string,
  addedDays?: number,
): { newIsoDepartureTime: string; newIsoArrivalTime: string; isoDuration: string } => {
  const date = editedFlight[`${type}IsoDate`]?.split('T');
  const timezone = editedFlight[`${type}Timezone`];
  const newIsoTime = moment.tz(`${date[0]}T${newTime}`, timezone || 'Europe/Paris').toISOString();
  const newDeparture = type === 'departure' ? newIsoTime : editedFlight.departureIsoDate;
  const newArrival = moment(type === 'arrival' ? newIsoTime : editedFlight.arrivalIsoDate)
    .add(addedDays, 'd')
    .toISOString();

  const isoDuration = moment.duration(moment(newArrival).diff(moment(newDeparture))).toISOString();

  if (isoDuration.includes('-')) {
    return getIsoDuration(editedFlight, type, newTime, 1);
  }
  return { newIsoDepartureTime: newDeparture, newIsoArrivalTime: newArrival, isoDuration };
};
