import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { hasOriginalData } from '../utils';
import { DesignContext } from '../core/Design';
import './field-container.scss';

function ensureWhitespace(text) {
  if (!text || typeof text !== 'string') return text;
  return text.replace(/ \?/g, '\xa0?');
}

const FieldContainer = ({
  label,
  labelStyleClass,
  disabled,
  locked,
  required,
  active,
  className,
  name,
  vertical,
  type,
  children,
  hidden,
  style,
  tooltip,
  description,
  isRight,
  isUploader,
  as,
  mode,
  template,
}) => {
  const Component = isUploader ? 'div' : as;
  const design = useContext(DesignContext);
  const readOnly = disabled || (locked && name && type !== 'MultiStringField' && hasOriginalData(name));
  const componentTemplate = template || get(design, ['defaultTemplates', type]);

  return (
    <>
      <Component
        className={cx(
          `af-field-container af-field-container--${type}`,
          className,
          componentTemplate && `af-field-container--${type}--${componentTemplate}`,
          {
            'is-active': active,
            'is-vertical': vertical,
            'is-required': required,
            'is-disabled': readOnly,
          },
        )}
        data-tooltip={tooltip}
        style={{
          display: hidden ? 'none' : undefined,
          ...style,
        }}
      >
        {((!isRight && label) || mode === 'summary') && (
          <div className={`af-label ${labelStyleClass}`} style={{ ...style }}>
            {ensureWhitespace(label)}
          </div>
        )}
        {description && vertical && <div className="af-description">{description}</div>}
        <div className="af-field">{children}</div>
        {isRight && label && mode !== 'summary' && (
          <div className={`af-label ${labelStyleClass}`} style={{ ...style, textAlign: 'left', marginLeft: 30 }}>
            {ensureWhitespace(label)}
          </div>
        )}
      </Component>
    </>
  );
};

FieldContainer.defaultProps = {
  active: false,
  as: 'label',
  children: undefined,
  className: undefined,
  description: undefined,
  disabled: false,
  hidden: false,
  isRight: false,
  isUploader: false,
  label: undefined,
  labelStyleClass: '',
  locked: false,
  mode: undefined,
  name: undefined,
  required: false,
  style: {},
  template: undefined,
  tooltip: undefined,
  vertical: false,
};

FieldContainer.propTypes = {
  active: PropTypes.bool,
  as: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  isRight: PropTypes.bool,
  isUploader: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  labelStyleClass: PropTypes.string,
  locked: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  template: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
};

export default FieldContainer;
