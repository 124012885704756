import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './translations';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json'

const { errorTracking } = window.__DATA__.options ?? {};
if (errorTracking?.enabled && process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'dev') {
  datadogRum.init({
    applicationId: 'd037b385-f184-40ca-b473-f1b3bba6d75f',
    clientToken: 'pub09bc92aabf75c292961c982b307452bb',
    site: 'datadoghq.eu',
    service: 'registration-forms',
    env: 'production',
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    ...errorTracking.options
  });
}


ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
