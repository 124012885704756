import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import BlockingInput from '../components/BlockingInput';
import FieldContainer from '../components/FieldContainer';
import { ensureConstraint, isAllConstraintsValid, validateConstraints } from '../utils/passwordUtils';

const defaultStrings = {
  passwordConfirm: 'Confirmer le mot de passe',
  notSameError: 'Les 2 mots de passes doivent être identiques',
};

const { status: userStatus } = window.__DATA__?.data || {};

const ValidationMark = ({ valid }) => {
  if (!valid) return <span style={{ color: 'red', width: 20 }}>&#10008;</span>;
  return <span style={{ color: 'green', width: 20 }}>&#10004;</span>;
};

class PasswordField extends PureComponent {
  static defaultProps = {
    // inputType: "text",
    value: '',
    required: false,
  };

  state = {
    confirm: '',
  };

  getStrings() {
    return { ...defaultStrings, ...this.props.strings };
  }

  isValid = (validatedConstraints) => {
    const { value, passwordType } = this.props;
    switch (passwordType) {
      case 'complex': {
        return isAllConstraintsValid(validatedConstraints);
      }
      case 'simple':
      default: {
        const { confirm } = this.state;
        return (confirm || '') === (value || '');
      }
    }
  };

  getValidatedConstraints = () => {
    const { value, passwordType } = this.props;
    const { confirm } = this.state;
    const constraints = ensureConstraint(passwordType);
    return validateConstraints(value, confirm, constraints);
  };

  render() {
    const { id, mode, name, summary, value, data, disabled, placeholder, label, onChange, passwordType = 'simple' } = this.props;
    const { confirm } = this.state;
    const { t } = this.props;
    if (disabled && !value) return null; // Hide disabled field
    const validatedConstraints = this.getValidatedConstraints();
    const { passwordConfirm, notSameError } = this.getStrings();
    // Never required on update :-)
    const required = this.props.required && userStatus !== 'registered';
    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return (
        <FieldContainer {...this.props} required={false} label={summary?.label || label}>
          <div className="af-string-field-summary">
            <span className="value">Votre mot de passe</span>
          </div>
        </FieldContainer>
      );
    }
    return (
      <React.Fragment>
        <FieldContainer {...this.props} required={required}>
          <input
            id={id}
            className="af-password-field"
            name={name}
            type="password"
            placeholder={placeholder || label}
            value={value || ''}
            required={required}
            onChange={(e) => onChange(name, e.target.value)}
          />
        </FieldContainer>
        <FieldContainer {...this.props} label=" " required={false}>
          <input
            id={id + 'Confirm'}
            className="af-password-field"
            name={name + 'Confirm'}
            type="password"
            placeholder={passwordConfirm}
            value={confirm}
            required={required}
            onChange={(e) => this.setState({ confirm: e.target.value })}
          />
          {!this.isValid() && passwordType === 'simple' && <div className="af-message af-message--error">{notSameError}</div>}

          {passwordType === 'complex' && (
            <div style={{ marginBottom: 20, textAlign: 'justify' }}>
              {validatedConstraints.map(({ isValid, min, type }) => (
                <div key={type} style={{ display: 'flex' }}>
                  <ValidationMark valid={isValid} />
                  {t(type, { min })}
                </div>
              ))}
            </div>
          )}
          {!this.isValid(validatedConstraints) && <BlockingInput name={name} />}
        </FieldContainer>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PasswordField);
