import React, { PureComponent } from 'react';
import TextareaAutoSize from 'react-autosize-textarea';
import FieldContainer from '../components/FieldContainer';
import { hasOriginalData } from '../utils';

const style = { resize: 'none' };

export default class TextareaField extends PureComponent {
  render() {
    const { id, label, mode, name, placeholder, onChange, required = false, locked, summary, value = '', disabled } = this.props;

    if (disabled && !value) return null; // Hide disabled field

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      // Auto-hide if empty
      if (value === undefined || value === null || value === '') {
        // TODO: handle `summary.messageIfEmpty` if required
        return null;
      }
      return (
        <FieldContainer {...this.props} required={false} label={summary?.label || label}>
          <div className="af-string-field-summary">
            <span className="value">{value}</span>
          </div>
        </FieldContainer>
      );
    }
    return (
      <FieldContainer {...this.props}>
        <TextareaAutoSize
          id={id}
          rows={1}
          name={name}
          placeholder={placeholder || label}
          value={value}
          required={required}
          readOnly={disabled || (locked && hasOriginalData(name))}
          className="af-textarea-field"
          onChange={(e) => onChange(name, e.target.value)}
          style={style}
        />
      </FieldContainer>
    );
  }
}
