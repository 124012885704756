import orderBy from 'lodash/orderBy';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import englishCountries from 'react-select-country-list';
import FieldContainer from '../components/FieldContainer';
import Select from '../components/FixedRequiredSelect';
import { isFieldLocked } from '../components/utils';
import frenchCountries from '../data/countries-fr-with-country-code.json';
import { removeDiacritics } from '../utils/stringUtils';
import './CountrySelectField.scss';
import { StringField } from './index';

function getCountriesList(lang) {
  if (lang === 'en')
    return englishCountries()
      .getData()
      .map(({ label }) => ({ label, value: label }));
  return orderBy(
    frenchCountries.map(({ label }) => ({ label, value: label })),
    (opt) => removeDiacritics(opt.label).toLowerCase(),
  );
}
const memoizedCountriesList = memoizeOne(getCountriesList);

function valueOf(v) {
  if (!v) return '';
  if (typeof v === 'object') return v.value;
  return v;
}

export default class CountrySelector extends PureComponent {
  handleChange = (name, value) => {
    const { onChange } = this.props;
    onChange(name, valueOf(value));
  };

  expandValue = (value) => {
    const { lang } = this.props;
    if (!value) return value;
    const options = memoizedCountriesList(lang);
    return options.find((o) => o.value === valueOf(value));
  };

  render() {
    const { label, lang, mode, name, value, placeholder, required, summary, disabled, locked } = this.props;
    const isLocked = isFieldLocked(name, locked);
    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return <StringField label={summary?.label || label} value={value} mode="summary" />;
    }

    return (
      <FieldContainer {...this.props}>
        <div className="af-country-select-container">
          <Select
            required={required}
            value={this.expandValue(value)}
            placeholder={placeholder || label}
            onChange={(data) => this.handleChange(name, data)}
            options={memoizedCountriesList(lang)}
            isDisabled={disabled || isLocked}
          />
          {value && <input key={value} name={name} value={valueOf(value)} readOnly style={{ display: 'none' }} />}
        </div>
      </FieldContainer>
    );
  }
}

CountrySelector.defaultProps = {
  lang: 'fr',
  label: '',
  placeholder: '',
  required: false,
  value: undefined,
};

CountrySelector.propTypes = {
  label: PropTypes.string,
  lang: PropTypes.oneOf(['fr', 'en']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};
