import React, { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import Images, { cloudimg, extractFormat } from '../utils/Images';

function double(n: number | string | undefined) {
  if (typeof n === 'number') return n * 2;
  return n;
}

type CdnImageProps = {
  cdnOptions?: any;
  imageType?: 'auto' | 'original';
  func?: 'bound' | 'crop' | 'fit';
  maxHeight?: number;
  maxWidth?: number;
  src: { uri: string } | string | undefined;
  srcSet?: boolean | string;
} & Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'srcSet'>;

const CdnImage = ({
  src,
  srcSet,
  maxWidth, // Doesn't work with Unsplash Picker
  maxHeight, // Doesn't work with Unsplash Picker
  func,
  imageType,
  cdnOptions,
  ...props
}: CdnImageProps): JSX.Element | null => {
  if (!maxWidth && !maxHeight) {
    // Turn into CDN...
    const url = Images.cdn(src);
    if (!url) return null;

    return <img src={url} {...props} />;
  }

  const imageSrc = cloudimg(src, {
    w: maxWidth,
    h: maxHeight,
    func,
    org_if_sml: 1,
    force_format: extractFormat(imageType),
    ...cdnOptions,
  });
  if (!imageSrc) return null;

  let imageSrcSet = srcSet || undefined;
  if (imageSrcSet === true) {
    if (maxWidth || maxHeight) {
      imageSrcSet = `${cloudimg(src, {
        w: double(maxWidth),
        h: double(maxHeight),
        func,
        org_if_sml: 1,
        force_format: extractFormat(imageType),
        ...cdnOptions,
      })} 2x`;
    } else {
      imageSrcSet = '';
    }
  }

  return <img src={imageSrc} srcSet={imageSrcSet} {...props} />;
};

CdnImage.defaultProps = {
  cdnOptions: undefined,
  imageType: 'auto',
  func: 'bound',
  maxHeight: undefined,
  maxWidth: undefined,
};

export const SquareImage: FC<Omit<CdnImageProps, 'func' | 'maxWidth' | 'maxHeight'> & { size: number }> = ({ size, src, ...props }) => {
  return <CdnImage func="crop" {...props} src={src} maxWidth={size} maxHeight={size} />;
};

export default CdnImage;
