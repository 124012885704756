import React, { PureComponent } from 'react';
import i18n from '../translations';

const defaultLabel = 'Vous pouvez vous inscrire à {count} activités';

async function post(url, data) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await res.json();
}

class Quota extends PureComponent {
  render() {
    const { roomLeft, quota, strings = {} } = this.props;
    const { complete = i18n.t('quota.full'), remainingPlaces = i18n.t('quota.remaining-places') } = strings;
    if (!quota) return null;
    return (
      <span className="checkbox--room-left">
        ({!roomLeft && <span style={{ color: 'red' }}>{complete}</span>}
        {!!roomLeft && (
          <span>
            {remainingPlaces}: {roomLeft || 0} / {quota}
          </span>
        )}
        )
      </span>
    );
  }
}

const defaultFields = [
  { label: 'Nom', key: 'lastName' },
  { label: 'Prénom', key: 'firstName' },
  { label: 'Mail', key: 'email' },
];

function checkFields(state, fields) {
  for (const field of fields) {
    if (!state[field.key]) return false;
  }
  return true;
}

export default class ActivityRegistration extends PureComponent {
  state = {
    data: this.props.data,
    workshops: this.props.workshops,
    openEntry: null,
    state: 'select',
    user: {},
    dayToShow: null,
  };

  toggleOpen = (key) => {
    const { openEntry } = this.state;
    this.setState({ openEntry: key === openEntry ? null : key });
  };

  computeCount = () => {
    const { data, workshops } = this.state;
    let count = 0;
    for (const w of workshops) {
      if (w.slots) {
        for (const s of w.slots) {
          if (data[s.id || s.key]) count++;
        }
      } else {
        if (data[w.id || w.key]) count++;
      }
    }
    return count;
  };

  handleSubmit = async (e, fields) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ state: 'register' });

    const { openEntry, workshops, user = {} } = this.state;
    const { url } = this.props;

    const id = openEntry;
    const workshop = workshops.find((w) => w.key === id);
    const data = {
      workshopId: id,
      workshopName: workshop && workshop.name,
      [id]: true,
    };

    for (const field of fields) {
      data[field.key] = user[field.key]; // TODO : move data to sub-object
    }

    try {
      const res = await post(url, data);
      if (res.success) {
        const { fields, updateFields } = ActivityRegistration;
        const header = fields[0].type === 'Image' ? fields[0] : undefined;
        const footer = fields[fields.length - 1].type === 'Image' ? fields[fields.length - 1] : undefined;
        updateFields(
          [
            header,
            {
              type: 'TextBlock',
              text: `Ton inscription est presque terminée ! Pour la finaliser, clique sur le lien qui t’a été envoyé par e-mail.


adidas running`,
            },
            footer,
          ].filter((v) => v),
        );
        // this.setState({ state: "registered", workshopName: res.workshopName });
      } else {
        // Update counts
        const { workshops } = res;
        this.setState({
          workshops,
          state: 'select',
        });
      }
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  handleInputChange = (e) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [e.target.name]: e.target.value,
      },
    });
  };

  computeCurrentTags = () => {
    const { data, workshops } = this.state;
    const tags = {};
    function addTags(newTags) {
      if (newTags) {
        for (const t of newTags) tags[t] = true;
      }
    }
    for (const w of workshops) {
      if (w.slots) {
        for (const s of w.slots) {
          if (data[s.id || s.key]) {
            addTags(s.tags);
          }
        }
      } else {
        if (data[w.id || w.key]) {
          addTags(w.tags);
        }
      }
    }
    return tags;
  };

  render() {
    const { label = defaultLabel, style, count, strings = {}, fields = defaultFields } = this.props;
    const { data, workshops, openEntry, user, state, dayToShow } = this.state;
    // console.log("toggle url", url)

    let currentDay = null;

    return (
      <div className="af-field-container--ActivityRegistration" style={{ ...style }}>
        {/* <div>{label.replace("{count}", count)}</div> */}

        {workshops.map((workshop) => {
          if (workshop.type === 'Header') {
            currentDay = workshop.label;
            if (workshop.image) {
              const isOpen = dayToShow === workshop.label;
              return (
                <div className="af-activity-header">
                  <i className={`af-show-mobile fa fa-chevron-${isOpen ? 'down' : 'right'}`} />
                  <img src={workshop.image} alt="header" onClick={() => this.setState({ dayToShow: isOpen ? null : workshop.label })} />
                </div>
              );
            }
            return <h3 style={{ marginTop: 8, marginBottom: 8, ...workshop.style }}>{workshop.label}</h3>;
          }
          if (workshop.type === 'Text') {
            return <p style={workshop.style}>{workshop.text}</p>;
          }
          const isOpen = openEntry === workshop.key;
          return (
            <div key={workshop.key} className={'af-activity-entry ' + (currentDay !== dayToShow ? 'af-hide-mobile' : '')}>
              <div className="af-activity-entry__title" onClick={() => this.toggleOpen(workshop.key)}>
                <i className={`fa fa-chevron-${isOpen ? 'down' : 'right'}`} />
                {workshop.name} <Quota roomLeft={workshop.roomLeft} quota={workshop.quota} />
              </div>
              {isOpen && (
                <div>
                  {workshop.description && (
                    <div className="af-activity-entry__description">
                      {workshop.description.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  )}
                  {!!workshop.roomLeft ? (
                    <div className="af-activity-entry__form">
                      <div className="af-columns" style={{ width: '90%' }}>
                        {[...fields, ...(workshop.extraFields || [])].map((field) => (
                          <div className="af-column">
                            <input
                              className="af-activity-entry__input"
                              value={user[field.key] || ''}
                              name={field.key}
                              onChange={this.handleInputChange}
                              placeholder={field.label.toUpperCase()}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="af-activity-entry__button-container">
                        <button
                          type="button"
                          disabled={state !== 'select' || !checkFields(user, [...fields, ...(workshop.extraFields || [])])}
                          onClick={(e) => this.handleSubmit(e, [...fields, ...(workshop.extraFields || [])])}
                        >
                          Je m'inscris
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>Déjà complet</div>
                  )}
                </div>
              )}
            </div>
          );
        })}
        {/* <div style={{ paddingTop: 8 }}>
          {selectedCount} : {registeredCount} / {count}
        </div> */}
      </div>
    );
  }
}
