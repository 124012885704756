import React, { FC, useMemo } from 'react';
import { checkFlightNumberFormat, divideFlightNumber } from '../../utils/flightUtils';
import StringField from '../../../StringField';
import DateField from '../../../DateField';
import { useTranslation } from 'react-i18next';

interface FlightSearchInputsProps {
  flightNumber: string;
  departureTime: string;
  required?: boolean;
  onChange: (name: string, value: string) => void;
}

export const FlightSearchInputs: FC<FlightSearchInputsProps> = ({ flightNumber, departureTime, required, onChange }) => {
  const { t } = useTranslation();

  const displayedFlightNumber = useMemo(() => divideFlightNumber(flightNumber)?.join(' ').trim(), [flightNumber]);

  const flightNumberIsCorrect = useMemo(() => (flightNumber ? checkFlightNumberFormat(flightNumber) : true), [flightNumber]);
  return (
    <>
      <StringField
        onChange={onChange}
        label={t('flight.flight-number')}
        name="flightNumber"
        value={displayedFlightNumber}
        placeholder="AZ156"
        incorrect={!flightNumberIsCorrect}
        hint={t('flight.wrong-number-format')}
        required={required}
      />
      <DateField onChange={onChange} label={t('flight.departure-date')} value={departureTime} name="departureTime" required={required} fromToday />
    </>
  );
};
