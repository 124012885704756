import { sendJson } from '../utils/fetch';

const { submitUrl } = window.__DATA__;
const currentUrl = window.location.href.split('?')[0];
const host = submitUrl || currentUrl;

class TicketingService {
  send(method, path, data = {}) {
    return sendJson(method, `${host}/ticketing${path}`, data);
  }

  startTicketingSession({ userCount, workshopsRegistrations }) {
    return this.send('POST', '/', { userCount, workshopsRegistrations });
  }

  cancelTicketingSession(sessionId) {
    return this.send('DELETE', `/${sessionId}`);
  }
}

export default new TicketingService();
