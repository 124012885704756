import moment from "moment-timezone";

type TimeLeft = { minutes: number; seconds: number };

export function getTimeLeft(now: number, date: Date | string): null | TimeLeft {
  const then = new Date(date).getTime();
  if (then < now) {
    return null;
  }
  const secondsLeft = Math.floor((then - now) / 1000);
  const seconds = secondsLeft % 60;
  const minutes = Math.floor(secondsLeft / 60) % 60;

  return { minutes, seconds };
}

export const formatDate = (date: any, format: string) => {
  return moment(date, [moment.ISO_8601, 'YYYY-MM-DD HH:mm:ss Z']).format(format);
}

