import React from "react";

export default class Link extends React.PureComponent {
  render() {
    const { text, url, target = "_self" } = this.props;
    return (
      <div className="af-container" {...this.props}>
        <a href={url} className="af-form-button" target={target}>
          {text}
        </a>
      </div>
    );
  }
}
