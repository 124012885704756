import React, { FC } from 'react';
import TextBlock from '../TextBlock';
import './SummaryBlock.scss';
import { ButtonPosition, UpdateMode, UpdateModeOptions } from './summary.types';
import { useTranslation } from 'react-i18next';

type PostSummaryBlockProps = {
  data: any;
  options?: UpdateModeOptions;
};

const PostSummaryBlock: FC<PostSummaryBlockProps> = ({ data, options }) => {
  const { t } = useTranslation();
  const { mode, postSummaryHtml, button } = options || {};

  const hasButton = mode === UpdateMode.SummaryWithUpdate && button?.position !== ButtonPosition.TOP;
  if ((!postSummaryHtml && !hasButton) || mode === UpdateMode.UpdateButtonWithoutSummary) return null;

  return (
    <div className="af-summary af-summary__post-block">
      {!!postSummaryHtml && <TextBlock html={postSummaryHtml} data={data} />}
      {hasButton && (
        <button type="submit" className="af-form-button af-form-button--update">
          {button?.text || t('btn.update-registration')}
        </button>
      )}
    </div>
  );
};
export default PostSummaryBlock;
