import React from 'react';
import cx from 'classnames';
import './Steps.scss';
import findIndex from 'lodash/findIndex';
import { getCurrentTabs } from '../fields/Tabs/Tabs';
import { FormMode } from '../utils/formUtils';

function isActiveStep(step, { activeStep, activeTab }) {
  if (activeStep !== step.value) return false;
  if (step.value === 'form' && 'tabIndex' in step) {
    return step.tabIndex === (activeTab || 0);
  }
  return true;
}

const Steps = ({ steps, activeStep, activeTab, tabsField, data, onGoToStep }) => {
  if (!steps?.length) return null;

  const activeIndex = findIndex(steps, (step) => {
    return isActiveStep(step, { activeStep, activeTab });
  });

  const currentTabs = getCurrentTabs({ tabs: tabsField.tabs, autoHideEmptyTabs: true, data });

  return (
    <ul className={cx('Steps', steps.length >= 4 && 'Steps--size-small')}>
      {steps.map(({ value, label, tabIndex }, index) => {
        const firstNotFinishedTab = currentTabs.findIndex((t) => !t.isFinished);
        const isDisabled =
          (index > firstNotFinishedTab && firstNotFinishedTab !== -1) ||
          (activeStep !== FormMode.CONFIRMATION && index === steps.length - 1) ||
          activeStep === FormMode.CONFIRMATION;
        return (
          <li
            key={`${value}-${tabIndex}`}
            className={cx('Step', index === activeIndex && 'is-active', index < activeIndex && 'is-before', `Step--value-${value}`, {
              'is-disabled': isDisabled,
            })}
            style={{ zIndex: steps.length + 1 - index }}
            onClick={(e) => (!isDisabled ? onGoToStep(e, index) : undefined)}
          >
            <div className="Step__label">{label}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default Steps;
