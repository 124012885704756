import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "rc-input-number/assets/index.css";
import InputNumber from "rc-input-number";
import every from "lodash/every";
import sumBy from "lodash/sumBy";
import BlockingInput from "../components/BlockingInput";
import { replaceValues } from "../components/utils";
import { getNumericValue, formatValue } from "../utils/numericUtils";

import FieldContainer from "../components/FieldContainer";
import "./table-numeric-field.scss";

const defaultStrings = {
  maxTotalExceeded: "Le total de chaque ligne doit être égal à {totalPerRow}",
  totalText: "Total = ",
};

const Icon = ({ className, name, ...props }) => <i className={`fa fa-fw fa-${name} ${className || ""}`} {...props} />;

function computeRowTotal(field, row, data) {
  const { columns } = field;
  return sumBy(columns, ({ name: colName }) => getNumericValue(field, data, `${field.name}.${row.name}.${colName}`));
}

export default class TableNumericField extends PureComponent {
  static validate = (field, data) => {
    const { totalPerRow } = field;
    if (!totalPerRow) return true;

    return every(field.rows, (row) => computeRowTotal(field, row, data) === totalPerRow);
  };

  isValidTotal = () => {
    const { data } = this.props;
    return TableNumericField.validate(this.props, data);
  };

  getValue = (name) => {
    const { data } = this.props;
    return getNumericValue(this.props, data, name);
  };

  getStrings() {
    return { ...defaultStrings, ...this.props.strings };
  }

  render() {
    const {
      disabled,
      formatter,
      name,
      rows,
      columns,
      max,
      min,
      onChange,
      precision,
      step,
      vertical,
      totalPerRow,
      data,
      required,
    } = this.props;
    const { maxTotalExceeded, totalText } = this.getStrings();

    return (
      <FieldContainer {...this.props} as="div">
        <div className="af-table-numeric-container" style={{ display: vertical ? "block" : "flex" }}>
          <table className={totalPerRow ? "table--has-total" : undefined}>
            <thead>
              <tr key={name}>
                <td className="table--label"></td>
                {columns.map((col) => (
                  <td key={col.name} className="table--input">
                    {col.label}
                  </td>
                ))}
                {totalPerRow && <td className="table--total">Total</td>}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => {
                const { name: rowName, label } = row;
                const total = computeRowTotal(this.props, row, data);
                const isValidTotal = !totalPerRow || total === totalPerRow;
                return (
                  <tr key={rowName}>
                    <td className="table--label">{label}</td>
                    {columns.map(({ name: colName }) => {
                      const key = `${name}.${rowName}.${colName}`;
                      const value = this.getValue(key);
                      return (
                        <td key={colName} className="table--input">
                          <InputNumber
                            className="af-table-numeric-input"
                            disabled={disabled}
                            formatter={(value) => formatValue(value, formatter)}
                            min={min}
                            max={max}
                            name={`${name}[${rowName}][${colName}]`}
                            onChange={(value) => onChange(key, value)}
                            precision={precision}
                            readOnly={disabled}
                            step={step}
                            value={value}
                          />
                        </td>
                      );
                    })}

                    {totalPerRow && (
                      <td className="table--total">
                        {isValidTotal ? (
                          <Icon name="check" className="icon--success" />
                        ) : (
                          <Icon name="remove" className="icon--error" />
                        )}
                        {formatValue(total, formatter)}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {totalPerRow && !this.isValidTotal() && (
            <>
              {/* Inject an empty input if this field is required and the total isn't 100% */}
              {required && <BlockingInput name={name} />}
              <div className="af-message af-message--error">{replaceValues(maxTotalExceeded, { totalPerRow })}</div>
            </>
          )}
        </div>
      </FieldContainer>
    );
  }
}

TableNumericField.defaultProps = {
  disabled: false,
  fields: [],
  formatter: undefined,
  min: 0,
  max: undefined,
  maxTotal: undefined,
  precision: undefined,
  step: 1,
  vertical: false,
};

TableNumericField.propTypes = {
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, label: PropTypes.string })),
  columns: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, label: PropTypes.string })),
  formatter: PropTypes.string,
  max: PropTypes.number,
  maxTotal: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  precision: PropTypes.number,
  step: PropTypes.number,
  vertical: PropTypes.bool,
};
