import get from 'lodash/get';
import map from 'lodash/map';
import jsep from 'jsep';
import memoizee from 'memoizee';

const types = {
  // supported
  LITERAL: 'Literal',
  UNARY: 'UnaryExpression',
  BINARY: 'BinaryExpression',
  LOGICAL: 'LogicalExpression',
  CONDITIONAL: 'ConditionalExpression', // a ? b : c
  MEMBER: 'MemberExpression',
  IDENTIFIER: 'Identifier',
  THIS: 'ThisExpression', // e.g. 'this.willBeUsed'
  CALL: 'CallExpression', // e.g. whatcha(doing)
  ARRAY: 'ArrayExpression', // e.g. [a, 2, g(h), 'etc']
  COMPOUND: 'Compound', // 'a===2, b===3' <-- multiple comma separated expressions.. returns last
};

function ensureArray(value: any) {
  if (!value) return [];
  if (Array.isArray(value)) return value;
  return [value];
}

// eslint-disable-next-line @typescript-eslint/ban-types
const functions: Record<string, Function> = {
  CONCAT: (a: any, b: any) => [...ensureArray(a), ...ensureArray(b)],
};

// const undefOperator = () => undefined;

const getParameterPath = (node: any, context: any) => {
  const type = node.type;
  //   assert(_.includes(types, type), 'invalid node type');
  //   assert(_.includes([types.MEMBER, types.IDENTIFIER], type), 'Invalid parameter path node type: ', type);
  // the easy case: 'IDENTIFIER's
  if (type === types.IDENTIFIER) {
    return node.name;
  }
  // Otherwise it's a MEMBER expression
  // EXAMPLES:  a[b] (computed)
  //            a.b (not computed)
  const computed = node.computed;
  const object = node.object;
  const property = node.property;
  // object is either 'IDENTIFIER', 'MEMBER', or 'THIS'
  //   assert(_.includes([types.MEMBER, types.IDENTIFIER, types.THIS], object.type), 'Invalid object type');
  //   assert(property, 'Member expression property is missing');

  let objectPath = '';
  if (object.type === types.THIS) {
    objectPath = '';
  } else {
    objectPath = node.name || getParameterPath(object, context);
  }

  if (computed) {
    // if computed -> evaluate anew
    const propertyPath = evaluateExpressionNode(property, context);
    return objectPath + '[' + propertyPath + ']';
  } else {
    // assert(_.includes([types.MEMBER, types.IDENTIFIER], property.type), 'Invalid object type');
    const propertyPath: string = property.name || getParameterPath(property, context);
    return (objectPath ? objectPath + '.' : '') + propertyPath;
  }
};

const evaluateExpressionNode = (node: any, context: any): any => {
  switch (node.type) {
    case types.LITERAL: {
      return node.value;
    }
    case types.THIS: {
      return context;
    }
    case types.COMPOUND: {
      const expressions = map(node.body, (el) => evaluateExpressionNode(el, context));
      return expressions.pop();
    }
    case types.ARRAY: {
      const elements = map(node.elements, (el) => evaluateExpressionNode(el, context));
      return elements;
    }
    case types.UNARY: {
      const value = evaluateExpressionNode(node.argument, context);
      switch (node.operator) {
        case '!':
          return !value;
        case '~':
          return ~value; // bitwise NOT
        case '+':
          return +value; // unary plus
        case '-':
          return -value; // unary negation
        //  case'++':  return ++value; // increment
        //  case'--':  return --value; // decrement
        default: {
          console.warn('Unknown unary operator', node.operator, node);
          return null;
        }
      }
    }
    case types.LOGICAL: // !!! fall-through to BINARY !!! //
    case types.BINARY: {
      const left = evaluateExpressionNode(node.left, context);
      const right = evaluateExpressionNode(node.right, context);
      switch (node.operator) {
        case '===':
          return left === right;
        case '!==':
          return left !== right;
        case '==':
          return left == right;
        case '!=':
          return left != right;
        case '>':
          return left > right;
        case '<':
          return left < right;
        case '>=':
          return left >= right;
        case '<=':
          return left <= right;
        case '+':
          return left + right;
        case '-':
          return left - right;
        case '*':
          return left * right;
        case '/':
          return left / right;
        case '%':
          return left % right; // remainde;
        case '**':
          return left ** right; // exponentiatio;
        case '&':
          return left & right; // bitwise AN;
        case '|':
          return left | right; // bitwise O;
        case '^':
          return left ^ right; // bitwise XO;
        case '<<':
          return left << right; // left shif;
        case '>>':
          return left >> right; // sign-propagating right shif;
        case '>>>':
          return left >>> right; // zero-fill right shif;
        case '||':
          return left || right;
        case '&&':
          return left && right;
        default: {
          console.warn('Unknown binary operator', node.operator, node);
          return null;
        }
      }
    }
    case types.CONDITIONAL: {
      const test = evaluateExpressionNode(node.test, context);
      const consequent = evaluateExpressionNode(node.consequent, context);
      const alternate = evaluateExpressionNode(node.alternate, context);
      return test ? consequent : alternate;
    }
    case types.CALL: {
      //   assert(_.includes([types.MEMBER, types.IDENTIFIER, types.THIS], node.callee.type), 'Invalid function callee type');
      const callee = evaluateExpressionNode(node.callee, context);
      if (!callee) {
        console.warn('Unknown function', callee, node);
        return null;
      }
      const args = map(node.arguments, (arg) => evaluateExpressionNode(arg, context));
      return callee(...args);
    }
    case types.IDENTIFIER: // !!! fall-through to MEMBER !!! //
    case types.MEMBER: {
      const path = getParameterPath(node, context);
      if (functions[path]) return functions[path];
      return get(context, path);
    }
    default:
      return undefined;
  }
};

const memoJsep = memoizee(jsep, { primitive: true, max: 20 });

export const jsepEval = (expression: string, context?: any) => {
  try {
    const tree = memoJsep(expression);
    return evaluateExpressionNode(tree, context);
  } catch (e) {
    console.warn('jsepEval error for', { expression, context }, e);
    return null;
  }
};
