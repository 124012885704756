import React, { FC, ReactNode } from 'react';
import { DateField } from '../../fields';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type EditableFieldProps = {
  edited?: boolean;
  type?: string;
  children: ReactNode | string;
  className?: string;
  onChange: (...args: any) => any;
  value: any;
};

export const EditableField: FC<EditableFieldProps> = (props) => {
  const { edited, type, children, value, onChange, className } = props;
  const { t } = useTranslation();

  const renderField = () => {
    if (!edited) return children;

    switch (type) {
      case 'time': {
        return (
          <DateField
            className={className}
            onChange={(name: string, newValue: string) => onChange(moment(newValue).format('HH:mm'))}
            value={value}
            name="time"
            required
            fromToday
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            dateFormat="HH:mm"
            timeCaption={t('flight.time')}
          />
        );
      }
      case 'string':
      default: {
        return <input className={className} value={value} onChange={(e) => onChange(e.target.value)} />;
      }
    }
  };
  return <>{renderField()}</>;
};
