import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import FileUploader from '../components/FileUploader';
import { HiddenField } from './index';
import isEmpty from 'lodash/isEmpty';
import { isFieldLocked } from '../components/utils';

export default class FileField extends PureComponent {
  static defaultProps = {
    value: '',
    required: false,
  };

  render() {
    const { id, label, mode, name, summary, value, onChange, placeholder, required, disabled, locked, acceptedFiles, privacyMode } = this.props;
    const isLocked = isFieldLocked(name, locked);
    const fileValue = value && typeof value === 'string' ? JSON.parse(value) || {} : value;

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      const summaryLabel = isEmpty(value) ? 'Aucun fichier enregistré' : fileValue.originalFilename;
      return (
        <FieldContainer {...this.props} required={false} label={summary?.label || label}>
          <div className="af-string-field-summary">
            <span className="value">{summaryLabel}</span>
          </div>
        </FieldContainer>
      );
    }

    return (
      <FieldContainer {...this.props} isUploader>
        <FileUploader
          id={id}
          field={name}
          value={fileValue}
          onChange={onChange}
          required={required}
          disabled={disabled || isLocked}
          placeholder={placeholder}
          acceptedFiles={acceptedFiles}
          privacyMode={privacyMode}
        />
        <HiddenField required={required} name={name} readOnly={false} value={value ? JSON.stringify(fileValue) : undefined} />
      </FieldContainer>
    );
  }
}
