import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './Quota.scss';
import { computePercentage } from './quota.utils';
import { bem } from '../../core/bem';

export enum QuotaMode {
  QUOTA_VISIBLE = 'visible',
  QUOTA_HIDDEN = 'hidden',
}

const css = bem('af-quota');

type QuotaProps = {
  roomLeft: number;
  quota: number;
  quotaMode?: QuotaMode;
  strings: Record<string, string>;
  usePercentages?: boolean;
};

const Quota: FC<QuotaProps> = ({ roomLeft, quota, strings = {}, usePercentages, quotaMode = QuotaMode.QUOTA_VISIBLE }) => {
  const { t } = useTranslation();
  const { complete = t('quota.full'), available = t('quota.available'), remainingPlaces = t('quota.remaining-places') } = strings;
  if (!quota) return null;

  const className = css({ [quotaMode]: true, full: roomLeft === 0, available: roomLeft !== 0 }).mix('text--workshops--room-left');
  if (quotaMode === QuotaMode.QUOTA_HIDDEN) {
    return (
      <span className={className}>
        {roomLeft === 0 && <span className="af-quota__label">{complete}</span>}
        {roomLeft !== 0 && <span className="af-quota__label">{available}</span>}
      </span>
    );
  }

  return (
    <span className={className}>
      ({roomLeft === 0 && <span className="af-quota__label">{complete} </span>}
      {remainingPlaces}: {usePercentages ? computePercentage(roomLeft, quota) : `${roomLeft} / ${quota}`})
    </span>
  );
};

export default Quota;
