import range from 'lodash/range';
import moment from 'moment';
import React, { PureComponent } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import FieldContainer from '../components/FieldContainer';
import InputBlocker from '../components/InputBlocker';
import { isFieldLocked } from '../components/utils';
import i18n from '../translations';
import { Error } from './errors';
import { StringField } from './index';

const defaultMonths = [
  i18n.t('dates.month.january'),
  i18n.t('dates.month.february'),
  i18n.t('dates.month.march'),
  i18n.t('dates.month.april'),
  i18n.t('dates.month.may'),
  i18n.t('dates.month.june'),
  i18n.t('dates.month.july'),
  i18n.t('dates.month.august'),
  i18n.t('dates.month.september'),
  i18n.t('dates.month.october'),
  i18n.t('dates.month.november'),
  i18n.t('dates.month.december'),
];

function pad(v) {
  if (v < 10) return '0' + v;
  return v;
}

function parseValue(value, format) {
  // TODO : tests...
  // console.log("parseValue", value, format);
  if (!value) return {};
  const date = moment(value, [format, 'YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD']);
  if (date) {
    return {
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
    };
  }
  return {};
}

class DateSelect extends PureComponent {
  render() {
    const { name, field, options, value, required, onChange, disabled } = this.props;

    return (
      <select
        className="af-dateselect-field"
        name={name + '-' + field}
        required={required}
        onChange={(e) => onChange(field, e.target.value)}
        value={value || ''}
      >
        <option value="" disabled={disabled} />
        {options.map((option) => {
          if (typeof option !== 'object')
            return (
              <option key={option} value={option} disabled={disabled}>
                {option}
              </option>
            );
          const { value, label } = option;
          return (
            <option key={value} value={value} disabled={disabled}>
              {label}
            </option>
          );
        })}
      </select>
    );
  }
}

export default class DateSelectField extends PureComponent {
  static defaultProps = {
    format: 'YYYY-MM-DD',
  };
  state = parseValue(this.props.value, this.props.format);

  pushChange = () => {
    const { name, onChange } = this.props;
    const { day, month, year } = this.state;
    if (day && month && year) {
      const { name, onChange, format } = this.props;
      const date = moment({ years: year, months: month - 1, days: day });
      const isValidDate = date.isValid();
      onChange(name, isValidDate ? date.format(format) : '');
    } else {
      onChange(name, '');
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value }, this.pushChange);
  };

  render() {
    const { label, lang, mode, summary, locked, disabled, format } = this.props;
    const { name, monthsLabels = defaultMonths, minYear = 1900, maxYear = 2040, value, required } = this.props;
    const { day, month, year } = this.state;
    const isValidDate = moment(value, format).isValid();
    const isLocked = isFieldLocked(name, locked);

    if (mode === 'summary') {
      const date = moment({ years: year, months: month - 1, days: day });
      const dateFormat = lang === 'en' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';

      const formatedDate = date.format(dateFormat);
      if (summary?.hidden) {
        return null;
      }
      return <StringField label={summary?.label || label} value={formatedDate} mode="summary" />;
    }

    return (
      <FieldContainer {...this.props}>
        {/* Hidden input to upload value... */}
        <input name={name} value={value || ''} readOnly style={{ display: 'none' }} required={required} />
        <DateSelect
          name={name}
          field="day"
          options={range(1, 32).map((v) => ({ value: v, label: '' + pad(v) }))}
          value={day}
          required={required}
          onChange={this.handleChange}
          disabled={disabled || isLocked}
        />
        <DateSelect
          name={name}
          field="month"
          options={monthsLabels.map((m, index) => ({ value: index + 1, label: m }))}
          required={required}
          value={month}
          onChange={this.handleChange}
          disabled={disabled || isLocked}
        />
        <DateSelect
          name={name}
          field="year"
          options={range(minYear, Number(maxYear) + 1)}
          required={required}
          value={year}
          onChange={this.handleChange}
          disabled={disabled || isLocked}
        />
        {(!isValidDate && !!value) || (day && month && year && value === '') ? (
          <Error message={i18n.t('errors.invalid-date')}>
            <InputBlocker name={name} />
          </Error>
        ) : null}
      </FieldContainer>
    );
  }
}
