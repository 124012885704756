import {
  ImageBlock,
  Separator,
  TextBlock,
  Header,
  WorkshopRegistration,
  WorkshopRegistrationV2,
  ActivityRegistration,
  ListItem,
  Basket,
  AwarenessBlock,
} from './components';
import * as fields from './fields';

export const formFields = {
  Image: ImageBlock,
  Separator,
  TextBlock,
  HtmlBlock: TextBlock,
  Header,
  WorkshopRegistration,
  WorkshopRegistrationV2,
  ActivityRegistration,
  ListItem,
  Basket,
  AwarenessBlock,
  ...fields,
};
