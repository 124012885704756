import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import './basket.css';
import { getCheckedProducts, computeDeposit } from '../utils/payment';
import sumBy from 'lodash/sumBy';
import { replaceValues } from './utils';

export default class Basket extends PureComponent {
  static defaultProps = {
    value: '',
    required: false,
  };

  getFields() {
    // TODO : fetch real fields, add store :/
    return window.__DATA__.fields;
  }

  getCheckedProducts() {
    const { users = [] } = this.props.data;
    return getCheckedProducts(this.getFields(), this.props.data, users.length || 1);
  }

  getUserCount() {
    const { data } = this.props;
    return (data.users && data.users.length) || 1;
  }

  render() {
    const { onlyDeposit, data, ...props } = this.props;

    const { products = [], extras = [] } = this.getCheckedProducts();
    const all = [...products, ...extras];
    // console.log("all", all);
    return (
      <FieldContainer {...props} vertical alignLeft>
        <table className="table--simple" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Article</th>
              <th style={{ width: '15%', textAlign: 'right' }}>Prix</th>
              {onlyDeposit && <th style={{ width: '15%', textAlign: 'right' }}>Acompte</th>}
              {onlyDeposit && <th style={{ width: '15%', textAlign: 'right' }}>A payer</th>}
            </tr>
          </thead>
          <tbody>{all.map(this.renderProductLine)}</tbody>
          <tfoot>
            <tr>
              {!onlyDeposit && (
                <th colSpan={2} style={{ textAlign: 'right' }}>
                  Total&nbsp;&nbsp;{sumBy(all, 'amount')}€
                </th>
              )}
              {onlyDeposit && (
                <th colSpan={4} style={{ textAlign: 'right' }}>
                  Total&nbsp;&nbsp;{sumBy(all, 'depositAmount')}€
                </th>
              )}
            </tr>
          </tfoot>
        </table>
      </FieldContainer>
    );
  }

  renderProductLine = (product, index) => {
    const { onlyDeposit, data } = this.props;

    const { amount, depositAmount } = product;
    const { unit, deposit = 100 } = product.price || {};
    console.log('render', product);
    return (
      <React.Fragment key={index}>
        <tr>
          <td>
            {product.header ? `${product.header} ` : ''}
            {replaceValues(product.summaryLabel || product.label, data)}
          </td>
          <td style={{ textAlign: 'right' }}>
            {amount}
            {unit || '€'}
          </td>
          {onlyDeposit && <td style={{ textAlign: 'right' }}>{deposit}%</td>}
          {onlyDeposit && (
            <td style={{ textAlign: 'right' }}>
              {depositAmount}
              {unit || '€'}
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  };
}
