import { noop } from 'lodash';
import React, { PureComponent } from 'react';

export default class HiddenField extends PureComponent {
  static defaultProps = {
    inputType: 'text',
    value: '',
    required: false,
    readOnly: true,
  };

  render() {
    const { id, name, inputType, value, forceValue, required, readOnly } = this.props;
    return (
      <input
        id={id}
        className="af-string-field"
        name={name}
        type={inputType}
        value={forceValue || value}
        required={required}
        readOnly={readOnly}
        style={{ display: 'none' }}
        onChange={noop}
      />
    );
  }
}
