import React, { FC } from 'react';
import './ToggleSwitch.scss';

type ToggleSwitchProps = {
  name: string;
  label: string;
  value?: boolean;
  onChange: (name: string, value: boolean) => void;
};

const ToggleSwitch: FC<ToggleSwitchProps> = ({ name, label, value = false, onChange }) => {
  return (
    <label className="af-switch-container">
      <div className="af-switch">
        <input type="checkbox" name={name} checked={value} onChange={(e) => onChange(name, e.target.checked)} />
        <span className="af-switch-slider round"></span>
      </div>
      {label}
    </label>
  );
};

export default ToggleSwitch;
