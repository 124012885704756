export { default as en } from './en.json';
export { default as es } from './es.json';
export { default as fr } from './fr.json';
export { default as de } from './de.json';
export { default as it } from './it.json';
export { default as pt } from './pt.json';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, es, fr, de, it, pt } from './';
import { stringFormatter } from './formatUtils';
import 'intl-pluralrules';

const resources = {
  en: {
    common: en,
    custom: window.__DATA__.strings,
  },
  fr: {
    common: fr,
    custom: window.__DATA__.strings,
  },
  es: {
    common: es,
    custom: window.__DATA__.strings,
  },
  de: {
    common: de,
    custom: window.__DATA__.strings,
  },
  it: {
    common: it,
    custom: window.__DATA__.strings,
  },
  pt: {
    common: pt,
    custom: window.__DATA__.strings,
  },
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['common', 'custom'],
  defaultNS: 'custom',
  fallbackNS: 'common',
  lng: window.__DATA__.lang || 'fr',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    formatSeparator: '|',
    format: stringFormatter,
  },
  //   disabled in prod
  //   debug: true,
});

export default i18n;
