
export async function sendJson(method, url, json) {
  const res = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });
  return res.json();
}
