/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import React from 'react';
import Icon from '../../../components/Icon';

const RoomingButton = ({
  success,
  error,
  children,
  content,
  icon,
  className,
  ...rest
}: {
  success?: boolean;
  error?: boolean;
  content?: string;
  className?: string;
  icon?: string;
  children?: any;
} & Record<string, any>): JSX.Element => {
  return (
    <button
      className={cx('af-form-button', className, error && 'af-form-button--error', success && 'af-form-button--success')}
      type="button"
      {...rest}
    >
      {icon && (
        <>
          <Icon name={icon} />{' '}
        </>
      )}
      {content}
      {children}
    </button>
  );
};
export default RoomingButton;
