import React, { FC } from 'react';
import cx from 'classnames';
import Icon from '../../../../components/Icon';
import { useTranslation } from 'react-i18next';

interface SearchButtonProps {
  onClick: () => any;
  isLoading?: boolean;
  disabled?: boolean;
}
export const SearchButton: FC<SearchButtonProps> = ({ onClick, isLoading, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      style={{ opacity: isLoading ? 0.7 : 1 }}
      className={cx('af-form-button')}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <div className="af-form-button__icon__container">
          <Icon name="refresh" spin />
        </div>
      ) : (
        <span>{t('flight.search')}</span>
      )}
    </button>
  );
};
