import React from 'react';
import cx from 'classnames';

type IconProps = {
  name: string;
  spin?: boolean;
  className?: string;
} & Record<string, any>;

const Icon = ({ className, name, spin, ...props }: IconProps) => <i className={cx(`fa fa-fw fa-${name}`, spin && 'fa-spin', className)} {...props} />;

export default Icon;
