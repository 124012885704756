import React from "react";

const InputBlocker = ({ name, style }) => (
  <input
    name={`${name}Error`}
    value=""
    required
    style={{ width: 1, height: 1, opacity: 0, border: 0, padding: 0, display: "block", ...style }}
  />
);

export default InputBlocker;
