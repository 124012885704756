import React, { PureComponent } from "react";

export default class ModernStringField extends PureComponent {
  render() {
    const { id, label, name, value = "", placeholder, onChange, required } = this.props;
    return (
        <div className="af-material-input">
          <input
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            value={value}
            required={required}
            onChange={e => onChange(name, e.target.value)}
          />
         <label>{label}</label>
        </div>
    );
  }
}
