import escapeRegExp from 'lodash/escapeRegExp';

export function getMailDomain(mail) {
  if (!mail) return null;
  const pos = mail.indexOf('@');
  if (pos === -1) return null;
  return `"${mail
    .slice(pos + 1)
    .trim()
    .toLowerCase()}"`;
}

export function validateEmail(email, domainRegex) {
  // eslint-disable-next-line no-useless-escape
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = re.test(String(email).toLowerCase());
  const isValidDomain = isEmailDomainRegexCompliant(email, domainRegex);
  return { isValid: isValidEmail && isValidDomain, isValidEmail, isValidDomain };
}

export function isEmailDomainRegexCompliant(email, domainRegex) {
  if (!email) return false;
  if (!domainRegex) return true;
  const isMatching = new RegExp(domainRegex, 'i').test(email);
  return isMatching;
}

/** HTML version used for testing
 * doesn't have the case insensitive flag, as it is NOT available;..
 */
export function isEmailDomainHtmlCompliant(email, domainRegex) {
  if (!email) return false;
  if (!domainRegex) return true;
  const isMatching = new RegExp(domainRegex).test(email);
  return isMatching;
}

const isLetter = new RegExp(/[a-zA-Z]/);

export function makeCaseInsensitive(regex) {
  return regex
    .split('')
    .map((letter) => {
      if (isLetter.test(letter)) {
        return `[${letter.toLowerCase()}${letter.toUpperCase()}]`;
      }
      return letter;
    })
    .join('');
}

export function getEmailDomainRegex({ whitelist = [], blacklist = [] }) {
  if (!whitelist.length && !blacklist.length) return;
  return whitelist.length
    ? `^.*@(${whitelist.map((d) => `(${makeCaseInsensitive(escapeRegExp(d))})`).join('|')})$`
    : `^.*@(${blacklist.map((d) => `(?!${makeCaseInsensitive(escapeRegExp(d))})`).join('')}.)*$`;
}
