/* eslint-disable @typescript-eslint/no-explicit-any */
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import memoizeOne from 'memoize-one';
import { extractCurrentFields, extractFields } from '.';
import type { FieldShape, FieldType, FieldValue } from '../fields/Fields.types';
import { evalCondition, extractConditionFields } from './conditions';
import { jsepEval } from './jsepEval';
import { Workshop, WorkshopRegistration } from '../fields/WorkshopRegistrationV2/workshop.types';

export type FormDataShape = {
  // workshopsRegistrations?: unknown;
  [key: string]: FieldValue;
};

const ignoredFieldTypes: FieldType[] = ['MultiNumericField'];

export function isExpression(forceValue: unknown): forceValue is string {
  return typeof forceValue === 'string' && forceValue[0] === '=';
}

export function evalIfNeeded(valueOrExp: unknown, data: unknown) {
  if (isExpression(valueOrExp)) return jsepEval(valueOrExp.slice(1), data);
  return valueOrExp;
}

// TODO: handle TownsField (cpName and townName)
// TODO: handle phoneNumber

/**
 * This code takes the state's complete data and walks through the form to sanitize it.
 * Note : should be available server side to sanitize all data sent (and maybe check types at the same time ?)
 *
 * Current limitations : `walkFieldsAndInjectValues` adds data but never removes it,
 * so if successive iterations should remove data this can fail.
 * In practice this shouldn't be an issue and was already a limitation of the previous
 * version
 */
function walkFieldsAndInjectValues(fields: FieldShape[] | null | undefined, data: FormDataShape, inputData: FormDataShape): boolean {
  if (!fields?.length) return false;

  let hasChanged = false;
  for (const field of fields) {
    if (!evalCondition(field.condition, data)) {
      continue;
    }

    if ('type' in field) {
      if (ignoredFieldTypes.includes(field.type)) {
        // TODO: eventually support field types listed there
        console.warn('Unhandled field type', field.type);
        continue;
      }
    }

    if (field.type === 'TownsField') {
      // TownsField is cp + town
      hasChanged =
        walkFieldsAndInjectValues(
          [
            { type: 'string', name: field.cpName },
            { type: 'string', name: field.townName },
          ],
          data,
          inputData,
        ) || hasChanged;
    } else if (field.type === 'WorkshopRegistrationV2') {
      set(data, 'workshopsRegistrations', get(inputData, 'workshopsRegistrations', []));
    } else if ('name' in field && field.name) {
      const prevValue = get(data, field.name);
      let newValue = field.forceValue || get(inputData, field.name, evalIfNeeded(field.defaultValue, data));
      if (isExpression(field.forceValue)) {
        newValue = jsepEval(field.forceValue.slice(1), data);
      }
      if (!isEqual(newValue, prevValue)) {
        set(data, field.name, newValue);
        hasChanged = true;
      }
    }

    // fallback
    const nestedFields = 'fields' in field ? field.fields : 'tabs' in field ? field.tabs : undefined;
    if (field.type !== 'ListItem') {
      hasChanged = walkFieldsAndInjectValues(nestedFields, data, inputData) || hasChanged;
    }
  }

  return hasChanged;
}

const extractFormConditionFields = (flatFormFields: FieldShape[]): string[] => {
  if (!flatFormFields) return [];

  const conditionFields: string[] = [];
  flatFormFields.forEach((field) => {
    extractConditionFields(field.condition, conditionFields);
    if (field.options?.length) {
      for (const option of field.options) {
        extractConditionFields(option.visibleBy, conditionFields);
      }
    }
  });
  return conditionFields;
};

/**
 * Static data is data that doesn't change depending on the form state
 * Ex : user category when nothing relies on it
 * @param {*} fields
 */
export function extractStaticData(data: FormDataShape, formFields: FieldShape[]) {
  if (!formFields) return {};

  const fieldsByName = keyBy(formFields, 'name');
  const conditionFields = keyBy(extractFormConditionFields(formFields));
  return pickBy(data, (_value, key) => !(key in fieldsByName) && key in conditionFields);
}

function sanitizeUserData(data: any, fields: FieldShape[] | null | undefined, rawData: any) {
  let hasChanged;
  let currIteration = 0;
  const maxIterations = 42;
  do {
    currIteration += 1;
    // TODO: optimization: partition conditionless fields and inject those as initial non-changing data
    hasChanged = walkFieldsAndInjectValues(fields, data, rawData);
  } while (hasChanged && currIteration < maxIterations);
}

export function extractGuestFields(fields: FieldShape[] | null | undefined, data: FormDataShape) {
  return extractCurrentFields(fields, data).filter((f: any) => f.type === 'ListItem');
}

/**
 * Sanitize data to keep only relevant fields
 */
export function sanitizeData(rawData: FormDataShape, fields: FieldShape[] | null | undefined): FormDataShape {
  const staticData = extractStaticData(rawData, extractFields(fields, { addAllFields: true }));

  // Always init with step...
  const data: FormDataShape = { ...cloneDeep(staticData), step: rawData.step };
  // safeguard to prevent freezing the client in a recursive check.
  // reaching this iteration count probably means we have circular
  // condition references within our fields.
  sanitizeUserData(data, fields, rawData);

  // Handle ListItems
  const listItemFields = extractGuestFields(fields, data);
  for (const listItemField of listItemFields) {
    const { name, fields: childFields, userFields, defaultAddValue } = listItemField;
    const defaultKeys = Object.keys(defaultAddValue || {});
    const keysToKeep = ['_id', 'editorKey', 'collection', 'parentId', 'workshopsRegistrations', ...defaultKeys, ...(userFields || [])];
    const items = get(data, name);
    if (Array.isArray(items)) {
      set(
        data,
        name,
        items.map((item) => {
          const itemData = pick(item, keysToKeep);
          sanitizeUserData(itemData, childFields, item);
          return itemData;
        }),
      );
    }
  }

  return data;
}

type WorkshopMap = Record<string, Workshop>;

type FormField = { type: string } & Record<string, any>;

type WorkshopRegistrationField = FormField & {
  workshops: Workshop[];
};

function extractSessionIds(registrationFields: WorkshopRegistrationField[]): Record<string, Workshop> {
  const map: Record<string, Workshop> = {};
  for (const field of registrationFields) {
    if (field.workshops?.length) {
      for (const workshop of field.workshops) {
        map[workshop._id] = workshop;
      }
    }
  }
  return map;
}

const extractFormSessions = memoizeOne((formFields: FormField[]): WorkshopMap => {
  const fields = (extractFields(formFields, { addAllFields: true }) as FormField[]).filter((f) => f.type === 'WorkshopRegistrationV2');
  return extractSessionIds(fields as WorkshopRegistrationField[]);
});

const extractAvailableSessions = memoizeOne((formFields: FormField[], data: unknown, parentData: unknown): WorkshopMap => {
  const fields = (extractCurrentFields(formFields, data, parentData || {}) as FormField[]).filter((f) => f.type === 'WorkshopRegistrationV2');
  return extractSessionIds(fields as WorkshopRegistrationField[]);
});

export function cleanWorkshopRegistrations(workshopRegistrations: WorkshopRegistration[], data: unknown, formFields: any[]): WorkshopRegistration[] {
  if (!workshopRegistrations?.length) return [];

  const allFormSessions = extractFormSessions(formFields);
  const availableSessions = extractAvailableSessions(formFields, data, {});

  return workshopRegistrations.filter((ws) => {
    // Session valid for form, invalid for current data
    if (ws.sessionId in allFormSessions && !(ws.sessionId in availableSessions)) {
      console.log('remove registration', allFormSessions[ws.sessionId]);
      return false;
    }
    return true;
  });
}
