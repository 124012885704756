import InputBlocker from '../../components/InputBlocker';
import React, { FC } from 'react';
import { Error } from './Error';

type ErrorType = {
  message: string;
  key: string;
};

type ErrorsProps = {
  name?: string;
  blocker?: boolean;
  errors?: ErrorType[];
  onlyBlocker?: boolean;
};

const Errors: FC<ErrorsProps> = (props) => {
  const { name, blocker = true, errors, onlyBlocker = false } = props;
  if (!errors?.length && !onlyBlocker) return null;

  return (
    <div>
      {errors?.map((error, index) => (
        <Error key={error.key || index} message={error.message} />
      ))}
      {blocker && !!name && <InputBlocker name={name} style={{ width: '100%' }} />}
    </div>
  );
};

export { Errors };
