/* eslint-disable @typescript-eslint/no-explicit-any */
import partition from 'lodash/partition';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccommodationRoomUserStatus, CandidateUser, RoomingAccommodation, RoomType } from '../rooming.types';
import { Invitation, OnStatusChange } from './Invitation';

const RoomingInvitations = ({
  accommodations,
  invitations,
  usersById,
  onStatusChange,
  mode,
}: {
  accommodations: RoomingAccommodation[];
  invitations: RoomType[];
  usersById: Record<string, CandidateUser>;
  mode: string;
  onStatusChange: OnStatusChange;
}): JSX.Element => {
  const { t } = useTranslation();
  const me = window.__DATA__.data;
  const [mine, others] = partition(invitations, (i) => i.owner?._id === me._id);
  const validRoom = invitations?.find((i) => i.users?.every((u) => u.status === AccommodationRoomUserStatus.ACCEPTED));

  const received = validRoom
    ? others?.map((o) => {
        const isValid = validRoom?._id === o._id;
        if (isValid) return o;
        return { ...o, users: o.users.map((u) => (u.userId !== me._id ? u : { ...u, status: AccommodationRoomUserStatus.REJECTED })) };
      })
    : others;

  return (
    <div>
      {others?.length > 0 && (
        <>
          <h3>{t('rooming.received-demands')}</h3>
          {received.map((room) => (
            <Invitation
              key={room._id}
              mode={mode}
              room={room}
              accommodations={accommodations}
              usersById={usersById}
              onStatusChange={onStatusChange}
            />
          ))}
        </>
      )}
      {mine?.length > 0 && (
        <>
          <h3>{t('rooming.sent-invitations')}</h3>
          {mine.map((room) => (
            <Invitation
              key={room._id}
              mode={mode}
              room={room}
              accommodations={accommodations}
              usersById={usersById}
              onStatusChange={onStatusChange}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default RoomingInvitations;
