export type User = {
  _id: string;
  collection: string;
  firstName: string;
  lastName: string;
};

export type CandidateUser = {
  _id: string;
  collection: string;
  firstName: string;
  lastName: string;
  email: string;
  hasValidRoom: boolean;
};

export type RoomingAccommodationLevel = {
  quota?: number;
  type: string;
  maxUsers: number;
  name: string;
  _id: string;
  remainingRooms?: number;
};

export type RoomingAccommodation = {
  eventId: string;
  _id: string;
  collection: string;
  title: string;
  levels: RoomingAccommodationLevel[];
};

export enum AccommodationRoomStatus {
  PENDING = 'PENDING',
  VALID = 'VALID',
  QUOTA_ERROR = 'QUOTA_ERROR',
  REJECTED = 'REJECTED',
}

export enum AccommodationRoomUserStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  QUOTA_ERROR = 'QUOTA_ERROR',
}

export type Owner = {
  _id: string;
  collection: string;
};

export type RoomUser = {
  _id: string;
  userId: string;
  userCollection: string;
  status: AccommodationRoomUserStatus;
};

export type RoomType = {
  _id: string;
  collection: string;
  accommodationId: string;
  levelId: string;
  owner: Owner;
  status: AccommodationRoomStatus;
  users: RoomUser[];
};
