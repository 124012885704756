import React, { Component } from "react";
import FormFields from "./FormFields";

class Column extends Component {
  render() {
    const { style, ...props } = this.props;
    return (
      <div className="af-column" style={style}>
        <FormFields {...props} />
      </div>
    );
  }
}

class Columns extends Component {
  render() {
    const { columns, data, errors, onChange } = this.props;
    return (
      <div className="af-columns">
        {columns.map((col, index) => {
          return <Column key={index} {...col} data={data} errors={errors} onChange={onChange} />;
        })}
      </div>
    );
  }
}

export { Columns, Column };
