import React, { createContext } from 'react';
import noop from 'lodash/noop';

export type SubmitOptions = {
  partialUpdate?: boolean;
  isLastTab?: boolean;
  step?: number;
};

export const SubmittableContext = createContext({ submit: noop });
export const useSubmittableContext = () => React.useContext(SubmittableContext);
// eslint-disable-next-line react/display-name
export const withSubmittableContext = (Component: any) => (props: any) =>
  <SubmittableContext.Consumer>{(context) => <Component {...props} {...context} />}</SubmittableContext.Consumer>;
