import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import { hasOriginalData } from '../utils';
import { getEmailDomainRegex, getMailDomain, validateEmail } from '../utils/emailUtils';
import i18n from '../translations';
import compact from 'lodash/compact';

export default class Emailfield extends PureComponent {
  static defaultProps = {
    value: '',
    required: false,
  };

  computeErrors = (value) => {
    // eslint-disable-next-line react/prop-types
    const { strings, whitelist, blacklist } = this.props;
    const emailDomain = getMailDomain(value) || '';
    // eslint-disable-next-line react/prop-types
    const { invalidEmail, invalidDomain } = strings || {};

    const { domains: whitelistedDomains } = whitelist || {};
    const { domains: blacklistedDomains } = blacklist || {};
    const domainRegex = getEmailDomainRegex({ whitelist: whitelistedDomains, blacklist: blacklistedDomains });
    const { isValid, isValidEmail, isValidDomain } = validateEmail(value, domainRegex);

    const errors = compact([
      !isValidEmail && (invalidEmail || i18n.t('errors.INVALID_EMAIL')),
      isValidEmail &&
        !isValidDomain &&
        (invalidDomain ? replaceValues(invalidDomain, { emailDomain }) : i18n.t('errors.INVALID_EMAIL_DOMAIN', { emailDomain })),
    ]);
    return { isValid, errors, domainRegex };
  };

  render() {
    const { mode, id, label, placeholder, name, value, onChange, required, disabled, locked } = this.props;
    if (disabled && !value) return null; // Hide disabled field

    const { isValid, errors, domainRegex } = this.computeErrors(value);

    if (mode === 'summary') {
      return <StringSummary {...this.props} value={value} />;
    }

    return (
      <FieldContainer {...this.props}>
        <input
          id={id}
          className="af-email-field"
          name={name}
          type="email"
          placeholder={placeholder || label}
          value={value}
          required={required}
          readOnly={disabled || (locked && hasOriginalData(name))}
          onChange={(e) => onChange(name, e.target.value)}
          pattern={domainRegex}
        />
        {!!value && !isValid && (
          <div className="af-message af-message--error">
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              {errors.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>
        )}
      </FieldContainer>
    );
  }
}
