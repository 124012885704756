import React, { FC } from 'react';
import TextBlock from '../TextBlock';
import './SummaryBlock.scss';
import { ButtonPosition, UpdateMode, UpdateModeOptions } from './summary.types';
import { useTranslation } from 'react-i18next';

type PreSummaryBlockProps = {
  data: any;
  options?: UpdateModeOptions;
};

const PreSummaryBlock: FC<PreSummaryBlockProps> = ({ data, options }) => {
  const { t } = useTranslation();
  const { mode, preSummaryHtml, button } = options || {};

  const hasButton =
    mode === UpdateMode.UpdateButtonWithoutSummary ||
    (mode === UpdateMode.SummaryWithUpdate && (button?.position === ButtonPosition.BOTH || button?.position === ButtonPosition.TOP));
  if (!preSummaryHtml && !hasButton) return null;

  return (
    <div className="af-summary af-summary__pre-block">
      {options?.preSummaryHtml && <TextBlock html={options?.preSummaryHtml} data={data} />}
      {hasButton && (
        <button type="submit" className="af-form-button af-form-button--update">
          {button?.text || t('btn.update-registration')}
        </button>
      )}
    </div>
  );
};
export default PreSummaryBlock;
