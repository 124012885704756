/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSetState } from 'ahooks';
import { keys } from 'lodash';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { ChangeEvent, FC } from 'react';
import FieldContainer from '../../../components/FieldContainer';
import { replaceValues } from '../../../components/utils';
import { Errors } from '../../errors';
import '../AccommodationReservation.scss';
import AccommodationInfo from '../components/AccommodationInfo';
import Stars from '../components/AccommodationStars';
import { useAccommodationErrors } from '../utils';
import flatten from 'lodash/flatten';

type Accommodation = { _id: string; levels: AccommodationLevel[] } & Record<string, any>;
type AccommodationLevel = { _id: string; name: string } & Record<string, any>;

type AccommodationPerDateCheckboxProps = {
  dates: string[];
  type: string;
  accommodations: Accommodation[];
} & Record<string, any>;

type SelectedAccommodation = {
  collection: string;
  accommodationName: string;
  accommodationLevelId: string;
  accommodationId: string;
  startDate: string;
  endDate: string;
};

type AccommodationPerDateState = {
  accommodations: SelectedAccommodation[];
  currentAccommodations: SelectedAccommodation[];
};

const AccommodationPerDate: FC<AccommodationPerDateCheckboxProps> = (props) => {
  const {
    data,
    required,
    accommodations,
    templates,
    onChange,
    name,
    registrationErrors,
    strings,
    dates,
    datesLabels = [],
    mode,
    summary,
   allowMultipleAccommodations = false,
  } = props;
  const { address: addressTemplate = '{address}', website: websiteTemplate = '{website}' } = templates;

  const errors = useAccommodationErrors(registrationErrors, strings);
  const [state, setState] = useSetState<AccommodationPerDateState>({
    accommodations: get(data, [name]) || [],
    currentAccommodations: get(data, [name]) || [],
  });
  const { accommodations: freshAccommodations, currentAccommodations } = state;
  const isSelected = (accommodations: SelectedAccommodation[], accommodation: Accommodation, level: AccommodationLevel, date: string) => {
    return accommodations.some(
      (acc: SelectedAccommodation) => acc.accommodationLevelId === level._id && acc.accommodationId === accommodation._id && acc.startDate === date,
    );
  };

  const onSelectAccommodationDate = (e: ChangeEvent<HTMLInputElement>, accommodation: Accommodation, level: AccommodationLevel, date: string) => {
    const checked = e.target.checked;
    setState((prevState) => {
      let newDates = [...prevState.accommodations];
      if (checked) {
        newDates.push({
          collection: accommodation.collection,
          accommodationName: level.name,
          accommodationLevelId: level._id,
          accommodationId: accommodation._id,
          startDate: date,
          endDate: date,
        });
      } else {
        newDates = newDates.filter(
          (acc) => acc.accommodationLevelId !== level._id || acc.accommodationId !== accommodation._id || acc.startDate !== date,
        );
      }

      onChange(name, newDates);
      return { accommodations: newDates };
    });
  };

  const accommodationKeys = flatten(accommodations.map(({ _id, levels }) => levels.map((level) => `${_id}-${level._id}`)));

  // Extract current user accommodations FOR THIS BLOCK (ie ignore other reservations)
  const currentUserAccommodations = state.accommodations.filter((a) => accommodationKeys.includes(`${a.accommodationId}-${a.accommodationLevelId}`));

  return (
    <FieldContainer {...props}>
      <div className="af-field-container--AccommodationReservation">
        <div className="list">
          {accommodations.map((accommodation: any) => {
            const { _id: accommodationId, title, levels = [], note } = accommodation;
            const address = replaceValues(addressTemplate, accommodation);
            const website = replaceValues(websiteTemplate, accommodation);

            return (
              <div className="accommodation" key={accommodationId}>
                {title && (
                  <h3 className="accommodation__title">
                    {title}
                    <Stars note={note} />
                  </h3>
                )}
                <div className="accommodation__info">
                  <AccommodationInfo icon="link" value={website} />
                  <AccommodationInfo icon="map-marker" value={address} />
                </div>
                <div className="accommodation__levels">
                  {levels.map((level: any) => {
                    const filteredDate = get(dates, level._id, []) as string[];
                    const availableDates = Object.keys(level?.dates).filter(
                      (date) => moment(date).isValid() && (!filteredDate.length || filteredDate.includes(date)),
                    );
                    const sortedDates = orderBy(availableDates);
                    const { _id: levelId, name } = level;

                    const hasOtherReservations = !!currentUserAccommodations.find(
                      (a) => a.accommodationId !== accommodationId || a.accommodationLevelId !== level._id,
                    );
                    const autoDisable = hasOtherReservations && !allowMultipleAccommodations;

                    return (
                      <div key={levelId} className={`accommodation__level accommodation__level--${levelId}`}>
                        <div className={`accommodation__level--title`}>{name}</div>
                        {sortedDates.map((date: string) => {
                          const { quota, userCount = 0 } = level?.dates?.[date] || {};
                          const isAvailable = userCount < quota || isSelected(currentAccommodations, accommodation, level, date);
                          const isChecked = isSelected(freshAccommodations, accommodation, level, date);

                          return (
                            <div key={`${levelId}-${date}`} className={`accommodation__level--date`}>
                              <label style={{ opacity: !isAvailable ? 0.5 : 1 }}>
                                <input
                                  checked={isChecked}
                                  disabled={!isAvailable || autoDisable}
                                  type="checkbox"
                                  name={name}
                                  required={required}
                                  onChange={(e) => onSelectAccommodationDate(e, accommodation, level, date)}
                                />
                                <span className="accommodation__level--date-label">
                                  {get(datesLabels, `${levelId}.${date}`) ?? get(datesLabels, [date]) ?? moment(date).format('YYYY-MM-DD')}
                                </span>
                              </label>
                              {!isAvailable && <div className="full">{get(strings, 'no-more-availability') || 'Plus de disponibilité'}</div>}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Errors errors={errors} />
    </FieldContainer>
  );
};

export { AccommodationPerDate };
