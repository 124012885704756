import React from 'react';
import FieldContainer from '../../components/FieldContainer';
import get from 'lodash/get';
import './ProductsField.scss';
import cx from 'classnames';
import { Errors } from '../errors';
import { computeProductFieldErrors } from './product.utils';

type Product = {
  _id: string;
  name: string;
  productId: string;
  amount?: string;
  currency: string;
  [x: string]: any;
};

type ProductsFieldProps = {
  data: Record<string, any>;
  label?: string;
  products: Product[];
  required?: boolean;
  value?: any;
  onChange: (name: string, products: Product[]) => void;
  type: string;
} & Record<string, any>;

const currencies: Record<string, string> = {
  EUR: '€',
};

const ProductEntry = ({
  amountWithTax,
  className,
  currency,
  checked = false,
  mode,
  name,
  onChange,
  variantId,
}: {
  amountWithTax: string | number;
  className?: string;
  currency: string;
  checked?: boolean;
  onChange?: (target: any) => any;
  mode: string;
  name: string;
  variantId?: string;
}) => {
  return (
    <div key={variantId} className={cx('Products__Product', mode, className)}>
      <label>
        {onChange && <input type="checkbox" name={variantId} checked={checked} onChange={onChange} />}
        <span className="name">{name}</span>
        <span className="amount">
          {(amountWithTax as number) / 100}
          {currencies[currency]} TTC
        </span>
      </label>
      {/* TODO: gérer ce cas coté serveur et update ce code en conséquence*/}
      {/*Ce produit n'est plus disponible*/}
      {/*<Errors errors={errors} />*/}
    </div>
  );
};

const ProductsField = (props: ProductsFieldProps): JSX.Element | null => {
  const { data, products, label, mode, name: fieldName, onChange, style, summary } = props;
  const productBasket = get(data, fieldName, []) as Product[];

  const handleChange = ({ target }: { target: any }) => {
    const { checked = false, name } = target || {};
    const checkedProduct = products.find((p) => p._id === name) as Product;
    if (checked) {
      const newProductBasket = [
        ...productBasket,
        {
          productVariantId: checkedProduct._id,
          productId: checkedProduct.productId,
          collection: checkedProduct.collection,
        },
      ] as Product[];
      onChange(fieldName, newProductBasket);
    } else {
      const newProductBasket = productBasket.filter((p) => p.productVariantId !== checkedProduct._id) as Product[];
      onChange(fieldName, newProductBasket);
    }
  };

  const errors = computeProductFieldErrors(props, productBasket);
  if (mode === 'summary') {
    if (summary?.hidden) {
      return null;
    }
    let total = 0;
    return (
      <FieldContainer {...props} required={false} label={summary?.label || label}>
        <div className="af-field-container--ProductsField" style={style}>
          {products.map((product) => {
            const { _id: variantId, name, amountWithTax, currency } = product;
            const isChecked = productBasket.some((p) => p.productVariantId === variantId);
            if (!isChecked) return null;
            total += amountWithTax;
            return (
              <ProductEntry
                amountWithTax={amountWithTax}
                checked={isChecked}
                currency={currency}
                key={variantId}
                mode={mode}
                name={name}
                variantId={variantId}
              />
            );
          })}
          <ProductEntry amountWithTax={total} currency="EUR" className="total" mode={mode} name="Total" variantId="total" />
        </div>
      </FieldContainer>
    );
  }

  return (
    <FieldContainer {...props} className={!label ? 'af-field-container--ProductsField__unlabeled' : undefined}>
      <Errors name={fieldName} errors={errors} />
      <div className="af-field-container--ProductsField" style={style}>
        <div className="Products">
          {products.map((product) => {
            const { _id: variantId, name, amountWithTax, currency } = product;
            const isChecked = productBasket.some((p) => p.productVariantId === variantId);
            return (
              <ProductEntry
                amountWithTax={amountWithTax}
                checked={isChecked}
                className="Products__Product"
                currency={currency}
                key={variantId}
                mode={mode}
                name={name}
                onChange={handleChange}
                variantId={variantId}
              />
            );
          })}
        </div>
      </div>
    </FieldContainer>
  );
};

ProductsField.defaultProps = {};

export default ProductsField;
