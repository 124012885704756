import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import substract from 'lodash/subtract';
import { hasOriginalData } from '../utils';

export function hasExtension(filename, ext) {
  return filename.slice(filename.length - ext.length).toLowerCase() === ext;
}

export function isImage(filename) {
  if (!filename) return false;
  return hasExtension(filename, '.jpg') || hasExtension(filename, '.jpeg') || hasExtension(filename, '.png') || hasExtension(filename, '.gif');
}

export function isVideo(filename) {
  if (!filename) return false;
  return (
    hasExtension(filename, '.mp4') ||
    hasExtension(filename, '.m4v') ||
    hasExtension(filename, '.mkv') ||
    hasExtension(filename, '.avi') ||
    hasExtension(filename, '.mpeg')
  );
}

export function iconForFilename(filename) {
  if (!filename) return 'file';

  if (hasExtension(filename, '.pdf')) return 'file-pdf-o';
  if (hasExtension(filename, '.doc') || hasExtension(filename, '.docx')) return 'file-word-o';
  if (hasExtension(filename, '.xls') || hasExtension(filename, '.xlsx')) return 'file-excel-o';
  if (hasExtension(filename, '.ppt') || hasExtension(filename, '.pptx')) return 'file-powerpoint-o';
  if (isImage(filename)) return 'file-image-o';
  if (isVideo(filename)) return 'file-video-o';

  return 'file';
}

function roundDecimals(number) {
  return Math.round(number * 100) / 100;
}

export function sizeLabel(size) {
  const kbSize = size / 1024;
  if (kbSize < 900) return roundDecimals(kbSize) + ' ko';
  else return roundDecimals(kbSize / 1024) + ' Mo';
}

const stringOps = {
  firstLetter: (s) => s.charAt(0),
  toLowerCase: (s) => s.toLowerCase(),
  toUpperCase: (s) => s.toUpperCase(),
  sub: (s) => s.toLowerCase(),
};

export function replaceValues(text, data) {
  if (!text) return text;
  if (!data) return text;

  return text.replace(/{(.*?)}/g, function (match, key) {
    // console.log("replace", replace)
    if (key.indexOf('[') !== -1) {
      // Use lodash get
      return get(data, key);
    }
    if (key.indexOf('.') !== -1) {
      const [field, op] = key.split('.');
      if (field in data) {
        if (op.includes('sub(')) {
          const number = Number(op[4]); // sub(x)
          if (Number.isNaN(number)) return data[field];
          return substract(data[field], number);
        }
        const f = stringOps[op];
        if (f) return f(data[field]);
        return data[field];
      }
    }

    if (key in data) {
      return data[key];
    }
    return '';
  });
}

export function replaceObjectValues(obj, data) {
  return mapValues(obj, (v) => {
    if (typeof v === 'string') return replaceValues(v, data);
    return v;
  });
}

export function isFieldLocked(name, locked) {
  return locked && hasOriginalData(name);
}
