import React from "react";
import PropTypes from "prop-types";
import Icon from "../../../components/Icon";

const AccommodationInfo = ({ value, icon }) => {
  if (!value) return null;
  return (
    <>
      {icon && <Icon name={icon} />} {value}
    </>
  );
};

AccommodationInfo.defaultProps = {
  icon: undefined,
  value: undefined,
};

AccommodationInfo.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
};

export default AccommodationInfo;
