export function findOverlappingSession(session, selectedSessions) {
  const { startDate, endDate } = session;
  if (!startDate || !endDate) return false;

  return selectedSessions.find((event) => {
    const { startDate: otherStart, endDate: otherEnd } = event;
    if (event._id === session._id || !otherStart || !otherEnd) return false; // Invalid date

    // TODO: compare strings or use parseISO ?
    return startDate < otherEnd && endDate > otherStart;
  });
}
