import React, { Component, CSSProperties } from 'react';
import { extractFields, isUpdate } from '../../utils';
import FormFields from '../FormFields';
import { SubmitOptions, withSubmittableContext } from '../../context/Submittable';
import { TabButton } from './TabButton';
import i18n from '../../translations';
import './Tab.scss';

const hasRegistration = !!extractFields(window.__DATA__.fields).find((f: any) => f.name === 'registered');
type TabProps = {
  isLast: boolean;
  onSelect: (index: number) => void;
  index: number;
  active: boolean;
  showNextTabOnUpdate?: boolean;
  showPreviousButton?: boolean;
  persistOnTabChange?: boolean;
  submit: (e: any, options: SubmitOptions) => any;
  style?: CSSProperties;
  disableIfUpdate?: boolean;
  strings?: Record<string, any>;
  isFinished?: boolean;
  data: Record<string, any>;
  onChange: (fieldName: string, value: any) => void;
};

class Tab extends Component<TabProps> {
  _isMounted = false;
  state = {
    forceDisabled: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // temporaryLock = () => {
  //   setTimeout(() => {
  //     this.setState({ forceDisabled: true });
  //   }, 100);
  //   setTimeout(() => {
  //     if (this._isMounted) {
  //       this.setState({ forceDisabled: false });
  //     }
  //   }, 1000); // Debounce clicks for 1 second
  // };

  handleClick = async (e: any) => {
    const { isLast, onSelect, data, index, showNextTabOnUpdate, persistOnTabChange, onChange } = this.props;
    const step = data?.step;

    if (!persistOnTabChange && (isLast || (isUpdate() && !showNextTabOnUpdate))) {
      // Force disable to debounce clicks
      // this.temporaryLock();
      return; // Let normal submit...
    }

    e.preventDefault();
    e.stopPropagation();

    if (persistOnTabChange) {
      onChange?.('step', index);
      // Keep step once finished ??
      const res = await this.props.submit(e, { isLastTab: isLast, partialUpdate: true, step: !step || index >= step ? index + 1 : step });
      if (res?.error) {
        return; // No tab change
      }
    }

    if (!isLast) {
      onSelect(index + 1); // Goto next
    }
  };

  render() {
    const { active, style, disableIfUpdate, persistOnTabChange, ...props } = this.props;

    if (persistOnTabChange && !active) {
      return null;
    }

    return (
      <div
        className={`af-tabs__content ${active ? 'af-tabs__content--active' : ''} ${
          disableIfUpdate && window.__DATA__.isUpdate ? 'af-tabs__content--disabled' : ''
        }`}
        style={style}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <FormFields {...props} />
        {this.renderButton()}
      </div>
    );
  }

  getButtonLabel() {
    const { isLast, strings = {}, showNextTabOnUpdate } = this.props;
    const { update = i18n.t('tabs.update'), validate = i18n.t('tabs.validate'), next = i18n.t('tabs.next') } = strings;
    if (isUpdate() && !showNextTabOnUpdate) return update;
    if (isLast) return validate;
    return next;
  }

  handleClickPrevious = async (e: any) => {
    const { onSelect, index, persistOnTabChange, onChange } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (persistOnTabChange) {
      onChange?.('step', index);
      // Keep step once finished ??
      await this.props.submit(e, { isLastTab: false, partialUpdate: true, step: index - 1 });
    }

    if (index !== 0) {
      onSelect(index - 1); // Goto previous
    }
  };

  renderButton() {
    const { isFinished, isLast, showNextTabOnUpdate, persistOnTabChange, strings = {}, data, index, showPreviousButton = true } = this.props;
    const { forceDisabled } = this.state;
    const { previous = i18n.t('tabs.previous') } = strings;
    const isOnlyTab = isLast && !showPreviousButton;
    if (!persistOnTabChange && hasRegistration && !data.registered && isOnlyTab && !showNextTabOnUpdate) return false; // Hardcoded, evil

    const { completeAllFields = i18n.t('errors.fill-all-required-fields') } = strings;
    return (
      <React.Fragment>
        {!isFinished && <div className="af-message af-message--error">{completeAllFields}</div>}
        <div className="tab-actions">
          {index !== 0 && showPreviousButton && (
            <TabButton
              style={{ marginRight: 8 }}
              label={previous}
              onClick={this.handleClickPrevious}
              strings={strings}
              showNextTabOnUpdate={showNextTabOnUpdate}
            />
          )}
          <div className="spacer" />
          <TabButton
            label={this.getButtonLabel()}
            disabled={!isFinished || (isFinished && forceDisabled)}
            onClick={isFinished ? this.handleClick : undefined}
            strings={strings}
            showNextTabOnUpdate={showNextTabOnUpdate}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withSubmittableContext(Tab);
