import React, { Component } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'whatwg-fetch';
import './App.scss';
import Form from './Form';
// import { StripeProvider, Elements } from "react-stripe-elements";
require('iframe-resizer/js/iframeResizer.contentWindow.js');

const queryClient = new QueryClient();

class App extends Component {
  render() {
    // if (window.__DATA__.stripeKey) {
    //   return (
    //     <StripeProvider apiKey={window.__DATA__.stripeKey}>
    //       <div className="af-container">
    //         <Elements>
    //           <Form />
    //         </Elements>
    //       </div>
    //     </StripeProvider>
    //   );
    // }
    return (
      <QueryClientProvider client={queryClient}>
        <div className="af-container">
          <Form />
        </div>
      </QueryClientProvider>
    );
  }
}

export default App;
