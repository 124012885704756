import trim from 'lodash/trim';
import memoize from 'memoizee';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/it';
import { ensureDate } from '../utils/date';

const splitFormats = memoize((formats) => formats.split('|').map((v) => v.trim()));
const parseFunction = memoize((format) => {
  const [name, args] = format.split(/[()]/);
  return { name, args: trim(args, '"\'') };
});

const config = window.__DATA__;

const { lang = 'fr ' } = config;
moment.locale(lang);

// eslint-disable-next-line no-unused-vars
function applyFormat(value, format, _lng) {
  let func = format;
  let parameters;

  // Parse string ?
  if (format.indexOf('(') !== -1) {
    // Found a function
    const { name, args } = parseFunction(format);
    func = name;
    parameters = args;
  }

  switch (func) {
    case 'date':
      if (moment.isMoment(value)) {
        return value.format(parameters).replace('\\n', '\n');
      }
      return moment(ensureDate(value)).format(parameters);
    case 'uppercase':
      return (value || '').toUpperCase();
    default: {
      console.warn(`Unknown func "${func}"`);
      return value;
    }
  }
}

function applyFormats(value, formats, lng) {
  // Chain formatters
  return formats.reduce((prevValue, format) => applyFormat(prevValue, format, lng), value);
}

export function stringFormatter(value, format, _lng) {
  if (format.indexOf('|') !== -1) {
    const formats = splitFormats(format);
    return applyFormats(value, formats, _lng);
  }
  return applyFormat(value, format, _lng);
}
