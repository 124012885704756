import { useEffect } from 'react';
import Swal from 'sweetalert2';

export const useLoadingModal = (isLoading: boolean, onClose?: (...args: any) => any) => {
  useEffect(() => {
    if (isLoading) {
      Swal.fire({ onClose });
      Swal.showLoading();
    } else {
      Swal.close();
    }
  }, [isLoading]);
};
