import { sendJson } from '../utils/fetch';

const apiUrl = window.__DATA__.api.flight;

class FlightService {
  getFlight = async (flightNumber: string, departureTime: string) => {
    return sendJson('POST', apiUrl, { flightNumber, departureTime });
  };
}

export default new FlightService();
