import cx from 'classnames';
import React, { Component } from 'react';

type HeaderProps = {
  activeIndex: number;
  tabs: Array<any>;
  isFinished?: boolean;
  persistOnTabChange?: boolean;
  onSelect: (tab: number) => void;
  data: Record<string, any>;
};

class Header extends Component<HeaderProps> {
  isDisabled = (index: number) => {
    const { tabs, activeIndex, persistOnTabChange } = this.props;

    if (persistOnTabChange && activeIndex < index) {
      return true;
    }

    for (let i = 0; i < index; i++) {
      if (!tabs[i].isFinished) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { tabs, activeIndex, onSelect } = this.props;

    return (
      <div className="af-tabs__header">
        {tabs.map((tab, index) => {
          const disabled = !tab.alwaysOpen && this.isDisabled(index);
          return (
            <div
              key={index}
              className={cx('af-tab', index === activeIndex && 'af-tab--active', disabled && 'af-tab--disabled')}
              onClick={() => !disabled && onSelect(index)}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
    );
  }
}

export { Header };
