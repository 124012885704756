import { createId } from '@appcraft/common-utils';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StringSummary from '../../components/StringSummary';
import ToggleSwitch from '../../components/ToggleSwitch';
import { FieldProps } from '../field.types';
import './CarbonFootprintTransportField.scss';
import { CarbonFootprintTransport, TransportKind, TransportType } from './carbon-footprint.types';
import { TransportFootprint } from './components/TransportFootprint';
import { TextBlock } from '../../components';

type PathStats = { transportTypes: TransportType[]; distanceKm: number; totalCO2Kg: number };

type CarbonFootprintTransportValue = {
  sameReturnPath?: boolean;
  items: CarbonFootprintTransport[];
  totalCO2Kg?: number;
  global?: PathStats;
  departure?: PathStats;
  return?: PathStats;
};

type CarbonFootprintFieldProps = FieldProps<CarbonFootprintTransportValue> & {
  multiple?: boolean; // Single or multi-mode ?
  description?: string;
};

type TransportArrayProps = {
  mode?: 'summary';
  kind: TransportKind;
  items: CarbonFootprintTransport[];
  onChange: (value: CarbonFootprintTransport[]) => void;
};

const TransportArray: FC<TransportArrayProps> = ({ mode, kind, items, onChange }) => {
  const { t } = useTranslation();

  const handleAdd = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const prev = items[items.length - 1];
    // Auto-fill from
    onChange([...items, { _id: createId(), kind, type: 'transport', from: prev?.to }]);
  };

  const handleChange = (index: number, entry: CarbonFootprintTransport) => {
    if (!entry._id) {
      entry._id = createId();
    }
    const newValue = [...items];
    newValue[index] = entry;
    onChange(newValue);
  };

  const handleDelete = (index: number) => {
    const newValue = [...items];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <div>
      {!items.length && <TransportFootprint index={0} mode={mode} value={items[0]} onChange={(v) => handleChange(0, v)} />}
      {!!items.length && (
        <>
          {items.map((v, index) => (
            <TransportFootprint
              key={v._id}
              index={index}
              mode={mode}
              value={v}
              onChange={(v) => handleChange(index, v)}
              onDelete={items.length > 1 ? () => handleDelete(index) : undefined}
            />
          ))}
        </>
      )}
      {mode !== 'summary' && (
        <button disabled={!!!items[0]?.computed} type="button" className="af-form-button" onClick={handleAdd}>
          {t('carbon-footprint.btn.add-trip')}
        </button>
      )}
    </div>
  );
};

function generateReturnPath(path: CarbonFootprintTransport[]): CarbonFootprintTransport[] {
  // Swap from and to...
  return path.map((i) => ({ ...i, _id: `return-${i._id}`, kind: 'return' as TransportKind, from: i.to, to: i.from })).reverse();
}

function extractFromTo(items: CarbonFootprintTransport[]): { from: { description?: string }; to: { description?: string } } {
  return {
    from: { description: items[0]?.from?.description },
    to: { description: items[items.length - 1]?.to?.description },
  };
}

function computePathStats(items: CarbonFootprintTransport[]): PathStats {
  return {
    transportTypes: uniq(items.map((i) => i.transportType).filter((v) => v) as TransportType[]),
    distanceKm: sumBy(items, 'computed.distanceKm'),
    totalCO2Kg: sumBy(items, 'computed.CO2Kg'),
    ...extractFromTo(items),
  };
}

const CarbonFootprintTransportField: FC<CarbonFootprintFieldProps> = (props) => {
  const { t } = useTranslation();
  const { label, description, name, value, onChange, disabled, mode, labelStyleClass = '' } = props;
  if (disabled && !value) return null; // Hide disabled field

  const { items = [], sameReturnPath = true } = value ?? {};

  const [departure, returnPath] = partition(items, (item) => item.kind === 'departure');

  const updateValue = (newValue: CarbonFootprintTransportValue) => {
    const [departure, returnPath] = partition(newValue.items, (item) => item.kind === 'departure');
    onChange(name, {
      ...newValue,
      totalCO2Kg: sumBy(newValue.items, 'computed.CO2Kg'),
      global: computePathStats(newValue.items),
      departure: computePathStats(departure),
      return: computePathStats(returnPath),
    });
  };

  const handleChange = (kind: TransportKind, items: CarbonFootprintTransport[]) => {
    const withKind = items.map((i) => ({ ...i, kind }));
    if (kind === 'departure') {
      if (sameReturnPath) {
        // Duplicate
        updateValue({ sameReturnPath, items: [...withKind, ...generateReturnPath(withKind)] });
      } else {
        updateValue({ sameReturnPath, items: [...withKind, ...returnPath] });
      }
    } else {
      updateValue({ sameReturnPath, items: [...departure, ...withKind] });
    }
  };

  const handleSameReturnChange = (_name: string, value: boolean) => {
    if (value) {
      // Changed, inject departure path
      updateValue({ sameReturnPath: value, items: [...departure, ...generateReturnPath(departure)] });
    } else {
      // No return path yet
      updateValue({ sameReturnPath: value, items: [...departure] });
    }
  };

  return (
    <div className="af-field-container--CarbonFootprintTransportField">
      <h3 className={`af-label ${labelStyleClass}`}>{label}</h3>
      {description && description[0] === '<' ? (
        <TextBlock html={description} className="description" />
      ) : (
        <div className="description">{description}</div>
      )}

      <h4>{t('carbon-footprint.labels.aller')}</h4>
      <TransportArray mode={mode} kind="departure" items={departure} onChange={(items) => handleChange('departure', items)} />
      <h4>{t('carbon-footprint.labels.retour')}</h4>
      {mode !== 'summary' ? (
        <ToggleSwitch
          label={t('carbon-footprint.labels.same-return-path')}
          name="transport.sameReturnPath"
          value={sameReturnPath}
          onChange={handleSameReturnChange}
        />
      ) : (
        <StringSummary type="string" label={t('carbon-footprint.labels.same-return-path')} value={t(`summary.checkbox.${sameReturnPath}`)} />
      )}
      {!sameReturnPath && <TransportArray mode={mode} kind="return" items={returnPath} onChange={(items) => handleChange('return', items)} />}
    </div>
  );
};

export default CarbonFootprintTransportField;
