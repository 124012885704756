import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import querystring from 'querystring';
import { iconForFilename, sizeLabel } from './utils';
import { Error } from '../fields/errors';
import InputBlocker from './InputBlocker';
import i18n from '../translations';

const styles = {
  dropzoneContainer: {
    cursor: 'pointer',
    width: '95%',
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'rgb(102, 102, 102)',
    borderRadius: 5,
    padding: 5,
  },
};

function uploadAssetFile(file, options) {
  var formData = new FormData();
  formData.append('file', file);

  let url = window.__DATA__.api.file;
  if (options) {
    url += '?' + querystring.stringify(options);
  }

  return fetch(url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json());
}

const File = ({ originalFilename, size }) => {
  return (
    <div>
      <i className={`fa fa-${iconForFilename(originalFilename)}`} style={{ marginRight: 5 }} />
      {originalFilename}
      <br />
      {sizeLabel(size)}
    </div>
  );
};

export default class FileUploader extends Component {
  state = {
    uploading: false,
    state: 'adding',
  };

  handleDrop = async (accepted, rejected) => {
    const { privacyMode } = this.props;

    if (accepted.length > 0) {
      this.setState({ state: 'uploading' });
      try {
        const res = await uploadAssetFile(accepted[0], { mode: privacyMode });
        if (res.errors) {
          this.setState({ state: 'error' });
          return;
        }
        const json = { ...res, url: res.uri };

        const { onChange, field } = this.props;
        if (onChange) {
          this.setState({ state: 'added' });
          onChange(field, json);
        }
      } catch (e) {
        this.setState({ state: 'adding' });
        console.log(e);
      }
    } else {
      console.log('No file to upload !');
    }
  };

  handleRemove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onChange, field } = this.props;
    if (onChange) {
      onChange(field, null);
      this.setState({ state: 'adding' });
    }
  };

  render() {
    const { state } = this.state;
    const { value, placeholder = i18n.t('file.upload.drop-file'), acceptedFiles, required, disabled, strings = {} } = this.props;
    const { invalidMsg = i18n.t('file.upload.invalid-file') } = strings;

    if (value && (value.uri || value.id)) {
      return (
        <div style={{ ...styles.dropzoneContainer, cursor: 'normal', borderStyle: disabled ? 'solid' : 'dashed' }}>
          {!disabled && <i className="fa fa-times" style={{ position: 'absolute', top: 0, right: 5, fontSize: 18 }} onClick={this.handleRemove} />}
          <File {...value} />
        </div>
      );
    }
    return (
      <>
        <Dropzone
          style={styles.dropzoneContainer}
          accept={!!acceptedFiles ? acceptedFiles : undefined}
          onDrop={this.handleDrop}
          inputProps={{ required, disabled }}
        >
          {state === 'adding' && <div>{placeholder}</div>}
          {state === 'uploading' && <div>Loading...</div>}
          {state === 'error' && (
            <div>
              <Error message={invalidMsg} />

              <i className="fa fa-times" style={{ position: 'absolute', top: 0, right: 5, fontSize: 18 }} onClick={this.handleRemove} />

              <InputBlocker name="fileUpload" style={{ width: '100%' }} />
            </div>
          )}
        </Dropzone>
      </>
    );
  }
}
