import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import FieldContainer from '../components/FieldContainer';
import SelectField from '../components/FixedRequiredSelect';

const towns = window.towns;

function valueOf(v) {
  if (!v) return '';
  if (typeof v === 'object') return v.value;
  return v;
}

function expandValue(value) {
  if (!value) return value;
  const town = towns.find((o) => o.ville === valueOf(value));
  return (town && { label: town.ville, value: town.ville }) || {};
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const postalCodes = uniq(towns.map((c) => c.cp));
  const suggestions = inputLength === 0 ? [] : postalCodes.filter((t) => t.toLowerCase().slice(0, inputLength) === inputValue);
  return suggestions;
}

export default class TownsField extends PureComponent {
  handleChange = (name, value) => {
    const { onChange } = this.props;
    onChange(name, value);
  };

  handleSelectCP = (e, { newValue }) => {
    const { cpName, townName } = this.props;
    this.handleChange(cpName, newValue);
    this.handleChange(townName, '');
  };

  handleSelectTown = (name, value) => {
    this.handleChange(name, value);
  };

  getSuggestionValue = (suggestion) => suggestion;

  renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  render() {
    const { required, cpLabel, cpName, townLabel, townName, data } = this.props;
    const cpValue = data[cpName] || '';
    const townValue = data[townName] || '';
    const cpSuggestions = getSuggestions(cpValue);
    const townSuggestions = towns.filter((t) => t.cp === cpValue).map((r) => ({ value: r.ville, label: r.ville }));

    const inputProps = {
      placeholder: cpLabel,
      value: cpValue,
      required,
      onChange: this.handleSelectCP,
      autocomplete: 'off',
    };
    return (
      <React.Fragment>
        <FieldContainer {...this.props} label={cpLabel}>
          <Autosuggest
            suggestions={cpSuggestions}
            onSuggestionsFetchRequested={noop}
            onSuggestionsClearRequested={noop}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
        </FieldContainer>
        <FieldContainer {...this.props} label={townLabel}>
          <div className="af-multi-select-container">
            <SelectField
              required={required}
              value={expandValue(townValue)}
              placeholder={townLabel}
              onChange={({ value }) => this.handleSelectTown(townName, value)}
              options={townSuggestions}
            />
            {cpValue && <input key={cpValue} name={cpName} value={cpValue} readOnly style={{ display: 'none' }} />}
            {townValue && <input key={townValue} name={townName} value={valueOf(townValue)} readOnly style={{ display: 'none' }} />}
          </div>
        </FieldContainer>
      </React.Fragment>
    );
  }
}
