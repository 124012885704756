import times from "lodash/times";
import PropTypes from "prop-types";
import React from "react";

import Icon from "../../../components/Icon";

const Stars = ({ note }) => (
  <span className="stars">
    {times(note, (index) => (
      <Icon key={index} name="star" />
    ))}
  </span>
);

Stars.defaultProps = {
  note: 0,
};

Stars.propTypes = {
  note: PropTypes.number,
};

export default Stars;
